import React, { FocusEventHandler, ReactNode, useEffect, useState } from 'react'
import ReactSelect, { InputActionMeta, MenuPosition } from 'react-select'

import {
  CaretDownIcon,
  HelpIcon,
  SearchIcon
} from '../../../assets/media/icons'
import { useIsMobile } from '../../../hooks/is-mobile.hook'
import { OptionType } from '../../../types/option.type'
import FormError from '../../forms/form-error/form-error.component'
import SmallModal from '../../small-modal/small-modal.component'
import Tooltip from '../../tooltip/tooltip.component'
import Error from '../error/error.component'
import Input from '../input/input.component'
import Label from '../label/label.component'
import { DropdownIndicator, DropdownSearch, Styles } from './select.styles'

export interface SelectProps {
  id: string
  label?: string
  tooltip?: string
  placeholder?: string
  size?: 'sm'
  options: OptionType[]
  name?: string
  value?: any
  defaultValue?: any
  onChange?: (value: any, option: OptionType) => void
  disabled?: boolean
  className?: string
  prefix?: ReactNode
  search?: boolean
  onBlur?: FocusEventHandler
  onSearch?: (newValue: string, actionMeta: InputActionMeta) => void
  onBottom?: any
  Components?: any
  menuOpen?: boolean
  loading?: boolean
  menuPosition?: MenuPosition
  error?: string
  ErrorProps?: any
  onClick?: any
  required?: boolean
}

export default function Select({
  id,
  label,
  tooltip,
  placeholder,
  size,
  options,
  value,
  defaultValue,
  onChange,
  disabled,
  className,
  prefix,
  name,
  search,
  onBlur,
  onSearch,
  onBottom,
  Components,
  menuOpen,
  loading,
  menuPosition,
  error,
  ErrorProps,
  onClick,
  required
}: SelectProps) {
  const isMobile = useIsMobile()
  const [modal, setModal] = useState(false)

  const innerValue: any =
    typeof value === 'string' ? options.find((o) => (o.value + '') === value) : ''
  const innerDefaultValue: any =
    typeof defaultValue === 'string'
      ? options.find((o) => o.value === defaultValue)
      : defaultValue

  const handleChange = (e: OptionType) => {
    onChange?.(e.value, e)
  }

  const handleRequiredLabels = (labelText: string) => {
    const labelLen = labelText.length
    if (!(labelText[labelLen - 1] === '*')) {
      return labelText
    } else {
      return (
        <span>
          {labelText.substring(0, labelLen - 1)}
          <span style={{ color: 'red' }}>*</span>
        </span>
      )
    }
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      padding: 0
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0
    })
  }

  let v

  if (typeof value?.label === 'string') {
    v = value.label
  } else {
    v = innerValue?.label || innerDefaultValue?.label
  }

  if (isMobile) {
    return (
      <>
        <Input
          readOnly
          id={id}
          label={label}
          placeholder={placeholder}
          size={size}
          prefix={prefix}
          disabled={disabled}
          suffix={search ? <SearchIcon /> : <CaretDownIcon />}
          value={v}
          onClick={() => setModal(true)}
          onFocus={(e) => e.target.blur()}
          className={className}
          name={name}
          onBlur={onBlur}
          tooltip={tooltip}
        />
        <SmallModal
          visible={modal}
          onCancel={() => setModal(false)}
          title={label || 'Select'}
          menu={options.map((option) => ({
            name: option.label as string,
            onClick: () => onChange?.(option.value, option)
          }))}
          onBottom={onBottom}
        />
      </>
    )
  }

  return (
    <Styles $size={size} className={className}>
      {label && (
        <Label htmlFor={id}>
          {handleRequiredLabels(label)}
          {required && <span style={{ color: 'red' }}>*</span>}

          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpIcon />
            </Tooltip>
          )}
        </Label>
      )}

      <div onClick={onClick} className="select__container">
        {!!prefix && <span className="select__prefix">{prefix}</span>}
        <ReactSelect
          id={id}
          placeholder={placeholder}
          options={options}
          value={innerValue}
          defaultValue={innerDefaultValue}
          className="select-container"
          styles={customStyles}
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: search ? DropdownSearch : DropdownIndicator,
            ...Components
          }}
          isSearchable={!!onSearch}
          onInputChange={onSearch}
          onMenuScrollToBottom={onBottom}
          onChange={handleChange}
          menuIsOpen={menuOpen}
          loadingMessage={() => 'Loading'}
          isLoading={loading}
          isDisabled={disabled}
          menuPosition={menuPosition}
          menuPlacement="auto"
        />
        {name && <FormError name={name} className="field-error" />}
        {error && <Error name={error} {...ErrorProps} />}
      </div>
    </Styles>
  )
}

export interface CustomSelectProps {
  id: string
  label?: string
  tooltip?: string
  placeholder?: string
  size?: 'sm'
  options: any[]
  name?: string
  value?: any
  defaultValue?: any
  onChange?: (value: any, option: any) => void
  onSearchValueChange?: (value: any) => void
  disabled?: boolean
  className?: string
  prefix?: ReactNode
  search?: boolean
  onBlur?: FocusEventHandler
  onSearch?: any
  onBottom?: any
  Components?: any
  menuOpen?: boolean
  loading?: boolean
  menuPosition?: MenuPosition
  forceDesktop?: boolean
  error?: string
  ErrorProps?: any
}

export function CustomSelect({
  id,
  label,
  tooltip,
  placeholder,
  size,
  options,
  value,
  onChange,
  onSearchValueChange,
  disabled,
  className,
  prefix,
  name,
  search,
  onBlur,
  onBottom,
  Components,
  menuOpen,
  loading,
  forceDesktop,
  menuPosition,
  error,
  ErrorProps
}: CustomSelectProps) {
  const isMobile = useIsMobile()
  const [modal, setModal] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e: OptionType) => {
    onChange?.(e.value, e)
  }

  const handleOnSearch = (e: string) => {
    onSearchValueChange && onSearchValueChange(e)
    setInputValue(e)
  }

  useEffect(() => {
    const filtered = options.filter((el) => !el.templates)
    let timerId: ReturnType<typeof setTimeout>

    if (inputValue.length >= 3) {
      timerId = setTimeout(() => {
        setFilteredOptions(options)
      }, 2000)
    } else {
      setFilteredOptions(filtered)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [inputValue, options])

  const filterOptions = (
    option: { label: string; value: string },
    input: string
  ) => {
    const { label } = option
    if (input && typeof label === 'string') {
      return label.toLowerCase().includes(input.toLowerCase())
    }
    return true
  }

  if (isMobile && !forceDesktop) {
    return (
      <>
        <Input
          readOnly
          id={id}
          label={label}
          placeholder={placeholder}
          size={size}
          prefix={prefix}
          disabled={disabled}
          suffix={search ? <SearchIcon /> : <CaretDownIcon />}
          value={value || ''}
          onClick={() => setModal(true)}
          onFocus={(e) => e.target.blur()}
          className={className}
          name={name}
          onBlur={onBlur}
          tooltip={tooltip}
        />
        <SmallModal
          visible={modal}
          onCancel={() => setModal(false)}
          title={label || 'Select'}
          menu={options.map((option) => ({
            name: option.label as string,
            onClick: () => onChange?.(option.value, option)
          }))}
          onBottom={onBottom}
        />
      </>
    )
  }

  return (
    <Styles $size={size} className={className}>
      {label && (
        <Label htmlFor={id}>
          {label}

          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpIcon />
            </Tooltip>
          )}
        </Label>
      )}

      <div className="select__container">
        {!!prefix && <span className="select__prefix">{prefix}</span>}
        <ReactSelect
          key={`my_unique_select_key__${value}`}
          id={id}
          placeholder={placeholder}
          options={filteredOptions}
          value={value || ''}
          className="select-container"
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: search ? DropdownSearch : DropdownIndicator,
            ...Components
          }}
          isSearchable={true}
          onInputChange={handleOnSearch}
          onMenuScrollToBottom={onBottom}
          onChange={handleChange}
          menuIsOpen={menuOpen}
          loadingMessage={() => 'Loading'}
          isLoading={loading}
          isDisabled={disabled}
          menuPosition={menuPosition}
          menuPlacement="auto"
          filterOption={filterOptions}
        />
        {name && <FormError name={name} className="field-error" />}
        {error && <Error name={error} {...ErrorProps} />}
      </div>
    </Styles>
  )
}
