import { PropsWithChildren, ReactNode, useState } from 'react'

import {
  CaretDownIcon,
  CheckIcon,
  DeleteIconBold
} from '../../../../assets/media/icons'
import IconButton from '../../../../components/buttons/icon-button/icon-button.component'
import { Styles } from './day-accordion.styles'

interface DayAccordionProps {
  title: string
  icon: ReactNode
  iconColor: string
  iconFill?: string
  onRemove: any
  error?: string
  defaultOpen?: boolean
  summeryClass?: string
  contentClass?: string
  actionComponent?: any
  status?: string
  setOpenedId?: Function
  id?: number | string
  openedId?: any
  totalWorkoutTime?: string
}

export default function DayAccordion({
  title,
  children,
  iconColor,
  icon,
  onRemove,
  iconFill,
  error,
  defaultOpen,
  contentClass,
  summeryClass,
  actionComponent,
  status,
  setOpenedId,
  id,
  openedId,
  totalWorkoutTime
}: PropsWithChildren<DayAccordionProps>) {
  const [open, setOpen] = useState(defaultOpen || false)
  return (
    <Styles
      $iconFill={iconFill}
      $open={setOpenedId ? openedId == id : open}
      $iconColor={iconColor}
      $error={error}
      $status={status}
    >
      <div className={`DayAccordion__summary ${summeryClass}`}>
        <div className="DayAccordion__summary-title-container">
          <div className="DayAccordion__summary-icon">{icon}</div>

          <div>
            <p className="DayAccordion__summary-title">{title}</p>
            {totalWorkoutTime && openedId == id && (
              <p className="DayAccordion__summary-time">
                Workout time: {totalWorkoutTime} hrs
              </p>
            )}
          </div>
          {status && status !== 'nothing_logged' && (
            <CheckIcon className="checkIcon" />
          )}
        </div>

        <div className="DayAccordion__actions">
          {actionComponent ? (
            actionComponent
          ) : (
            <IconButton className="DayAccordion__delete-btn" onClick={onRemove}>
              <DeleteIconBold />
            </IconButton>
          )}
          {setOpenedId ? (
            <IconButton
              className="DayAccordion__summary-btn"
              onClick={() => {
                setOpen(!open)
                if (openedId == id) {
                  setOpenedId && setOpenedId(null)
                } else {
                  setOpenedId && setOpenedId(id)
                }
              }}
            >
              <CaretDownIcon className="CaretDownIconColor" />
            </IconButton>
          ) : (
            <IconButton
              className="DayAccordion__summary-btn"
              onClick={() => setOpen(!open)}
            >
              <CaretDownIcon className="CaretDownIconColor" />
            </IconButton>
          )}
        </div>
      </div>
      {setOpenedId
        ? openedId == id && (
            <div className={`DayAccordion__content ${contentClass}`}>
              {children}
            </div>
          )
        : open && (
            <div className={`DayAccordion__content ${contentClass}`}>
              {children}
            </div>
          )}
    </Styles>
  )
}
