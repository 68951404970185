import styled from 'styled-components'

import { getColorCarry } from '../../pipes/theme-color.pipe'

export const FooterInvisible = styled.div<any>`
  width: ${({ isCollapsed }) =>
    isCollapsed ? 'calc(100vw - 81px)' : 'calc(100vw - 220px)'};
  position: fixed;
  left: 220px;
  bottom: 0;
  height: 89px;
  background-color: ${getColorCarry('background_v3')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  transition: 0.25s ease-out;
  transform: translateX(
    ${(props) =>
      props.$open && props.isCollapsed
        ? '-140px'
        : !props.$open && props.isCollapsed
        ? '-105vw'
        : props.$open
        ? '0px'
        : '-100vw'}
  );
  z-index: 112;
  opacity: ${({ $open }) => ($open ? '1' : '0')};

  @media only print {
    display: none;
  }

  .footer {
    &__action {
      text-transform: capitalize;
      margin-right: 1rem;
      color: ${getColorCarry('blue_50')};
      border-color: ${getColorCarry('blue_50')};
      font-weight: 500;

      &_primary {
        color: ${getColorCarry('white')};
        border-color: ${getColorCarry('primary_v2')};
      }
    }

    &__action-close {
      color: ${getColorCarry('primaryDark2_v2')};
      border: 1px solid ${getColorCarry('neutral_50')};

      & > svg > path {
        stroke: ${getColorCarry('white')};
      }
    }

    &__actions-container {
      display: flex;
      align-items: center;
    }
  }
`

export const FooterVisible = styled.div<any>`
  width: ${({ isCollapsed }) => (isCollapsed ? '81px' : '220px')};
  position: fixed;
  bottom: 0;
  border-top: 1px solid #85787a;
  border-right: ${({ isCollapsed }) =>
    isCollapsed ? 'none' : '1px solid #85787a'};
  background-color: #fff;
  z-index: 113;
  transition: width 0.2s ease-in;

  @media only print {
    display: none;
  }

  .footer {
    &__sidebar-handle {
      position: absolute;
      width: 14px;
      height: 21px;
      background: #0c0d0d;
      border-radius: 0px 4px 4px 0px;
      border: 0;
      cursor: pointer;

      svg {
        position: relative;
        right: 7px;
        bottom: 0;
        width: 14px;
        height: 14px;
        color: white;
        path {
          stroke-width: 2.5;
        }
      }
    }

    &__user-card {
      border-radius: 0;
      height: 88px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: ${getColorCarry('background_v3')};
      padding-left: ${({ isCollapsed }) => (isCollapsed ? '25px' : '')};

      & .user-badge-card__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: ${getColorCarry('white')};
      }

      & .user-badge-card__subtitle {
        font-size: 0.75rem;
        color: ${getColorCarry('neutral_30')};
        opacity: 0.6;
      }

      & .user-badge-card__content {
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
        color: #ffffff;
      }

      & svg {
        stroke: ${getColorCarry('white')};
        transform: translateY(-0.5rem);
      }
    }
  }
`

export const TrainerFooterInvisible = styled(FooterInvisible)<any>`
  box-shadow: 0px -5px 40px rgba(63, 201, 173, 0.2);
  background: linear-gradient(#003b3f, #00383d);

  .footer {
    &__action {
      border: 1px solid #2ba8b6;
      box-sizing: border-box;
      border-radius: 10px;
      color: #2ba8b6;
      svg {
        margin-right: 16px;
        stroke: #2ba8b6;
      }

      &_primary {
        color: ${getColorCarry('white')};
        background: #2ba8b6;
      }
    }
  }
`
export const TrainerFooterVisible = styled(FooterVisible)<any>`
   border-top: 1px solid #196a73;
   border-right: 1px solid #196a73;

   .footer {
    &__sidebar-handle {
      position: absolute;
      width: 14px;
      height: 21px;
      background: #186871;
      border-radius: 0px 4px 4px 0px;
      border: 0;
      cursor: pointer;

      svg {
        position: relative;
        right: 7px;
        bottom: 0;
        width: 14px;
        height: 14px;
        color: white;
        path {
          stroke-width: 2.5;
        }
      }
    }

     &__user-card {
      background: linear-gradient(#003B3F, #00383D);
      & .user-badge-card__title {
        color: #ffffff;
      }
       & .user-badge-card__subtitle {
        color: #b7dbdf;
      }
       & svg {
        stroke: #ffffff;;
      }
`
