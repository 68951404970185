export function getUnreadCount(rooms: any, isClient: boolean, trainerId?: string): number {
  try {
    let unread = 0

    Object.values(rooms).filter(room => isClient ?
      (room as any)?.room.user_uuid === trainerId
      : true
    )
    .forEach((room) => {
      unread += (room as any)?.room.unReadMessagesCount || 0
    })

    return unread
  } catch (e: any) {
    return 0
  }
}
