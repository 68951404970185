import styled from 'styled-components'

import { getColorCarry } from '../../../../pipes/theme-color.pipe'

export default styled.div`
  .qa-add-workout {
    &__flex-last {
      align-items: end;
    }
    &__trash {
      color: ${getColorCarry('red')};
      margin-left: 10px;
    }
    &__now-wrap {
      white-space: nowrap;
    }
    &__w-100 {
      width: 100%;
    }
    &__mt-10 {
      margin-top: 10px;
    }
    &__ml-10 {
      margin-left: 10px;
    }
    &__pb-20 {
      padding-bottom: 20px;
    }
    &__form-group {
      margin-top: 20px;
    }
    &__formik-input {
      height: 44px;
      border-radius: 0.625rem;
      font-size: 0.875rem;
      color: #2e2f31;
      padding: 0 1rem;
    }
    &__box {
      background-color: #f1f4f7;
      padding: 18px;
      margin-bottom: 20px;
      border-radius: 10px;
    }
    &__negative-space {
      margin-top: -10px;
      margin-bottom: 10px;
    }
    &__box-headline {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__flex-form-group {
      display: flex;
      justify-content: space-between;
    }
    &__add-another-button {
      display: flex;
      justify-content: center;
      gap: 9px;
      color: ${getColorCarry('neutral_70')};
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23757575' stroke-width='1' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 10px;
      border: none;
      padding: 0.75rem;
      width: 100%;
      background-color: ${getColorCarry('white')};
      cursor: pointer;
    }
  }
`
