import { useQuery } from '@tanstack/react-query'

import { useAuth } from '../../hooks/auth.hook'
import { GetActivitiesCurrentRevision } from '../../services/api/logging'

const trainingPlans = 'training-plans'

export const useTrainingPlanCurrentRevision = (clientId?: number) => {
  const user = useAuth()

  return useQuery(
    [trainingPlans, 'current-revision', clientId || user.id],
    async () => {
      return GetActivitiesCurrentRevision(clientId || user.id, trainingPlans)
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data']
    }
  )
}
