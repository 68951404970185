import { FieldArray, FormikProvider, useFormik } from 'formik'
import React, { FC, useMemo, useState } from 'react'

import { AddIcon, DeleteOutlinedIcon } from '../../../../../assets/media/icons'
import { useTranslation } from '../../../../../modules/i18n/i18n.hook'
import { EditLoggingResource } from '../../../../../services/api/logging'
import { formatWorkout } from '../../../../../utils/api/logging/add'
import { getTemplates } from '../../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../../utils/arrays'
import Button from '../../../../buttons/button/button.component'
import IconButton from '../../../../buttons/icon-button/icon-button.component'
import AutoCompleteInput from '../../../../form/autoCompleteInput/autoCompleteInput.component'
import Input from '../../../../form/input/input.component'
import RadioInput from '../../../../form/radio-input/radio-input.component'
import Select from '../../../../form/select/select.component'
import TimeInput from '../../../../form/TimeInput/time-input.component'
import { toast } from '../../../../toast/toast.component'
import QuickAccessBack from '../../../components/quick-access-back/quick-access-back.component'
import { useQuickAccess } from '../../../quick-access.context'
import { quickAccessRoutes } from '../../../quick-access.routes'
import Styles from './workout-overview-add-exercise.styles'

const WorkoutOverviewAddExercise: FC = () => {
  const { t } = useTranslation()
  const { routeParams } = useQuickAccess()
  const { workout, date, workoutId }: any = routeParams
  const [mode, setMode] = useState<'exercise' | 'superset'>('exercise')
  const [exerciseTemplate, setExerciseTemplate] = useState<any>([])
  const [name, setName] = useState('')
  const modifiedData = {
    name: workout?.name,
    items: workout?.items,
    time: workout?.time,
    date: date
  }
  const [exerciseType, setExerciseType] = useState<
    'strength' | 'cardio' | string
  >('strength')

  const items = useMemo(() => {
    return [
      {
        is_superset: mode === 'superset',
        sort_order: null,
        data: [
          {
            name: '',
            info: {
              avg_heart_rate: '',
              duration: '',
              intensity: '',
              reps: '',
              rest_interval: '',
              sets: '',
              tempo: '',
              type: 'strength'
            },
            link: '',
            sort_order: ''
          }
        ]
      }
    ]
  }, [])

  const intensityOptions = useMemo(() => {
    return [
      {
        label: 'Low',
        value: 'Low'
      },
      {
        label: 'Moderate',
        value: 'Moderate'
      },
      {
        label: 'High',
        value: 'High'
      }
    ]
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      time: '',
      date: '',
      items: items
    },
    onSubmit: async (values: any) => {
      const mockValues = { ...values }
      if (mode === 'superset') {
        mockValues.items.forEach((item: any) => {
          item.is_superset = true
        })
      }
      mockValues.type = exerciseType
      if (mode == 'exercise') {
        insertExercise(modifiedData, mockValues)
      } else {
        modifiedData.items.push(mockValues.items[0])
      }
      const finalData = formatWorkout(modifiedData)
      const response = await EditLoggingResource(
        finalData,
        modifiedData.date,
        workoutId
      )

      if (response) {
        toast.show({ type: 'success', msg: 'Workout successfully edited' })
      }
    }
  })

  const instanceOfSuperset = {
    is_superset: true,
    sort_order: null,
    data: [
      {
        name: '',
        info: {
          avg_heart_rate: '',
          duration: '',
          intensity: '',
          reps: '',
          rest_interval: '',
          sets: '',
          tempo: '',
          type: 'strength'
        },
        link: '',
        sort_order: ''
      }
    ]
  }

  const useTemplateExercise = async (name: string, clientId?: string) => {
    const data = await getTemplates('/exercises', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setExerciseTemplate(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = exerciseTemplate
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(name.toLowerCase()) &&
          w.info.type ===
            (exerciseType === 'superset' ? 'strength' : exerciseType)
      )
      .map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [exerciseTemplate, name])

  const onExerciseSelected = (value: string, index: number, index2: number) => {
    const ex = exerciseTemplate.find((m: any) => m._id === value)
    if (ex) {
      formik.setFieldValue(`items.${index}.data.${index2}.name`, ex.name)
      formik.setFieldValue(`items.${index}.data.${index2}.info`, ex.info)
      formik.setFieldValue(`items.${index}.data.${index2}.link`, ex.link)
    }
  }
  const insertExercise = (modified: any, data: any) => {
    const final = {
      is_superset: false,
      data: {
        name: data.items[0].data[0].name,
        info: {
          avg_heart_rate: data.items[0].data[0].info.avg_heart_rate,
          duration: data.items[0].data[0].info.duration,
          intensity: data.items[0].data[0].info.intensity,
          reps: data.items[0].data[0].info.reps,
          rest_interval: data.items[0].data[0].info.rest_interval,
          sets: data.items[0].data[0].info.sets,
          tempo: data.items[0].data[0].info.tempo,
          type: data.type
        },
        link: data.items[0].data[0].link
      }
    }
    modified.items.push(final)
  }

  return (
    <Styles mode={mode}>
      <QuickAccessBack
        label={'overview'}
        route={quickAccessRoutes.WORKOUT_OVERVIEW}
      />

      {mode === 'exercise' && (
        <div className="qa-workout-overview-add-exercise__radio-group">
          <RadioInput
            name="exercise-type"
            label={t('quickaccess:add-exercise.label-strength')}
            value="strength"
            isChecked={exerciseType === 'strength'}
            handleChange={(value) => {
              formik.resetForm()
              setExerciseType(value)
            }}
          />
          <RadioInput
            name="exercise-type"
            label={t('quickaccess:add-exercise.label-cardio')}
            value="cardio"
            isChecked={exerciseType === 'cardio'}
            handleChange={(value) => {
              formik.resetForm()
              setExerciseType(value)
            }}
          />
        </div>
      )}

      <div className="qa-workout-overview-add-exercise__header">
        <h2>
          {t(`quickaccess:workout-overview.add-exercise.heading-${mode}`)}
        </h2>
        {exerciseType === 'strength' && (
          <Button
            variant="text"
            onClick={() => {
              formik.resetForm()
              setMode(mode === 'exercise' ? 'superset' : 'exercise')
            }}
          >
            {t(
              `quickaccess:workout-overview.add-exercise.switch-mode-${
                mode === 'exercise' ? 'superset' : 'exercise'
              }`
            )}
          </Button>
        )}
      </div>

      {mode === 'superset' && (
        <div className="qa-workout-overview-add-exercise__superset-heading">
          <h4>Superset 1</h4>
          <hr className="qa-workout-overview-add-exercise__divider" />
        </div>
      )}

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="qa-workout-overview-add-exercise__form-container">
            <div className="qa-workout-overview-add-exercise__input-group">
              <FieldArray
                name="items"
                render={() => (
                  <>
                    {formik.values.items.map((exercise, index) => (
                      <React.Fragment key={index}>
                        <FieldArray
                          name={`items.${index}.data`}
                          render={(arrayHelpers) => (
                            <>
                              {formik.values.items[index].data?.map(
                                (el: any, index2: number) => (
                                  <React.Fragment key={index2}>
                                    <div
                                      className={`qa-workout-overview-add-exercise__input-group-section`}
                                    >
                                      <AutoCompleteInput
                                        id="qa-workout-overview-add-exercise-exercise"
                                        label={
                                          exerciseType == 'strength'
                                            ? t(
                                                'quickaccess:workout-overview.add-exercise.label-exercises'
                                              )
                                            : t(
                                                'quickaccess:add-workout.cardio_name'
                                              )
                                        }
                                        name={`items.${index}.data.${index2}.name`}
                                        placeholder={
                                          exerciseType == 'strength'
                                            ? t(
                                                'quickaccess:workout-overview.add-exercise.placeholder-exercises'
                                              )
                                            : t(
                                                'quickaccess:add-exercise.label-cardio'
                                              )
                                        }
                                        value={
                                          formik.values.items[index].data[
                                            index2
                                          ].name
                                        }
                                        log={true}
                                        onSelect={(value) =>
                                          onExerciseSelected(
                                            value,
                                            index,
                                            index2
                                          )
                                        }
                                        // suffix={<SearchIcon />}
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            `items.${index}.data.${index2}.name`,
                                            value
                                          )
                                          setName(value)
                                          if (value.length >= 3)
                                            useTemplateExercise(value)
                                        }}
                                        options={nameOptions}
                                      />
                                      {formik.values.items[index]?.data.length >
                                      1 ? (
                                        <IconButton
                                          className="qa-add-workout__ml-10"
                                          onClick={() =>
                                            arrayHelpers.remove(index2)
                                          }
                                        >
                                          <DeleteOutlinedIcon color="red" />
                                        </IconButton>
                                      ) : null}
                                    </div>

                                    {exerciseType === 'strength' ? (
                                      <>
                                        <div
                                          className={`qa-workout-overview-add-exercise__input-group-section`}
                                        >
                                          <Input
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-workout-overview-add-exercise-sets"
                                            label={t(
                                              'quickaccess:workout-overview.add-exercise.label-sets'
                                            )}
                                            name={`items.${index}.data.${index2}.info.sets`}
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.sets
                                            }
                                            placeholder="-"
                                            onChange={(e) =>
                                              !isNaN(+e.target.value) &&
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.sets`,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <Input
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-workout-overview-add-exercise-reps"
                                            label={t(
                                              'quickaccess:workout-overview.add-exercise.label-reps'
                                            )}
                                            name={`items.${index}.data.${index2}.info.reps`}
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.reps
                                            }
                                            placeholder="-"
                                            onChange={(e) => {
                                              if (
                                                !isNaN(+e.target.value) ||
                                                e.target.value.search('-') > -1
                                              ) {
                                                formik.setFieldValue(
                                                  `items.${index}.data.${index2}.info.reps`,
                                                  e.target.value
                                                )
                                              }
                                            }}
                                          />
                                        </div>
                                        <div
                                          className={`qa-workout-overview-add-exercise__input-group-section`}
                                        >
                                          <Input
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-workout-overview-add-exercise-tempo"
                                            label={t(
                                              'quickaccess:workout-overview.add-exercise.label-tempo'
                                            )}
                                            name={`items.${index}.data.${index2}.info.tempo`}
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.tempo
                                            }
                                            placeholder="-"
                                            onChange={(e) =>
                                              !isNaN(+e.target.value) &&
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.tempo`,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <TimeInput
                                            name={`items.${index}.data.${index2}.info.rest_interval`}
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-workout-overview-add-exercise-rest-interval"
                                            label={t(
                                              'quickaccess:workout-overview.add-exercise.label-rest'
                                            )}
                                            placeholder="-"
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.rest_interval
                                            }
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.rest_interval`,
                                                e.target.value
                                              )
                                            }
                                            format="mm:ss"
                                          />
                                        </div>
                                        <Input
                                          id="qa-workout-overview-add-exercise-link"
                                          label={t(
                                            'quickaccess:add-workout.link'
                                          )}
                                          name={`items.${index}.data.${index2}.link`}
                                          value={
                                            formik.values.items[index].data[
                                              index2
                                            ].link
                                          }
                                          placeholder="https://"
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              `items.${index}.data.${index2}.link`,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <hr
                                          style={{ width: '100%' }}
                                          className="qa-workout-overview-add-exercise__divider"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className={`qa-workout-overview-add-exercise__input-group-section`}
                                        >
                                          <TimeInput
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-add-exercise-time"
                                            label={t(
                                              'quickaccess:add-exercise.label-time'
                                            )}
                                            name={`items.${index}.data.${index2}.info.duration`}
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.duration
                                            }
                                            placeholder="-"
                                            format="hh:mm"
                                            // allowClear={false}
                                            onChange={(e: any) =>
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.duration`,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <Select
                                            className="qa-workout-overview-add-exercise__input-group-item"
                                            id="qa-add-exercise-intensity"
                                            label={t(
                                              'quickaccess:add-exercise.label-intensity'
                                            )}
                                            name={`items.${index}.data.${index2}.info.intensity`}
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.intensity
                                            }
                                            placeholder="-"
                                            options={intensityOptions}
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.intensity`,
                                                value
                                              )
                                            }}
                                          />
                                        </div>
                                        <Input
                                          id="Avg-heart-rate"
                                          label="Average heart rate (bpm)"
                                          placeholder="-"
                                          value={
                                            formik.values.items[index].data[
                                              index2
                                            ].info.avg_heart_rate
                                          }
                                          onChange={(e: any) =>
                                            formik.setFieldValue(
                                              `items.${index}.data.${index2}.info.avg_heart_rate`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                              {mode === 'superset' && (
                                <div>
                                  <Button
                                    onClick={() => {
                                      arrayHelpers.push(
                                        instanceOfSuperset.data[0]
                                      )
                                    }}
                                    variant="text"
                                    className="qa-workout-overview-add-exercise__add-superset-exercise-btn"
                                  >
                                    <AddIcon />{' '}
                                    <span>
                                      {t(
                                        'quickaccess:workout-overview.add-exercise-btn'
                                      )}
                                    </span>
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        />
                      </React.Fragment>
                    ))}
                  </>
                )}
              />
            </div>
          </div>
          <Button
            htmlType={'submit'}
            className="qa-workout-overview-add-exercise__button"
          >
            {t(`quickaccess:workout-overview.add-exercise.add-${mode}-btn`)}
          </Button>
        </form>
      </FormikProvider>
    </Styles>
  )
}

export default WorkoutOverviewAddExercise
