import Styles from './workout.styles'
import WorkoutItem from './workout-item.component'

interface WorkoutsActivitiesProps {
  onSelectWorkout: (workout: any) => void
  changeLogRoute: Function
  workoutData: any
}

function WorkoutsActivities({
  onSelectWorkout,
  workoutData
}: WorkoutsActivitiesProps) {
  return (
    <Styles>
      {workoutData?.map((workout: any, index: number) => (
        <WorkoutItem
          handleChange={() => {
            onSelectWorkout(workout)
          }}
          workout={workout}
          key={index}
        />
      ))}
    </Styles>
  )
}

export default WorkoutsActivities
