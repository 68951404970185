// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react'

interface useExerciseLoggedInfo {
  activeSetIndex: number
  sets: Array<any>
}

const useExerciseLoggedInfo = (
  logWorkout: any,
  activeExercise: any
): useExerciseLoggedInfo => {
  const currentActiveIndex = logWorkout?.info?.sets.length

  const [activeSetIndex, setActiveSetIndex] = useState(currentActiveIndex || 0)
  const [sets, setSets] = useState<any>(
    [...Array(Number(activeExercise.data.info.sets))].map((item, index) => ({
      set: (index + 1).toString(),
      kgs: '10',
      reps: '10'
    }))
  )

  useEffect(() => {
    if (logWorkout) {
      const loggedItem = { ...logWorkout } as any
      if (!loggedItem?.info?.sets) {
        return
      }
      loggedItem?.info?.sets
        ? (loggedItem.info.sets = loggedItem.info.sets.filter(
            (item: any) => item != null
          ))
        : null

      let lastSetNumber =
        +loggedItem?.info?.sets[loggedItem.info.sets.length - 1]?.set
      const loggedFilledArray = [...loggedItem.info.sets].concat(
        [
          ...Array(
            Number(activeExercise.data.info.sets) -
              (loggedItem.info.sets.length - 1)
          )
        ].map(() => {
          lastSetNumber += 1
          return { set: lastSetNumber.toString(), kgs: '10', reps: '10' }
        })
      )

      setActiveSetIndex(loggedItem?.info?.sets?.length || 0)
      setSets(loggedFilledArray)
    } else {
      setSets(
        [...Array(Number(activeExercise.data.info.sets))].map(
          (item, index) => ({
            set: (index + 1).toString(),
            kgs: '10',
            reps: '10'
          })
        )
      )
    }
  }, [activeExercise])

  return { activeSetIndex, sets }
}

export default useExerciseLoggedInfo
