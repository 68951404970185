import { toast } from '../../../components/toast/toast.component'
import { EP_CALENDAR } from '../../../enums/api.enum'
import api from '../../../managers/api.manager'
import logger from '../../../managers/logger.manager'
import { serverError } from '../../../pipes/server-error.pipe'

export async function AddLoggingResource(
  data: any,
  date: string,
  accountId?: string | null
) {
  try {
    const response = await api.post(
      `/calendar/date/${date}/activities${
        accountId ? `?account_id=${accountId}` : ''
      }`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function EditLoggingResource(
  data: any,
  date: string,
  id: string | number,
  userId?: number
) {
  try {
    const response = await api.put(
      `/calendar/date/${date}/activities/${id}${userId ? `?account_id=${userId}` : ''}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function GetAnActivityOfTheCalendarById(
  date: string,
  id: string | number,
  userId?: number
) {
  try {
    const response = await api.get(`/calendar/date/${date}/activities/${id}${userId ? `?account_id=${userId}` : ''}`)
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function AddLogToActivityOfTheCalendar(
  date: string,
  id: string | number,
  data: object,
  userId?: number
) {
  try {
    const response = await api.post(
      `/calendar/date/${date}/activities/${id}/log${userId ? `?account_id=${userId}` : ''}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function AddLogToActivityOfTheCalendarByUser(
  date: string,
  id: string | number,
  data: object,
  userId: string | number
) {
  try {
    const response = await api.post(
      `/calendar/date/${date}/activities/${id}/log?account_id=${userId}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function GetActivitiesCurrentRevision(
  userId: string | number,
  type: string
) {
  try {
    const response = await api.get(
      `/${type}/active/revisions/current/${
        type === 'diet-plans' ? 'days' : 'activities'
      }?account_id=${userId}&takes_all=true`
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    //toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function AssignDietPlanDay(
  userId: string | number,
  revisionData: any,
  date: string,
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  event?: any
) {
  const data =
    type == 'diet-plans'
      ? {
          diet_plan_day_id: revisionData._id
        }
      : {
          training_plan_activity_ids: revisionData.map(
            (workout: any) => workout._id
          )
        }

  try {
    const response = await api.post(
      `/calendar/date/${date}/${
        type === 'diet-plans'
          ? 'assign-diet-plan-day'
          : 'assign-training-plan-activities'
      }?account_id=${userId}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function AssignDietPlanActivities(
  userId: string | number,
  revisionData: any,
  date: string,
  mealPlanId?: string
) {
  const data = {
    diet_plan_activity_ids: revisionData.resource.map((meal: any) => meal._id),
    diet_plan_day_id: mealPlanId || ''
  }

  try {
    const response = await api.post(
      `/calendar/date/${date}/assign-diet-plan-activities?account_id=${userId}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function assignSingleWorkout(
  userId: string | number,
  revisionData: any,
  date: any
) {
  const data = {
    training_plan_activity_id: revisionData._id
  }
  try {
    const response = await api.post(
      `/calendar/date/${date}/assign-training-plan-activity?account_id=${userId}`,
      data
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

export async function getCalendarDayData(
  userId: string | number,
  startDate: string,
  endDate: string
) {
  try {
    const response = await api.get(
      `${EP_CALENDAR}?filter[date]=${startDate},${endDate}&filter[account_id]=${userId}`
    )
    return response?.data?.data
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}
