import styled from 'styled-components'

import { getColorCarry } from '../../pipes/theme-color.pipe'

export const Styles = styled.div<any>`
  width: 100%;
  height: ${(props) => (props.$icon ? '480px' : '100%')};
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: white;

  padding: ${(props) => (props.$spacing ? '1.5rem 0' : '0')};

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    & svg {
      color: ${getColorCarry('neutral_50')};
    }
  }
`

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${getColorCarry('neutral_70')};
`

export const NewStyle = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 52px;
    height: 52px;
    * {
      fill: #9e9e9e;
    }
  }

  .text {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(16, 36, 61, 0.8);
  }
`
