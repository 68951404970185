import useSWR from 'swr'

import api from '../../../managers/api.manager'

async function fetcher(url: string) {
  const response = await api.get(url)
  return response.data.data
}

interface getMealsProps {
  userId: number
}

export default function getMeals({ userId }: getMealsProps) {
  const { data, mutate } = useSWR(
    `/diet-plans/current/revisions/current/days?account_id=${userId}&takes_all=true`,
    fetcher
  )
  return {
    data,
    refetch: mutate
  }
}
