export class Formatter {
  private formats: ((val: string) => string)[] = []

  public format(value: string): string {
    return this.formats.reduce((a, b) => b(a), value)
  }

  public number() {
    this.formats.push((val: string) => val.replace(/\D/g, ''))
    return this
  }

  public float() {
    this.formats.push((val: string) => val.replace(/[^\d.-]/g, ''))
    return this
  }

  public min(x: number) {
    this.formats.push((val: string) => (Number(val) < x ? String(x) : val))
    return this
  }

  public max(x: number) {
    this.formats.push((val: string) => (Number(val) > x ? String(x) : val))
    return this
  }

  public toFixed(x: number) {
    this.formats.push((val: string) => {
      const periodPos = val.indexOf('.')
      if (periodPos < 0) return val

      return `${val.substring(0, periodPos)}.${val.substring(
        periodPos + 1,
        periodPos + x + 1
      )}`
    })
    return this
  }
}

const formatter = () => new Formatter()

export default formatter
