import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AddIcon } from '../../../../assets/media/icons'
import { Routes } from '../../../../enums/routes.enum'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { getColorCarry } from '../../../../pipes/theme-color.pipe'
import { AddLogToActivityOfTheCalendar, EditLoggingResource } from '../../../../services/api/logging'
import { formatMeal } from '../../../../utils/api/logging/add'
import { DATE_FORMAT } from '../../../../utils/date'
import { getRoute } from '../../../../utils/routes'
import Button from '../../../buttons/button/button.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import QuickAccessMacronutrientWithPrevValuesComponent from '../../components/quick-access-macronutrient/quick-access-macronutrient-with-prev-values.component'
import QuickAccessTopbar from '../../components/quick-access-topbar/quick-access-topbar.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import FoodItem from './quick-access-food-item/quick-access-food-item.component'
import Styles from './quick-access-meal-overview.styles'

const nutrients: any = {
  Proteins: 'proteins',
  Fat: 'fat',
  'Net Carbs': 'net_carbs',
  Sugar: 'sugar',
  Fiber: 'fiber',
  'Total Carbs': 'total_carbs',
  Calories: 'calories'
}

interface Props {}

const QuickAccessMealOverview: FC<Props> = () => {
  const { setOpen, routeParams, client, setRoute } = useQuickAccess()
  const history = useHistory()
  const { t } = useTranslation()

  const [initialMealData] = useState(
    JSON.parse(JSON.stringify(routeParams?.initialData))
  )
  const mealDate = routeParams?.date || '';

  const [random, setRandom] = useState(Math.random())
  routeParams?.meal?.resource?.items?.forEach((item: any) => {
    if (item.log) item.data = item.log
  })

  const [meal, setMeal] = useState(
    routeParams?.meal?.log || routeParams?.meal?.resource
  )
  const [stateNutrients, setStateNutrients] = useState(1)

  const instanceOfFood: any = {
    data: {
      new: true,
      name: 'No name',
      info: {
        proteins: 0,
        fat: 0,
        net_carbs: 0,
        sugar: 0,
        fiber: 0,
        grams: 0,
        total_carbs: 0,
        calories: 0
      }
    }
  }

  const createFood = () => {
    const data = { ...meal }
    data.items.push(instanceOfFood)
    setMeal(data)
  }

  const saveEditedMeal = async (redirect?: boolean) => {
    const finalData = formatMeal(meal)
    const editResponse = await EditLoggingResource(
      finalData,
      moment().format(DATE_FORMAT),
      routeParams.id
    )

    if (redirect) {
      if (editResponse) {
        toast.show({ type: 'success', msg: 'Meal successfully saved' })
        setOpen(false)
        history.push(
          getRoute(Routes.CALENDAR)
        )
      } else {
        toast.show({ type: 'error', msg: 'Error saving meal' })
      }
    }
  }

  const markComplete = async () => {
    delete meal.supplements

    saveEditedMeal()

    const response = await AddLogToActivityOfTheCalendar(
      routeParams?.meal.date,
      routeParams.id,
      meal,
      client?.id
    )
    if (response) {
      toast.show({ type: 'success', msg: 'Meal successfully logged' })
      setOpen(true)
      setRoute(quickAccessRoutes.LOG_MEAL)
      // history.push(
      //   getRoute(Routes.ACTIVITIES_CURR_PLAN) + (
      //     client?.id ? '?client_id='+client.id : ''
      //   )
      // )
    }
  }

  const handleRemove = (index: number) => {
    const data = { ...meal }
    data.items.splice(index, 1)
    setMeal(data)
    setRandom(Math.random())
  }

  const getTwoDecimal = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  const [totalMacros, setTotalMacros] = useState({
    proteins: 0,
    fat: 0,
    net_carbs: 0,
    sugar: 0,
    fiber: 0,
    total_carbs: 0,
    calories: 0
  })

  const [prevTotalMacros, setPrevTotalMacros] = useState<any>({
    proteins: 0,
    fat: 0,
    net_carbs: 0,
    sugar: 0,
    fiber: 0,
    total_carbs: 0,
    calories: 0
  })

  useEffect(() => {
    const macros: any = {
      proteins: 0,
      fat: 0,
      net_carbs: 0,
      sugar: 0,
      fiber: 0,
      total_carbs: 0,
      calories: 0
    }

    meal.items.forEach((item: any) => {
      Object.keys(item.data.info).forEach((elem) => {
        macros[elem] += +item.data.info[elem]
      })
    })

    setTotalMacros(macros)
  }, [stateNutrients])

  useEffect(() => {
    const macros: any = {
      proteins: 0,
      fat: 0,
      net_carbs: 0,
      sugar: 0,
      fiber: 0,
      total_carbs: 0,
      calories: 0
    }

    initialMealData.items.forEach((item: any) => {
      if (!item?.is_new) {
        Object.keys(item.data.info).forEach((elem) => {
          macros[elem] += +item.data.info[elem]
        })
      }
    })

    setPrevTotalMacros(macros)
  }, [])
  const calculate = (newValue: any, prevValue: any) => {
    return getTwoDecimal(prevValue - newValue)
  }

  return (
    <Styles>
      <QuickAccessTopbar height={190} />
      <QuickAccessBack
        label="log-meals"
        route={quickAccessRoutes.LOG_MEAL}
        color={getColorCarry('neutral_50')}
      />
      <div className="qa-meal-overview__header">
        <h2>{routeParams?.name}</h2>
        <h3>{moment().format('dddd, D MMMM YYYY').toString()}</h3>

        <div className="qa-meal-overview__header-macronutrients">
          {Object.keys(nutrients)?.map((k) => {
            const leftOver = calculate(
              (totalMacros as any)[nutrients[k]],
              prevTotalMacros[nutrients[k]]
            )
            return (
              <div key={`${k}-${random}`}>
                <QuickAccessMacronutrientWithPrevValuesComponent
                  target={
                    leftOver
                      ? `${getTwoDecimal(
                          (prevTotalMacros as any)[nutrients[k]]
                        )}${k === 'Calories' ? 'KCal' : 'g'}`
                      : ''
                  }
                  key={k}
                  unit={k === 'Calories' ? 'KCal' : 'g'}
                  title={k}
                  leftover={leftOver}
                  amount={`${getTwoDecimal(
                    (totalMacros as any)[nutrients[k]]
                  )}${k === 'Calories' ? 'KCal' : 'g'}`}
                  variant="light"
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className="qa-meal-overview__top-spacing" />

      <div className="qa-meal-overview__body">
        {meal.items.map((item: any, index: number) => (
          <FoodItem
            handleRemove={handleRemove}
            key={`${index}-${random}`}
            index={index}
            setStateNutrients={setStateNutrients}
            item={item}
            macronutrients={nutrients}
          />
        ))}
      </div>

      <div className="qa-meal-overview__footer">
        <button
          className="qa-meal-overview__add-food-button"
          onClick={createFood}
        >
          <AddIcon />
          <span>{t('quickaccess:meal-overview.add-food-btn')}</span>
        </button>
        {
          mealDate === moment().format("YYYY-MM-DD") ?
          <Button onClick={markComplete}>
            {t('quickaccess:meal-overview.mark-completed-btn')}
          </Button>
          :
          <Button onClick={() => saveEditedMeal(true)}>
            Save Meal
          </Button>
        }
      </div>
    </Styles>
  )
}

export default QuickAccessMealOverview
