import {
  ActionType,
  INCREASE_ACTIVE_WORKOUT_INDEX,
  SET_ACTIVE_WORKOUT_DATA,
  SET_ACTIVE_WORKOUT_LOG_DATA,
  SET_DEFAULT_ACTIVE_WORKOUT_INDEX,
  SET_WORKOUT_ITEMS
} from '../action-types'
import { withStorage } from './storage.hook'

const initialState = {
  workoutItems: [],
  activeWorkoutIndex: 0,
  activeWorkoutData: {
    id: '',
    name: '',
    date: '',
    logData: null
  }
}

export const logWorkoutReducer = withStorage(
  (state = initialState, { type, payload }: ActionType<any>) => {
    switch (type) {
      case SET_WORKOUT_ITEMS:
        return {
          ...state,
          workoutItems: payload
        }
      case INCREASE_ACTIVE_WORKOUT_INDEX:
        return {
          ...state,
          activeWorkoutIndex:
            state.workoutItems.length === state.activeWorkoutIndex + 1
              ? 0
              : state.activeWorkoutIndex + 1
        }
      case SET_DEFAULT_ACTIVE_WORKOUT_INDEX: {
        return {
          ...state,
          activeWorkoutIndex: 0
        }
      }
      case SET_ACTIVE_WORKOUT_DATA:
        return {
          ...state,
          activeWorkoutData: payload
        }
      case SET_ACTIVE_WORKOUT_LOG_DATA:
        return {
          ...state,
          activeWorkoutData: { ...state.activeWorkoutData, logData: payload }
        }
    }
    return state
  },
  {},
  'logWorkout'
)
