import * as Yup from 'yup'

export const mealValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  time: Yup.string(),
  date: Yup.string().required(),
  items: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        data: Yup.object().shape({
          name: Yup.string().required(),
          info: Yup.object().shape({
            grams: Yup.string().required(),
            proteins: Yup.string().required(),
            fat: Yup.string().required(),
            net_carbs: Yup.string().required(),
            sugar: Yup.string(),
            fiber: Yup.string(),
            total_carbs: Yup.string().required(),
            calories: Yup.string().required()
          })
        })
      })
    )
})
