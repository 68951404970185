import React from 'react'

import { FoodIconV3, MealIconV3 } from '../../../../../assets/media/icons'
import Styles from './meal-item.styles'

interface MealItemProps {
  meal: any
  onSelect: () => void
  variant: 'activity' | 'split'
}

function MealItem({ meal, onSelect, variant }: MealItemProps) {
  return (
    <Styles onClick={onSelect}>
      {variant === 'activity' && <MealIconV3 className="qa-log-item__icon" />}

      {variant === 'split' && <FoodIconV3 className="qa-log-item__icon" />}
      <div className="container">
        <h3>{meal.name}</h3>
      </div>
    </Styles>
  )
}
export default MealItem
