import { QueryClient } from '@tanstack/react-query'
import moment from 'moment'

import { Activity } from '../../../types/activity.type'
import {
  formatWeekActivities,
  parseActivities
} from '../../../utils/api/calendar'
import { DateView } from '../../../utils/date'

export function parseSingleActivity(data: any, date: Date) {
  //reuse old logic to get Activity

  return parseActivities([
    {
      date: moment(date).format('YYYY-MM-DD'),
      activities: [data]
    }
  ])[0]
}

/** Get query keys to corresponding calendar views for the date */
export function getKeysForDate(date: Date) {
  return {
    //Query key for day
    day: ['activities', moment(moment(date).format('YYYY-MM-DD')).toString()],

    //Query key for week
    week: ['activities', moment(date).isoWeek().toString()],

    //Query key for month
    month: ['activities', moment(date).format('M'), 'month']
  }
}

/** Get query keys to corresponding calendar views for the date in Calendar Page */
export function getCalendarKeysForDate(date: Date) {
  return {
    //Query key for day
    day: ['calendar-activities', moment(moment(date).format('YYYY-MM-DD')).toString()],

    //Query key for week
    week: ['calendar-activities', moment(date).isoWeek().toString()],

    //Query key for month
    month: ['calendar-activities', moment(date).format('M'), 'month']
  }
}

const views: DateView[] = ['day', 'week', 'month']

/** Populates calendar views (day/week/month) for the date with new activities */
export function addActivityInQueries({
  date,
  queryClient,
  activities
}: {
  date: Date
  queryClient: QueryClient
  activities: Activity[]
}) {
  const keys = getCalendarKeysForDate(date)

  const formattedForWeek = formatWeekActivities(activities)

  views.forEach((view) => {
    const key = keys[view]
    // console.log({key, formattedForWeek})
    queryClient.setQueryData<Activity[]>(key, (old) => {
      return [
        ...(old ?? []),
        ...(view === 'month' ? activities : formattedForWeek)
      ]
    })
  })
}

/** Deletes the activity from calendar views */
export function deleteActivityInQueries({
  date,
  queryClient,
  activity
}: {
  date?: Date
  queryClient: QueryClient
  activity: Activity
}) {
  const start = activity.start ?? date

  if (!start) {
    return
  }

  const keysToUpdate = getCalendarKeysForDate(start)

  views.forEach((view) => {
    queryClient.setQueryData<Activity[]>(keysToUpdate[view], (old) => {
      return old?.filter((a) => a._id !== activity._id)
    })
  })
}

export function updateViewQueries({
  queryClient,
  activity,
  date
}: {
  queryClient: QueryClient
  activity: Activity
  date?: Date
}) {
  const start = activity.start ?? date

  if (!start) {
    return
  }

  const keysToUpdate = getCalendarKeysForDate(start)

  views.forEach((view) => {
    queryClient.setQueryData<Activity[]>(keysToUpdate[view], (old) => {
      const newActivities = old?.map((a) => {
        console.log({a, activity})
        if (
          (a._id === activity._id || a.id === activity.id) &&
          (
            (a.resource_type !== "sessions" && a.date === activity.date) ||
            a.resource_type === "sessions"
          )
        ) {
          return activity
        }
        return a
      })

      if (view === 'week') {
        newActivities?.forEach((activity) => {
          if (!activity.resource?.time) {
            return
          }

          activity.count =
            newActivities.filter(
              (a) =>
                a.date === activity.date &&
                a.resource?.time === activity.resource?.time &&
                a._id !== activity._id
            ).length - 1
        })
      }

      return newActivities
    })
  })
}
