import { AutoComplete as AntdAutoComplete } from 'antd'
import React, {
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'

import { HelpIcon } from '../../../assets/media/icons'
import FormError from '../../forms/form-error/form-error.component'
import Tooltip from '../../tooltip/tooltip.component'
import Error, { ErrorProps } from '../error/error.component'
import Label from '../label/label.component'
import Styles from './autoCompleteInput.styles'

export interface AutoCompleteInputProps {
  id: string
  label?: string
  tooltip?: string
  placeholder?: string
  size?: 'sm'
  onChange?: (value: string, option: any) => void
  defaultValue?: string
  value?: string
  options?: any[]
  onClick?: () => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  name?: string
  onBlur?: FocusEventHandler
  labelComponent?: ReactNode
  max?: number
  error?: any
  shouldScrollTo?: any
  onSelect?: (value: string, option: any) => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  ErrorProps?: Pick<ErrorProps, 'size'>
  log?: boolean
  required?: boolean
}

const AutoCompleteInput = forwardRef<any, AutoCompleteInputProps>(
  (
    {
      id,
      label,
      tooltip,
      placeholder,
      size,
      defaultValue,
      value,
      options,
      onChange,
      onClick,
      onFocus,
      className,
      disabled,
      name,
      onBlur,
      labelComponent,
      max,
      error,
      shouldScrollTo,
      onSelect,
      onKeyDown,
      ErrorProps,
      log,
      required
    },
    ref
  ) => {
    const scrollRef = useRef<HTMLLabelElement>(null)
    const handleScrollTo = () => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const [innerOptions, setInnerOptions] = useState<any>([])
    const [innerInputValue, setInnerInputValue] = useState('')

    useEffect(() => {
      if (shouldScrollTo) {
        handleScrollTo()
      }
    }, [shouldScrollTo])

    useEffect(() => {
      let timerId: ReturnType<typeof setTimeout>

      if (innerInputValue.length >= 3) {
        timerId = setTimeout(() => {
          setInnerOptions(options)
        }, 500)
      } else {
        setInnerOptions([])
      }
      return () => {
        clearTimeout(timerId)
      }
    }, [innerInputValue, options])

    const handleRequiredLabels = (labelText: string) => {
      const labelLen = labelText.length
      if (!(labelText[labelLen - 1] === '*')) {
        return labelText
      } else {
        return (
          <span>
            {labelText.substring(0, labelLen - 1)}
            <span style={{ color: 'red' }}>*</span>
          </span>
        )
      }
    }

    const handleChange = (value: string, option: any) => {
      setInnerInputValue(value)

      onChange?.(value, option)
    }
    return (
      <Styles
        $size={size}
        onClick={onClick}
        className={className}
        $disabled={disabled}
      >
        {label && (
          <Label ref={scrollRef} htmlFor={id} className="input__label">
            {labelComponent}

            {handleRequiredLabels(label)}
            {required && <span style={{ color: 'red' }}>*</span>}

            {tooltip && (
              <Tooltip title={tooltip}>
                <HelpIcon />
              </Tooltip>
            )}
          </Label>
        )}
        <AntdAutoComplete
          getPopupContainer={(trigger: any) => trigger.parentElement}
          ref={ref}
          id={id}
          placeholder={placeholder}
          className="input__input"
          defaultValue={defaultValue}
          value={value}
          options={innerOptions}
          onChange={handleChange}
          onFocus={onFocus}
          disabled={disabled}
          onBlur={onBlur}
          maxLength={max}
          onKeyDown={onKeyDown}
          dropdownAlign={
            log
              ? {
                  points: ['tl', 'bl'],
                  offset: [0, -4],
                  overflow: {
                    adjustX: 0,
                    adjustY: 0
                  }
                }
              : {}
          }
          onSelect={onSelect}
        />
        {name && <FormError name={name} className="field-error" />}
        {error && <Error name={error} {...ErrorProps} />}
      </Styles>
    )
  }
)

export default AutoCompleteInput
