import { WorkoutIconV3 } from '../../../../../assets/media/icons'
import Styles from './workout-item.styles'

interface WorkoutItemProps {
  workout: any
  handleChange: Function
}

function WorkoutItem({ workout, handleChange }: WorkoutItemProps) {
  return (
    <Styles
      onClick={() => {
        handleChange()
      }}
    >
      <WorkoutIconV3 className="qa-log-item__icon" />
      <div className="container">
        <h3>{workout.name}</h3>
      </div>
    </Styles>
  )
}
export default WorkoutItem
