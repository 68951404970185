import styled from 'styled-components'

export default styled.div`
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
  .no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
