import styled from 'styled-components'

import { getColorCarry } from '../../../../pipes/theme-color.pipe'
export default styled.div`
  .mb-20 {
    margin-bottom: 20px;
  }
  .supplement {
    display: grid;
    grid-template-columns: 0.9fr 2fr 0.4fr 0.1fr;
    gap: 1rem;
    padding: 0.75rem 0 0.75rem 0;
    border-radius: 15px;
    margin-left: 1rem;
    &__invalid-field {
      & input {
        border-color: red !important;
      }
    }
    &__delete {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      &-btn {
        color: ${getColorCarry('red')};
      }
    }
  }
`
