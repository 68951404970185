import { ArrowDownIcon, ArrowUpIcon } from '../../../../assets/media/icons'
import { Styles } from './quick-access-macronutrient.styles'

interface MacronutrientProps {
  title: string
  amount: string
  target?: string
  variant?: 'light' | 'dark'
  leftover: number
  unit: string
}

export default function QuickAccessMacronutrientWithPrevValuesComponent(
  props: MacronutrientProps
) {
  const { title, amount, target, variant = 'dark', leftover, unit } = props

  return (
    <Styles log={true} variant={variant}>
      <p className="Macronutrient__name">{title}</p>
      <div className={'Macronutrient__values-content'}>
        <p className="Macronutrient__value">{amount}</p>
        {!leftover ? null : leftover > 0 ? (
          <div className="Macronutrient__value Macronutrient__value__flex">
            <ArrowDownIcon className="Macronutrient__value Macronutrient__arrow-icon green" />
            <sub className={`Macronutrient__diff green`}>{`${Math.abs(
              leftover
            )}${unit}`}</sub>
          </div>
        ) : (
          <div className="Macronutrient__value Macronutrient__value__flex">
            <ArrowUpIcon className="Macronutrient__value Macronutrient__arrow-icon red" />
            <sub className={`Macronutrient__diff red`}>{`${Math.abs(
              leftover
            )}${unit}`}</sub>
          </div>
        )}
      </div>
      {target && (
        <p className="Macronutrient__subtitle">{`Target ${target}`}</p>
      )}
    </Styles>
  )
}

QuickAccessMacronutrientWithPrevValuesComponent.defaultProps = {
  amount: '0g'
}
