import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AddIcon,
  OptionSolidIcon,
  VideoOutlinedIcon
} from '../../../../assets/media/icons'
import VideoImg from '../../../../assets/media/quick-access-video.png'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { serverError } from '../../../../pipes/server-error.pipe'
import { getColorCarry } from '../../../../pipes/theme-color.pipe'
import {
  AddLogToActivityOfTheCalendarByUser,
  GetAnActivityOfTheCalendarById
} from '../../../../services/api/logging'
import { SET_WORKOUT_ITEMS } from '../../../../store/action-types'
import { RootState } from '../../../../store/reducers'
import Button from '../../../buttons/button/button.component'
import Card from '../../../card/card.style'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import QuickAccessModal from '../../components/quick-access-modal/quick-access-modal.component'
import QuickAccessTopbar from '../../components/quick-access-topbar/quick-access-topbar.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-workout-overview.styles'
interface Props {}

const initialData = {
  id: 1,
  name: '',
  items: [],
  completed: true
}

const QuickAccessWorkoutOverview: FC<Props> = () => {
  const { activeWorkoutIndex, activeWorkoutData, workoutItems } = useSelector(
    (state: RootState) => state.logWorkout
  )
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const { setRoute, client } = useQuickAccess()
  const [todayDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [workout, setWorkout] = useState<any>(initialData)
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetAnActivityOfTheCalendarById(
        activeWorkoutData.date,
        activeWorkoutData.id,
        client?.id
      )
      const workOutItems: any[] = []

      data.resource.items
        .map((item: any, index: number) => ({ ...item, order: index + 1 }))
        .filter(
          (item: any) =>
            item.status !== 'everything_logged' &&
            item.status !== 'everything_logged_but_changed'
        )
        .map((el: any) =>
          workOutItems.push({
            ...el,
            date: activeWorkoutData.date,
            workOutId: activeWorkoutData.id,
            name: activeWorkoutData.name
          })
        )

      dispatch({
        type: SET_WORKOUT_ITEMS,
        payload: workOutItems
      })

      setWorkout({
        ...data.resource,
        workoutId: data._id,
        resource_type: data.resource_type
      })
    }
    fetchData()
  }, [])

  const renderExercise = (exercise: any, fromSuperset?: boolean) => {
    const data = exercise?.data || exercise
    const info = exercise?.data?.info || exercise.info

    const getLink = (link: string) => {
      return link ? (
        <a
          href={/^https?:\/\//i.test(link) ? link : `https://${link}`}
          target="_blank"
          rel="noreferrer"
        >
          <VideoOutlinedIcon /> {t('quickaccess:workout-overview.view-video')}
        </a>
      ) : (
        '-'
      )
    }

    return (
      <Card
        className={`exercise-card ${fromSuperset ? 'superset-exercise' : ''}`}
        key={exercise._id}
      >
        <div>
          <h3>{data.name}</h3>
          <h4>
            {info.type === 'strength' || info.type !== 'cardio'
              ? `${info.sets} sets, ${info.reps} reps`
              : `${info.intensity} intensity`}
          </h4>
          <span className="video-link">{getLink(data.link)}</span>
        </div>
        <div className="exercise-data">
          {info.type === 'strength' || info.type !== 'cardio' ? (
            <>
              <div className="exercise-data-card">
                <h3>{info.rest_interval}</h3>
                <h4>{t('quickaccess:workout-overview.rest')}</h4>
              </div>
              <div className="exercise-data-card">
                <h3>{info.tempo}</h3>
                <h4>{t('quickaccess:workout-overview.tempo')}</h4>
              </div>
            </>
          ) : (
            <div className="exercise-data-card">
              <h3>{info.duration}</h3>
              <h4>{t('quickaccess:workout-overview.time')}</h4>
            </div>
          )}
        </div>
      </Card>
    )
  }

  const renderSuperset = (superset: any) => {
    return (
      <Card className="superset-card" key={superset._id}>
        <h3 className="superset-card-header">Superset</h3>

        {superset.data.map((exercise: any) => renderExercise(exercise, true))}
      </Card>
    )
  }

  const onMarkCompleted = async () => {
    try {
      await AddLogToActivityOfTheCalendarByUser(
        todayDate,
        workout.workoutId,
        {
          log_all: true
        },
        client?.id || auth.id
      )
      toast.show({ type: 'success', msg: 'Workout successfully logged' })
      setRoute(quickAccessRoutes.LOG_EXERCISE)
    } catch (e: any) {
      toast.show({ type: 'error', msg: serverError(e) })
    }
  }

  const onStartWorkoutClick = () => {
    if (workoutItems[activeWorkoutIndex].data.length) {
      setRoute(quickAccessRoutes.WORKOUT_LOG_SUPERSET, {
        name: activeWorkoutData.name,
        workoutId: workout.workoutId,
        exercise: workoutItems[activeWorkoutIndex],
        workoutDate: activeWorkoutData.date,
        logData: activeWorkoutData.logData,
        lengthOfWorkout: workout.items.length.toString(),
        workout: workout.items
      })
    } else if (workoutItems[activeWorkoutIndex].data.info.type === 'cardio') {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO, {
        id: activeWorkoutData.id,
        name: activeWorkoutData.name,
        exercise: workoutItems[activeWorkoutIndex],
        logData: activeWorkoutData.logData,
        workoutId: workout.workoutId,
        workoutDate: activeWorkoutData.date,
        withinWorkout: 'true',
        lengthOfWorkout: workout.items.length.toString(),
        workout: workout.items
      })
    } else {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_STRENGTH, {
        id: activeWorkoutData.id,
        name: activeWorkoutData.name,
        exercise: workoutItems[activeWorkoutIndex],
        logData: activeWorkoutData.logData,
        workoutId: workout.workoutId,
        workoutDate: activeWorkoutData.date,
        lengthOfWorkout: workout.items.length.toString(),
        workout: workout.items
      })
    }
  }

  const someItemLogged = workoutItems.some(
    (item: any) =>
      item.status === 'everything_logged' ||
      item.status === 'not_everything_logged'
  )
  const onStartText = workoutItems.length
    ? someItemLogged
      ? 'Next Exercise'
      : t('quickaccess:workout-overview.start-workout')
    : t('quickaccess:workout-overview.start-workout')

  return (
    <Styles>
      <QuickAccessTopbar />
      <QuickAccessBack
        label="log-exercise"
        route={quickAccessRoutes.LOG_EXERCISE}
        color={getColorCarry('neutral_50')}
      />
      <div className="header">
        <h2>{activeWorkoutData?.name}</h2>
        <OptionSolidIcon />
      </div>
      <h4>{moment().format('dddd, D MMMM YYYY').toString()}</h4>

      <div className="body">
        {workout.resource_type === 'workout_items'
          ? renderExercise(workout)
          : workout.items.map((exercise: any) =>
              exercise.is_superset
                ? renderSuperset(exercise)
                : renderExercise(exercise)
            )}
      </div>

      <div className="footer">
        <button
          className="add-exercise-button"
          onClick={() =>
            setRoute(quickAccessRoutes.WORKOUT_OVERVIEW_ADD_EXERCISE, {
              workout: workout,
              date: activeWorkoutData.date,
              workoutId: activeWorkoutData.id
            })
          }
        >
          <AddIcon />{' '}
          <span>{t('quickaccess:workout-overview.add-exercise-btn')}</span>
        </button>

        <div className="button-group">
          <Button
            onClick={onMarkCompleted}
            className="button-group__button"
            variant="secondary"
          >
            {t('quickaccess:workout-overview.mark-completed')}
          </Button>
          <Button
            className="button-group__button"
            onClick={() => {
              workoutItems.length
                ? onStartWorkoutClick()
                : toast.show({ type: 'success', msg: 'Everything is logged' })
            }}
          >
            {onStartText}
          </Button>
        </div>
      </div>

      <QuickAccessModal
        visible={showVideo}
        onCancel={() => setShowVideo(false)}
      >
        <img
          src={VideoImg}
          alt="workout-video"
          style={{ marginTop: '35px', width: '100%' }}
        />
      </QuickAccessModal>
    </Styles>
  )
}

export default QuickAccessWorkoutOverview
