import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'

import {
  AddIcon,
  ClockFilledGrey,
  DeleteOutlinedIcon
} from '../../../../assets/media/icons'
import userTypes from '../../../../enums/user-types.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { AddLoggingResource } from '../../../../services/api/logging'
import { calculateTotalWorkoutTime } from '../../../../utils/activities'
import {
  formatSingleCardio,
  formatSingleWorkout,
  formatSingleWorkoutSuperset
} from '../../../../utils/api/logging/add'
import { getTemplates } from '../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../utils/arrays'
import Button from '../../../buttons/button/button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import DatePicker from '../../../form/date-picker/date-picker.component'
import Input from '../../../form/input/input.component'
import RadioInput from '../../../form/radio-input/radio-input.component'
import Select from '../../../form/select/select.component'
import Textarea from '../../../form/textarea/textarea.component'
import TimeInput from '../../../form/TimeInput/time-input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-add-exercise.styles'
import validationSchema from './validation/exerciseValidationSchema'

const QuickAccessAddExercise: FC = () => {
  const { t } = useTranslation()
  const [exerciseType, setExerciseType] = useState('strength')
  const [validate, setValidate] = useState(
    validationSchema[`${exerciseType}ValidationSchema`]
  )
  const [exerciseTemplate, setExerciseTemplate] = useState<any>([])
  const [name, setName] = useState('')

  const { type } = useAuth()
  const { client, setOpen } = useQuickAccess()

  const supersetInstance: any = {
    name: '',
    date: '',
    time: '',
    total_workout_time: '',
    info: {
      type: 'superset'
    },
    data: [
      {
        name: '',
        link: '',
        info: {
          sets: '',
          reps: '',
          tempo: '',
          rest_interval: '',
          notes: ''
        }
      }
    ]
  }

  const initialValues: any = {
    name: '',
    date: '',
    time: '',
    total_workout_time: '',
    link: '',
    info: {
      type: 'strength',
      sets: '',
      reps: '',
      tempo: '',
      rest_interval: '',
      avg_heart_rate: '',
      duration: '',
      intensity: '',
      notes: ''
    }
  }

  const handleAddExercise = () => {
    const data = { ...formik.values }
    data.data.push({
      name: '',
      link: '',
      info: {
        sets: '',
        reps: '',
        tempo: '',
        rest_interval: '',
        notes: ''
      }
    })
    formik.setValues(data)
  }

  const deleteExercise = (key: number) => {
    const data = { ...formik.values }
    data.data.splice(key, 1)
    formik.setValues(data)

    //Recalculate total workout time
    const isSuperset = exerciseType === 'superset'
    const totalWorkoutTime = calculateTotalWorkoutTime({
      items: [
        {
          data: isSuperset ? data.data : data,
          is_superset: isSuperset
        }
      ]
    })

    formik.setFieldValue('total_workout_time', totalWorkoutTime)
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: async (values) => {
      values.info.type = exerciseType
      const finalData =
        exerciseType == 'superset'
          ? formatSingleWorkoutSuperset(values)
          : exerciseType === 'cardio'
          ? formatSingleCardio(values)
          : formatSingleWorkout(values)
      const response = await AddLoggingResource(
        finalData,
        values.date,
        type === userTypes.TRAINER && client?.id ? client.id.toString() : null
      )

      if (response) {
        toast.show({ type: 'success', msg: 'Exercise successfully created' })
        setOpen(false)
      }
    }
  })

  const useTemplateExercise = async (name: string, clientId?: string) => {
    const data = await getTemplates('/exercises', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setExerciseTemplate(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = exerciseTemplate
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(name.toLowerCase()) &&
          w.info.type ===
            (exerciseType === 'superset' ? 'strength' : exerciseType)
      )
      .map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [exerciseTemplate, name])

  const onExerciseSelected = (value: string, key?: number) => {
    const ex = exerciseTemplate.find((m: any) => m._id === value)
    if (ex) {
      if (key != 0 && !key) {
        formik.setFieldValue('name', ex.name)
        formik.setFieldValue('info.sets', ex.info.sets)
        formik.setFieldValue('info.reps', ex.info.reps)
        formik.setFieldValue('info.tempo', ex.info.tempo)
        formik.setFieldValue('info.rest_interval', ex.info.rest_interval)
        formik.setFieldValue('info.intensity', ex.info.intensity)
        formik.setFieldValue('info.duration', ex.info.duration)
        formik.setFieldValue('info.avg_heart_rate', ex.info.avg_heart_rate)
        formik.setFieldValue('info.notes', ex.info.notes)
        formik.setFieldValue('link', ex.link)
        formik.setFieldValue('_id', ex._id)
      } else {
        formik.setFieldValue(`data.${key}.name`, ex.name)
        formik.setFieldValue(`data.${key}.info.sets`, ex.info.sets)
        formik.setFieldValue(`data.${key}.info.reps`, ex.info.reps)
        formik.setFieldValue(`data.${key}.info.tempo`, ex.info.tempo)
        formik.setFieldValue(`data.${key}.info.notes`, ex.info.notes)
        formik.setFieldValue(
          `data.${key}.info.rest_interval`,
          ex.info.rest_interval
        )
        formik.setFieldValue(`data.${key}.link`, ex.link)
        formik.setFieldValue(`data.${key}._id`, ex._id)
      }
    }
  }

  const handleCalculateTotalTime = () => {
    const data = { ...formik.values }

    const isSuperset = exerciseType === 'superset'
    const totalWorkoutTime = calculateTotalWorkoutTime({
      items: [
        {
          data: isSuperset ? data.data : data,
          is_superset: isSuperset
        }
      ]
    })

    formik.setFieldValue('total_workout_time', totalWorkoutTime)
  }

  return (
    <Styles>
      <QuickAccessBack label={'add'} route={quickAccessRoutes.ADD} />
      <div className="qa-add-exercise__radio-group">
        <RadioInput
          name="exercise-type"
          label={t('quickaccess:add-exercise.label-strength')}
          value="strength"
          isChecked={exerciseType === 'strength'}
          handleChange={(value) => {
            formik.resetForm()
            formik.setValues(initialValues)
            setExerciseType(value)
            setValidate(validationSchema[`${value}ValidationSchema`])
          }}
        />
        <RadioInput
          name="exercise-type"
          label={t('quickaccess:add-exercise.label-cardio')}
          value="cardio"
          isChecked={exerciseType === 'cardio'}
          handleChange={(value) => {
            formik.resetForm()
            formik.setValues(initialValues)
            setExerciseType(value)
            setValidate(validationSchema[`${value}ValidationSchema`])
          }}
        />
        <RadioInput
          name="exercise-type"
          label={t('quickaccess:add-exercise.label-superset')}
          value="superset"
          isChecked={exerciseType === 'superset'}
          handleChange={(value) => {
            formik.resetForm()
            formik.setValues(supersetInstance)
            setExerciseType(value)
            setValidate(validationSchema[`${value}ValidationSchema`])
          }}
        />
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {exerciseType == 'superset' && (
            <Input
              className="qa-add-exercise__workout-name"
              id="qa-add-workout-name"
              label={t('quickaccess:add-workout.name-of-workout') + '*'}
              name="name"
              value={formik.values.name}
              placeholder="-"
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
            />
          )}
          <DatePicker
            className="qa-add-exercise__date"
            id="qa-add-exercise-date"
            name={'date'}
            defaultPickerValue={moment()}
            format={'YYYY-MM-DD'}
            defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
            allowClear={false}
            label={t('quickaccess:add-exercise.label-date')}
            required
            value={formik.values.date}
            onChange={(value, dateStr) => formik.setFieldValue('date', dateStr)}
          />

          <TimeInput
            id="qa-add-exercise-time"
            className="qa-add-exercise__time"
            label="Schedule"
            name="time"
            value={formik.values.time}
            format="hh:mm"
            placeholder="hh:mm"
            onChange={(e) => formik.setFieldValue('time', e.target.value)}
          />

          {['strength', 'superset'].includes(exerciseType) && (
            <TimeInput
              id="workout-time"
              format={'hh:mm'}
              name={'total_workout_time'}
              label="Total Exercise Time"
              placeholder="hh:mm"
              value={formik.values.total_workout_time}
              className="qa-add-exercise__time"
              onChange={() => {}}
              disabled
              tooltip="Total exercise duration in hours and minutes"
              suffix={<ClockFilledGrey />}
            />
          )}

          {exerciseType !== 'superset' && (
            <AutoCompleteInput
              className="qa-add-exercise__exercise"
              id="qa-add-exercise-exercise"
              label={
                (exerciseType === 'strength'
                  ? t('quickaccess:add-exercise.label-exercise-name')
                  : t('quickaccess:add-exercise.label-cardio-name')) + '*'
              }
              name="name"
              placeholder={
                exerciseType === 'strength'
                  ? t('quickaccess:add-exercise.placeholder-exercise')
                  : t('quickaccess:add-exercise.placeholder-cardio')
              }
              value={formik.values.name}
              onChange={(value) => {
                setName(value)
                formik.setFieldValue('name', value)
                if (value.length >= 3) useTemplateExercise(value)
              }}
              onSelect={(value) => onExerciseSelected(value)}
              options={nameOptions}
            />
          )}

          <div className="qa-add-exercise__input-group">
            {exerciseType === 'strength' ? (
              <>
                <Input
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-sets"
                  label={t('quickaccess:add-exercise.label-sets') + '*'}
                  name="info.sets"
                  value={formik.values.info.sets}
                  placeholder="-"
                  onChange={(e) => {
                    formik.setFieldValue('info.sets', e.target.value)
                    handleCalculateTotalTime()
                  }}
                />
                <Input
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-reps"
                  label={t('quickaccess:add-exercise.label-reps')}
                  name="info.reps"
                  value={formik.values.info.reps}
                  placeholder="-"
                  onChange={(e) => {
                    formik.setFieldValue('info.reps', e.target.value)
                    handleCalculateTotalTime()
                  }}
                />
                <Input
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-tempo"
                  label={t('quickaccess:add-exercise.label-tempo')}
                  name="info.tempo"
                  value={formik.values.info.tempo}
                  placeholder="-"
                  onChange={(e) => {
                    !isNaN(+e.target.value) &&
                      formik.setFieldValue('info.tempo', e.target.value)
                    handleCalculateTotalTime()
                  }}
                />
                <TimeInput
                  name="info.rest_interval"
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-rest-interval"
                  label={t('quickaccess:add-exercise.label-rest')}
                  placeholder="-"
                  value={formik.values.info.rest_interval}
                  onChange={(e) => {
                    // !isNaN(+e.target.value) &&
                    formik.setFieldValue('info.rest_interval', e.target.value)
                    handleCalculateTotalTime()
                  }}
                  format="mm:ss"
                />
                <Input
                  className="qa-add-exercise__input-group-item-big"
                  id="qa-add-exercise-link"
                  label={`Link to video/instructions`}
                  name="link"
                  value={formik.values.link}
                  placeholder="https://"
                  onChange={(e) => formik.setFieldValue('link', e.target.value)}
                />
                <Textarea
                  className="Exercise__notes-textarea"
                  id="Exercise-notes"
                  label="Notes"
                  placeholder="You can add your notes here..."
                  value={formik.values.info?.notes}
                  rows={formik.values.info?.notes?.search('\n') >= 0 ? 4 : 1}
                  onChange={(e) => {
                    formik.setFieldValue('info.notes', e.target.value)
                  }}
                />
              </>
            ) : exerciseType === 'cardio' ? (
              <>
                <TimeInput
                  id="cardio-duration"
                  className="qa-add-exercise__input-group-item"
                  name={`info.duration`}
                  label={t('quickaccess:add-workout.duration') + '*'}
                  placeholder="hh:mm"
                  value={formik.values.info.duration}
                  onChange={(e) =>
                    formik.setFieldValue(`info.duration`, e.target.value)
                  }
                  format="hh:mm"
                />
                <Select
                  label={t('quickaccess:add-workout.intensity')}
                  name={`info.intensity`}
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-intensity"
                  value={formik.values.info.intensity}
                  onChange={(value) =>
                    formik.setFieldValue('info.intensity', value)
                  }
                  options={[
                    {
                      value: 'Low',
                      label: 'Low'
                    },
                    {
                      value: 'Moderate',
                      label: 'Moderate'
                    },
                    {
                      value: 'High',
                      label: 'High'
                    }
                  ]}
                  required
                />

                <Input
                  className="qa-add-exercise__input-group-item"
                  id="qa-add-exercise-avg"
                  label={`Avg heart rate (bpm)`}
                  name="info.avg_heart_rate"
                  value={formik.values.info.avg_heart_rate}
                  placeholder="90"
                  onChange={(e) =>
                    !isNaN(+e.target.value) &&
                    formik.setFieldValue('info.avg_heart_rate', e.target.value)
                  }
                />
                <Textarea
                  className="Exercise__notes-textarea"
                  id="Exercise-notes"
                  label="Notes"
                  placeholder="You can add your notes here..."
                  value={formik.values.info?.notes}
                  rows={formik.values.info?.notes?.search('\n') >= 0 ? 4 : 1}
                  onChange={(e) => {
                    formik.setFieldValue('info.notes', e.target.value)
                  }}
                />
              </>
            ) : (
              <>
                {formik.values.data.map((item: any, key: number) => (
                  <div className={`qa-add-exercise__input-group`} key={key}>
                    <div className="qa-add-exercise__box-headline qa-add-exercise__w-100 ">
                      <h3 className="qa-add-exercise__now-wrap">
                        {t('quickaccess:add-workout.exercise')} {key + 1}
                      </h3>
                      <DeleteOutlinedIcon
                        onClick={() => deleteExercise(key)}
                        className={`qa-add-exercise__trash`}
                      />
                      <hr className="qa-add-exercise__w-100 qa-add-exercise__ml-10" />
                    </div>

                    <AutoCompleteInput
                      id={`qa-add-exercise-exercise-${key}`}
                      label={
                        t('quickaccess:add-exercise.label-exercise-name') + '*'
                      }
                      name={`data.${key}.name`}
                      placeholder={t(
                        'quickaccess:add-exercise.placeholder-exercise'
                      )}
                      value={formik.values.data[key].name}
                      onChange={(value) => {
                        setName(value)
                        formik.setFieldValue(`data.${key}.name`, value)
                        if (value.length >= 3) useTemplateExercise(value)
                      }}
                      options={nameOptions}
                      onSelect={(value) => onExerciseSelected(value, key)}
                    />

                    <Input
                      className="qa-add-exercise__input-group-item"
                      id={`qa-add-exercise-sets-${key}`}
                      label={t('quickaccess:add-exercise.label-sets') + '*'}
                      name={`data.${key}.info.sets`}
                      value={item.info.sets}
                      placeholder="-"
                      onChange={(e) => {
                        !isNaN(+e.target.value) &&
                          formik.setFieldValue(
                            `data.${key}.info.sets`,
                            e.target.value
                          )
                        handleCalculateTotalTime()
                      }}
                    />
                    <Input
                      className="qa-add-exercise__input-group-item"
                      id={`qa-add-exercise-reps-${key}`}
                      label={t('quickaccess:add-exercise.label-reps')}
                      name={`data.${key}.info.reps`}
                      value={item.info.reps}
                      placeholder="-"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `data.${key}.info.reps`,
                          e.target.value
                        )
                        handleCalculateTotalTime()
                      }}
                    />
                    <Input
                      className="qa-add-exercise__input-group-item"
                      id={`qa-add-exercise-tempo-${key}`}
                      label={t('quickaccess:add-exercise.label-tempo')}
                      name={`data.${key}.info.tempo`}
                      value={item.info.tempo}
                      placeholder="-"
                      onChange={(e) => {
                        !isNaN(+e.target.value) &&
                          formik.setFieldValue(
                            `data.${key}.info.tempo`,
                            e.target.value
                          )
                        handleCalculateTotalTime()
                      }}
                    />
                    <TimeInput
                      name={`data.${key}.info.rest_interval`}
                      className="qa-add-exercise__input-group-item"
                      id={`qa-add-exercise-rest-interval-${key}`}
                      label={t('quickaccess:add-exercise.label-rest')}
                      placeholder="-"
                      value={item.info.rest_interval}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `data.${key}.info.rest_interval`,
                          e.target.value
                        )
                        handleCalculateTotalTime()
                      }}
                      format="mm:ss"
                    />
                    <Input
                      className="qa-add-exercise__input-group-item-big"
                      id={`qa-add-exercise-link-${key}`}
                      label={`Link to video/instructions`}
                      name={`data.${key}.link`}
                      value={item.link}
                      placeholder="https://"
                      onChange={(e) =>
                        formik.setFieldValue(`data.${key}.link`, e.target.value)
                      }
                    />
                  </div>
                ))}

                <Textarea
                  className="Exercise__notes-textarea"
                  id="Exercise-notes"
                  label="Notes"
                  placeholder="You can add your notes here..."
                  value={formik.values.info?.notes}
                  rows={formik.values.info?.notes?.search('\n') >= 0 ? 4 : 1}
                  onChange={(e) => {
                    formik.setFieldValue('info.notes', e.target.value)
                  }}
                />

                <button
                  type="button"
                  className="qa-add-exercise__add-another-button"
                  onClick={handleAddExercise}
                >
                  <AddIcon />{' '}
                  <span>
                    {t('quickaccess:add-workout.add_another_exercise')}
                  </span>
                </button>
              </>
            )}

            <div className="qa-add-exercise__button-group">
              <Button htmlType={'submit'} className="qa-add-exercise__button">
                {t('quickaccess:add-exercise.add')}
              </Button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </Styles>
  )
}

export default QuickAccessAddExercise
