import { Tabs } from 'antd'
import React, { FC, useEffect, useState } from 'react'

import userTypes from '../../../../enums/user-types.enum'
import useTrainerAccount from '../../../../hooks/api/accounts/useTrainerAccount'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import eventBus from '../../../../utils/EventBus'
import QuickAccessChat from '../../components/quick-access-chat/quick-access-chat.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import QuickAccessAdd from '../quick-access-add/quick-access-add.component'
import QuickAccessLog from '../quick-access-log/quick-access-log.component'
import Styles, { StyledTabs } from './quick-access-home.styles'

type Props = {}

const QuickAccessHome: FC<Props> = ({}) => {
  const { route, setRoute } = useQuickAccess()
  const [tabKey, setTabKey] = useState(0)
  const { t } = useTranslation()
  const { type } = useAuth()
  const { user: trainer } = useTrainerAccount()

  const toggleKey = () => {
    setTabKey(tabKey + 1)
  }

  useEffect(() => {
    eventBus.on('openLogPopup', toggleKey)
    return () => eventBus.remove('openLogPopup', toggleKey)
  }, [])

  return (
    <Styles>
      <StyledTabs
        key={tabKey}
        defaultActiveKey={String(route)}
        onChange={(r) => setRoute(+r)}
      >
        <Tabs.TabPane tab={t('quickaccess:log')} key={quickAccessRoutes.LOG}>
          <QuickAccessLog />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('quickaccess:add')} key={quickAccessRoutes.ADD}>
          <QuickAccessAdd />
        </Tabs.TabPane>
      </StyledTabs>

      {type === userTypes.CLIENT && trainer.id ? <QuickAccessChat /> : null}
    </Styles>
  )
}

export default QuickAccessHome
