import moment from 'moment'

import { Activity } from '../../types/activity.type'
import { DATE_FORMAT, TIME_FORMAT } from '../date'

export const EVENT_LABELS: any = {
  // invoices: 'Invoice Due Date',
  invoices: 'Invoice',
  sessions: 'PT Session'
}

export function parseActivities(data: any[]): Activity[] {
  const res: any[] = []
  data?.forEach((row) => {
    const activities = row?.activities
      .filter(
        (item: any) =>
          item?.resource?.name ||
          item?.resource?.data?.name ||
          item?.resource_type === 'invoices' ||
          item?.resource_type === 'sessions'
      )
      .map((act: any) => ({
        ...act,
        date: row.date
      }))

    activities.forEach((item: any) => {
      if (item.resource_type != 'meals') {
        if (!item.resource.items) {
          item.resource.data?.info
            ? (item.resource.data.info.type = item.resource.data.info.intensity
                ? 'cardio'
                : 'strength')
            : null
        } else {
          item.resource.items.forEach((e: any) => {
            if (Array.isArray(e.data)) {
              e.data.forEach((j: any) => {
                j.info.type = j.info.intensity ? 'cardio' : 'strength'
              })
            } else {
              e.data.info.type = e.data.info.intensity ? 'cardio' : 'strength'
            }
          })
        }
      }
    })

    res.push(...activities)
  })

  return res as unknown as Activity[]
}

export function getEventTitle(e: any) {
  return (
    (e.resource_type === 'sessions'
      ? e.resource.type
      : e.resource_type === 'events'
      ? e.resource.name
      : EVENT_LABELS[e.resource_type]) || 'Event'
  )
}

export function getEventTime(e: any) {
  const resource_type = e.resource_type
  if (resource_type === 'sessions') {
    const start = moment(`${e.date} ${e.time}`, `${DATE_FORMAT} ${TIME_FORMAT}`)
    const end = moment(start).add(moment.duration(e.resource.duration))
    return {
      start: start.toDate(),
      end: end.toDate()
    }
  } else if (resource_type === 'invoices') {
    const date = moment(e.date, DATE_FORMAT)
    return {
      start: date.toDate(),
      end: date.toDate()
    }
  } else if (resource_type === 'events') {
    const start = moment(`${e.date} ${e.time}`, `${DATE_FORMAT} HH:mm:ss`)
    const end = moment(start).add(moment.duration(e.resource.duration))
    return {
      start: start.toDate(),
      end: end.toDate()
    }
  } else if (
    resource_type === 'workouts' ||
    resource_type === 'meals' ||
    resource_type === 'workout_items'
  ) {
    const start = moment(
      `${e.date} ${e.resource.time || e.resource?.data?.info?.schedule}`,
      `${DATE_FORMAT} HH:mm:ss`
    )
    const end = moment(start).add(moment.duration(e.resource.duration))
    return {
      start: start.toDate(),
      end: end.toDate()
    }
  }
  return null
}

// returns true if set, false if not set
export function checkIfEventSet(e: any) {
  const resource_type = e.resource_type
  if (resource_type === 'sessions' && (!e.date || !e.time)) {
    return false
  } else if (resource_type === 'invoices' && (!e.date || !e.time)) {
    return false
  } else if (resource_type === 'events' && (!e.date || !e.time)) {
    return false
  } else if (
    (resource_type === 'workouts' ||
      resource_type === 'meals' ||
      resource_type === 'workout_items') &&
    !e.resource.time &&
    !e.resource?.data?.info?.schedule
  ) {
    return false
  }
  return true
}

export function formatWeekActivities(data: Activity[]) {
  try {
    const res: any[] = []

    data.forEach((row) => {
      const time = getEventTime(row)
      if (row.resource_type === 'sessions' && row.resource?.duration) {
        res.push({
          title: getEventTitle(row),
          start: time?.start,
          end: time?.end,
          resource: {
            type: 'session',
            session: row.resource
          },
          ...row,
          id: row._id
        })
      } else if (row.resource_type === 'invoices') {
        res.push({
          title: 'Invoice',
          allDay: true,
          start: time?.start,
          end: time?.end,
          resource: {
            type: 'invoice'
          },
          ...row,
          id: row._id
        })
      } else if (row.resource_type === 'events') {
        res.push({
          title: getEventTitle(row),
          start: time?.start,
          end: time?.end,
          resource: {
            type: 'event'
          },
          id: row._id
        })
      } else if (
        row.resource_type === 'workouts' ||
        row.resource_type === 'meals' ||
        row.resource_type === 'workout_items'
      ) {
        res.push({
          title: row.resource?.name || row?.resource?.data?.name,
          start: time?.start || row?.resource?.data?.info?.schedule,
          end: time?.end,
          ...row,
          id: row._id
        })
      } else {
        const start = moment(
          `${row.date} ${row.time}`,
          `${DATE_FORMAT} HH:mm:ss`
        )
        res.push({
          title: row.name,
          start: start.toDate(),
          end: start.toDate(),
          id: row._id
        })
      }
    })

    return res
  } catch (e: any) {
    console.error(e)
    return []
  }
}

export function formatEventValues(
  values: any,
  accountId: number,
  accountType: string
) {
  const formData: any = {}

  formData['account_id'] = accountId
  formData['access'] = accountType

  Object.keys(values).forEach((key) => {
    formData[key] = values[key]
  })

  return formData
}

export function formatAndSortMonthActivities(activities: any) {
  const notSetActivities = activities
    .filter((activity: any) => !checkIfEventSet(activity))
    .map((activity: any) => {
      if (
        activity.resource_type === 'invoices' ||
        activity.resource_type === 'sessions'
      )
        activity.title = EVENT_LABELS[activity.resource_type] || 'unset'
      return activity
    })
    .sort((a: any, b: any) => {
      const nameA = a.resource?.name?.toUpperCase()
      const nameB = b.resource?.name?.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

  const setActivities = activities
    .filter((activity: any) => checkIfEventSet(activity))
    .map((activity: any) => {
      if (
        activity.resource_type === 'invoices' ||
        activity.resource_type === 'sessions'
      )
        activity.title = EVENT_LABELS[activity.resource_type] || 'unset'
      return activity
    })
    .sort((a: any, b: any) => {
      if (
        moment(getEventTime(a)?.start, 'hh:mm').isBefore(
          moment(getEventTime(b)?.start, 'hh:mm')
        )
      ) {
        return -1
      } else if (
        moment(getEventTime(a)?.start, 'hh:mm').isAfter(
          moment(getEventTime(b)?.start, 'hh:mm')
        )
      ) {
        return 1
      } else {
        const nameA = a.resource?.name?.toUpperCase()
        const nameB = b.resource?.name?.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    })

  return [...notSetActivities, ...setActivities]
}

export function getLoggedMessage(resource_type: string) {
  switch (resource_type) {
    case 'meals':
      return 'Meal successfully logged'
      break
    case 'workouts':
      return 'Workout successfully logged'
      break
    case 'workout_items':
      return 'Cardio successfully logged'
      break
    case 'invoices':
      return 'Invoice successfully logged'
      break
    case 'sesions':
      return 'Session successfully logged'
      break

    default:
      return 'Event successfully logged'

      break
  }
}
