function getBase(base: string) {
  if (process.env.PUBLIC_URL) {
    const publicUrl = process.env.PUBLIC_URL
    const publicUrlProtocol = publicUrl.split(':')[0]
    const publicUrlHost = publicUrl.split('/')[2]

    if (publicUrlHost.startsWith('localhost')) {
      return 'http://localhost:5111'
    }

    return `${publicUrlProtocol}://${base}.${publicUrlHost}`
  } else if (typeof window !== 'undefined') {
    if (window.location.host.startsWith('localhost')) {
      return 'http://localhost:5111'
    }
    // if (window.location.host.startsWith('app')) {
    //   const hostArr = window.location.host.split('.')
    //   hostArr.shift()

    //   return `${window.location.protocol}//${base}.${hostArr.join('.')}`
    // }

    return `${window.location.protocol}//${base}.${window.location.host}`
  } else {
    return `https://${base}.liverightstaging.xyz`
  }
}

export function payments(id: number | string) {
  return `${getBase('payments')}/invoices/${id}/pay`
}

export function invoices(id: number | string) {
  return `${getBase('invoices')}/invoices/${id}`
}

export const formatCreditCard = (no: string) => {
  let formatedNo = no.replace(/.(?=.{4})/g, '*')
  formatedNo = formatedNo.replace(/^(.{3})(.{4})(.{5})(.*)$/, '$1 $2 $3 $4')
  return formatedNo
}
