import moment from 'moment'
import { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Routes } from '../../../../enums/routes.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useIsMobile } from '../../../../hooks/is-mobile.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import {
  useActivities,
  useActivityMutations,
  useDietPlanCurrentRevision,
  useTrainingPlanCurrentRevision
} from '../../../../store/query'
import { getActivities } from '../../../../utils/api/activities'
import { getRoute } from '../../../../utils/routes'
import LoaderComponent from '../../../loader/loader.component'
import { useQuickAccess } from '../../quick-access.context'
import { QuickAccessActionType } from '../../types/quick-access-action.type'
import { useOpenContext } from '../quick-access-popup/openContext'
import Styles from './quick-access-action.styles'

const QuickAccessAction: FC<QuickAccessActionType> = ({
  route,
  icon: Icon,
  label,
  color,
  disabled,
  setOpenWorkout,
  setOpenMeal,
  setWorkoutData,
  setMealData
}) => {
  const history = useHistory()
  const { setOpen }: any = useOpenContext()
  const { t } = useTranslation()
  const { setRoute, client } = useQuickAccess()
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(false)
  const auth = useAuth()

  const dietPlanRevision = useDietPlanCurrentRevision(client?.id)
  const trainingPlanRevision = useTrainingPlanCurrentRevision(client?.id)

  const changeLogRoute = (overwrite?: any) => {
    trainingPlanRevision.refetch()
    if (isMobile && label !== 'measurement') {
      setRoute(overwrite ?? route)
    } else {
      if (history.location.pathname !== `${overwrite ?? route}`) {
        history.push(`${overwrite ?? route}`)
        setOpen(false)
      }
    }
  }

  const { data: activitiesForToday, refetch } = useActivities(
    new Date(),
    'day',
    undefined,
    client?.id
  )

  const workoutsForToday = useMemo(() => {
    return activitiesForToday.filter(
      (a) =>
        a.resource_type === 'workouts' || a.resource_type === 'workout_items'
    )
  }, [activitiesForToday])

  const mealsForToday = useMemo(() => {
    return activitiesForToday.filter((a) => a.resource_type === 'meals')
  }, [activitiesForToday])

  const loggedWorkoutsForToday = useMemo(() => {
    return workoutsForToday.filter(
      (activity) => activity.status === 'everything_logged'
    )
  }, [workoutsForToday])

  const loggedMealsForToday = useMemo(() => {
    return mealsForToday.filter(
      (activity) => activity.status === 'everything_logged'
    )
  }, [mealsForToday])

  const notLoggedWorkoutsForToday = useMemo(() => {
    return workoutsForToday.filter(
      (activity) => !activity.status.includes('everything_logged')
    )
  }, [workoutsForToday])

  const notLoggedMealsForToday = useMemo(() => {
    return mealsForToday.filter(
      (activity) => !activity.status.includes('everything_logged')
    )
  }, [mealsForToday])

  const { rescheduleActivity } = useActivityMutations()

  const loadActivitiesDataMeal = async () => {
    const activeTrainingSplits = await fetchTrainingSplits(client?.id)
    refetch()

    // if (!activeTrainingSplits.length && !isMobile) {
    //   if (!isMobile && notLoggedMealsForToday.length === 1) {
    //     const event = notLoggedMealsForToday[0]
    //     rescheduleActivity(event, moment().minutes(0).toDate())

    //     const route =
    //       getRoute(Routes.LOGGING_MEAL, {
    //         date: moment(new Date()).format('YYYY-MM-DD'),
    //         id: event._id
    //       }) + (client ? `?client_id=${client?.id}` : '')

    //     changeLogRoute(route)
    //     setIsLoading(false)
    //     !isMobile && setOpen(false)
    //   } else {
    //     let revisionData = dietPlanRevision.data

    //     revisionData = revisionData?.filter((meal: any) => {
    //       for (let i = 0; i < meal.activities.length; i++) {
    //         if (
    //           notLoggedMealsForToday?.find(
    //             (item: any) => item.resource._id == meal.activities[i]._id
    //           ) ||
    //           !meal.activities[i].items.length
    //         ) {
    //           return false
    //         }
    //       }
    //       return true
    //     })

    //     //If no meals scheduled for the day but the diet plan is active...
    //     if (notLoggedMealsForToday.length === 0 && revisionData.length > 0) {
    //       const alreadyUsedRevision = revisionData.find((revision: any) =>
    //         revision.activities.find((a: any) =>
    //           mealsForToday.find((meal) => meal.resource?._id === a._id)
    //         )
    //       )

    //       revisionData.forEach((revision: any) => {
    //         revision.activities = revision.activities.filter(
    //           (activity: any) => {
    //             return !loggedMealsForToday?.find(
    //               (item: any) => item.resource._id == activity._id
    //             )
    //           }
    //         )
    //       })

    //       setMealData?.(revisionData, alreadyUsedRevision)
    //       setOpenMeal?.(true)
    //     } else {
    //       setIsLoading(false)
    //       changeLogRoute()
    //       !isMobile && setOpen(false)
    //     }
    //   }
    // } else {
    setIsLoading(false)
    changeLogRoute()
    !isMobile && setOpen(false)
    // }
  }
  const fetchTrainingSplits = async (clientId?: number) => {
    return await getActivities('/training-splits', {
      page: 1,
      per_page: 10,
      filter: {
        account_id: clientId || auth.id || '',
        status: 'active'
      }
    })
  }

  const loadActivitiesDataWorkout = async () => {
    const activeTrainingSplits = await fetchTrainingSplits(client?.id)
    refetch()
    // console.log({activeTrainingSplits, notLoggedWorkoutsForToday})
    // if (!activeTrainingSplits.length) {
    //   if (!isMobile && notLoggedWorkoutsForToday.length === 1) {
    //     const event = notLoggedWorkoutsForToday[0]

    //     rescheduleActivity(event, moment().minutes(0).toDate())

    //     const route =
    //       getRoute(Routes.LOGGING_EXERCISE_LOG, {
    //         date: moment(new Date()).format('YYYY-MM-DD'),
    //         id: event._id
    //       }) + (client ? `?client_id=${client?.id}` : '')

    //     changeLogRoute(route)
    //     setIsLoading(false)
    //     !isMobile && setOpen(false)
    //   } else {
    //     let revisionData = trainingPlanRevision.data

    //     revisionData = revisionData.filter((workout: any) => {
    //       return !loggedWorkoutsForToday.find(
    //         (item: any) => workout._id === item.resource._id
    //       )
    //     })

    //     //If no workouts scheduled for the day but the training plan is active...
    //     if (notLoggedWorkoutsForToday.length === 0 && revisionData.length > 0) {
    //       setWorkoutData?.(revisionData)
    //       setOpenWorkout?.(true)
    //     } else {
    //       setIsLoading(false)
    //       changeLogRoute()
    //       !isMobile && setOpen(false)
    //     }
    //   }
    // } else {
      setIsLoading(false)
      changeLogRoute()
      !isMobile && setOpen(false)
    // }
  }

  return (
    <Styles
      color={color}
      disabled={disabled}
      onMouseOver={() => {}}
      onClick={() => {
        if (disabled) {
          return
        }

        if (label == 'log-workout') {
          setIsLoading(true)
          loadActivitiesDataWorkout()
        } else if (label == 'log-meal') {
          setIsLoading(true)
          loadActivitiesDataMeal()
        } else {
          changeLogRoute()
          !isMobile && setOpen(false)
        }
      }}
      className={'qa-action'}
    >
      <div className="tooltip">
        <span className="tooltiptext">
          {disabled && label === 'log-workout'
            ? 'Add a workout or activate a training plan to start logging'
            : ''}

          {disabled && label === 'log-meal'
            ? 'Add a meal or activate a meal plan to start logging'
            : ''}
        </span>
      </div>
      {isLoading ? (
        <LoaderComponent width={'36px'} color={'secondary'} />
      ) : (
        <Icon />
      )}

      <span className={'qa-action__label'}>
        {t(`quickaccess:menu.${label}`)}
      </span>
    </Styles>
  )
}

export default QuickAccessAction
