import styled from 'styled-components'

import { darken } from '../../../../assets/styles/_media'

export default styled.div<{ color: string; disabled?: boolean }>`
  width: 115px;
  height: 100px;
  background-color: ${(p) => p.theme.vars.colors.secondary3_v2};
  color: ${({ color }) => color};
  ${(p) => p.theme.extend.flexCenter}
  flex-direction: column;
  border-radius: 10px;
  ${(p) =>
    p.disabled
      ? `
  > svg > * {
    fill: ${darken(p.color ?? p.theme.vars.colors.secondary3_v2, 0.9)};
  }

  > * {
    color: ${darken(p.color ?? p.theme.vars.colors.secondary3_v2, 0.8)};
  }
  `
      : ''}
  padding: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: ${(p) => p.theme.vars.defaults.transition};
  &:hover {
    background-color: ${(p) => darken(p.theme.vars.colors.secondary3_v2, 0.98)};

    ${(p) =>
      p.disabled
        ? `
    :hover .tooltiptext {
      visibility: visible;
    }
`
        : ''}
  }
  svg {
    width: 36px;
    height: 36px;
    display: block;
    color: ${({ color }) => color};
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    transform: translate(-110px, -80px);
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: white;
    color: #000;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .tooltip .qa-action {
    &__label {
      margin-top: 11px;
      font-size: 12px;
      font-weight: 300;
    }
  }
`
