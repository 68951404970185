import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'

import { AddIcon, CheckIcon, EditIcon } from '../../../../assets/media/icons'
import VideoImg from '../../../../assets/media/quick-access-video.png'
import { useAuth } from '../../../../hooks/auth.hook'
import useExerciseLoggedInfo from '../../../../hooks/workout-log-info.hook'
import Formatter from '../../../../managers/formatter.manager'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { getLoggedData } from '../../../../services/api/activities'
import { AddLogToActivityOfTheCalendar } from '../../../../services/api/logging'
import { INCREASE_ACTIVE_WORKOUT_INDEX } from '../../../../store/action-types'
import { RootState } from '../../../../store/reducers'
import { omitEmpty } from '../../../../utils/obj'
import { stringifyURL } from '../../../../utils/query'
import Button from '../../../buttons/button/button.component'
import Input from '../../../form/input/input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessExerciseInput from '../../components/quick-access-exercise-input/quick-access-exercise-input.component'
import QuickAccessModal from '../../components/quick-access-modal/quick-access-modal.component'
import ProggressBar from '../../components/quick-access-progress-bar/quick-access-progress-bar.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-logging-strength.styles'

function getKey(params: any) {
  return stringifyURL('/logged-info', {
    ...params,
    filter: omitEmpty(params?.filter)
  })
}

const QuickAccessLoggingStrength: FC = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const { routeParams, setRoute, workoutProgress, setWorkoutProgress, client } =
    useQuickAccess()
  const { workoutItems, activeWorkoutIndex, activeWorkoutData } = useSelector(
    (state: RootState) => state.logWorkout
  )
  const workout = routeParams.workout

  const [sets, setSets] = useState<any>([])
  const [prevSets, setPrevSets] = useState<any>([])
  const [prevVolume, setPrevVolume] = useState<any>(0)

  const dispatch = useDispatch()
  const { exercise }: any = routeParams

  const { log }: any = exercise ? exercise : { log: [] }
  useEffect(() => {
    if (log && log?.was_skipped) {
      skipExercise()
    }
  }, [log])

  async function getValues() {
    const params = {
      filter: {
        account_id: client?.id || auth.id,
        resource_type: 'workout_items',
        resource_id: exercise._id
      }
    }
    const response = await getLoggedData(getKey(params))
    setSets(response?.resource?.log?.info.sets)
  }

  useEffect(() => {
    getValues()
  }, [])

  // timer
  const restTime = new Date()
  const execIntervalMinutesInSeconds =
    +exercise?.data?.info?.rest_interval?.split(':')[0] * 60
  const execIntervalSeconds =
    +exercise?.data?.info?.rest_interval?.split(':')[1]
  restTime.setSeconds(
    restTime.getSeconds() + (execIntervalMinutesInSeconds + execIntervalSeconds)
  ) // 10 min

  const {
    seconds,
    minutes,
    isRunning,
    restart: restartTimer,
    pause: pauseTimer,
    start: startTimer
  } = useTimer({
    expiryTimestamp: restTime,
    autoStart: false,
    onExpire: () => {
      if (isResting) {
        setIsResting(false)
        if (exerciseSets.length - 1 === currentSetIndex) {
          // go to next exercise
        } else {
          // go to next set
          restartTimer(restTime, false)
        }
      }
    }
  })
  const { _id }: any = routeParams.exercise

  const exerciseInfo = useExerciseLoggedInfo(log, routeParams.exercise)

  const [currentSetIndex, setCurrentSetIndex] = useState(
    exerciseInfo.activeSetIndex
  )

  const prevValues = useMemo(() => {
    const kgs: any = sets?.find(
      (sets: any) => currentSetIndex + 1 == sets.set
    )?.kgs
    const reps: any = sets?.find(
      (sets: any) => currentSetIndex + 1 == sets.set
    )?.reps
    return { kgs, reps }
  }, [sets, currentSetIndex])

  const [editingSetIndex, setEditingSetIndex] = useState<number | null>(null)
  const [exerciseSets, setExerciseSets] = useState<{ [key: string]: number }[]>(
    exerciseInfo.sets
  )

  const [currentWeight, setCurrentWeight] = useState(
    exerciseSets[exerciseInfo.activeSetIndex]?.kgs || 0
  )
  const [currentReps, setCurrentReps] = useState(
    exerciseSets[exerciseInfo.activeSetIndex]?.reps || 0
  )
  const [totalVolume, setTotalVolume] = useState(0)
  const [showVideo, setShowVideo] = useState(false)
  const [isResting, setIsResting] = useState(false)

  useEffect(() => {
    let id: any
    workout?.forEach((ex: any, i: number) => {
      if (
        ex?._id === _id &&
        workout[i - 1]?.data?.info?.type != 'cardio' &&
        !workout[i - 1]?.is_superset
      ) {
        id = workout[i - 1]?._id
      }
    })
    const getSets = async () => {
      const params = {
        filter: {
          account_id: client?.id || auth.id,
          resource_type: 'workout_items',
          resource_id: id
        }
      }
      const response = await getLoggedData(getKey(params))
      setPrevSets(response?.resource?.log?.info?.sets)
    }
    if (id) {
      getSets()
    }
  }, [_id])

  useEffect(() => {
    let total = 0
    if (prevSets?.length) {
      prevSets.forEach((set: any) => {
        total += set.kgs * set.reps
      })
    }
    setPrevVolume(total)
  }, [prevSets])

  useEffect(() => {
    restartTimer(restTime, false)
    setExerciseSets(exerciseInfo.sets)
  }, [exerciseInfo.sets])

  useEffect(() => {
    setCurrentSetIndex(exerciseInfo.activeSetIndex)
  }, [exerciseInfo.activeSetIndex])

  useEffect(() => {
    setWorkoutProgress(
      (activeWorkoutIndex / Number(routeParams.lengthOfWorkout)) * 100
    )
  }, [])

  useEffect(() => {
    calculateTotalVolume()
  }, [currentSetIndex, editingSetIndex, exerciseSets.length])

  const handleChangeSet = (
    field: string,
    value: number,
    index = currentSetIndex
  ) => {
    const newSets = [...exerciseSets]
    newSets[index][field] = value
    setExerciseSets(newSets)
  }

  const onCheckIconClick = () => {
    setEditingSetIndex(null)
    postSet(true)
  }

  const calculateTotalVolume = () => {
    let totalVolume = 0
    exerciseSets.slice(0, currentSetIndex).forEach((set) => {
      totalVolume += +set.kgs * +set.reps
    })
    setTotalVolume(totalVolume)
  }

  const formatTime = (minutes: number, seconds: number) => {
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`
  }

  const postSet = async (single?: boolean) => {
    const res = []
    if (single) {
      for (let index = 0; index < currentSetIndex; index++) {
        res.push(exerciseSets[index])
      }
    } else {
      for (let index = 0; index <= currentSetIndex; index++) {
        res.push(exerciseSets[index])
      }
    }

    const data = {
      items: [
        {
          _id: _id,
          data: {
            info: {
              sets: res
            }
          }
        }
      ]
    }

    const response = await AddLogToActivityOfTheCalendar(
      routeParams.workoutDate,
      routeParams.workoutId,
      data,
      client?.id
    )

    if (response) {
      toast.show({ type: 'success', msg: 'Exercise successfully logged' })
    }
    return response
  }
  async function skipSets() {
    const data = {
      items: [
        {
          _id: _id,
          data: {
            was_skipped: true,
            info: {
              sets: sets
            }
          }
        }
      ]
    }
    const response = await AddLogToActivityOfTheCalendar(
      routeParams.workoutDate,
      routeParams.workoutId,
      data,
      client?.id
    )

    if (response) {
      toast.show({ type: 'success', msg: 'Exercise successfully skipped' })
    }
  }

  function navigateToNextLink() {
    if (workoutItems[activeWorkoutIndex + 1]?.data?.length) {
      setRoute(quickAccessRoutes.WORKOUT_LOG_SUPERSET, {
        name: workoutItems[activeWorkoutIndex + 1]?.name,
        workoutId: workoutItems[activeWorkoutIndex + 1]?.workOutId,
        exercise: workoutItems[activeWorkoutIndex + 1],
        workoutDate: workoutItems[activeWorkoutIndex + 1]?.date,
        logData: workoutItems[activeWorkoutIndex + 1]?.log,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: workout || []
      })
    } else if (
      workoutItems[activeWorkoutIndex + 1]?.data?.info?.type === 'cardio'
    ) {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO, {
        id: activeWorkoutData.id,
        name: workoutItems[activeWorkoutIndex + 1]?.name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1]?.log,
        workoutId: workoutItems[activeWorkoutIndex + 1]?.workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1]?.date,
        withinWorkout: 'true',
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: workout || []
      })
    } else {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_STRENGTH, {
        id: workoutItems[activeWorkoutIndex + 1]?.data._id,
        name: workoutItems[activeWorkoutIndex + 1]?.name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1]?.data.log,
        workoutId: workoutItems[activeWorkoutIndex + 1]?.workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1]?.date,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: workout || []
      })
    }
  }

  const goToNextSet = async () => {
    if (
      exerciseSets.length - 1 === currentSetIndex ||
      exerciseSets.length === currentSetIndex
    ) {
      // go to next exercise
      restartTimer(restTime)
      pauseTimer()
      setWorkoutProgress(
        ((activeWorkoutIndex + 1) / Number(routeParams.lengthOfWorkout)) * 100
      )

      handleChangeSet('kgs', currentWeight)
      handleChangeSet('reps', currentReps)
      setCurrentWeight(exerciseSets[currentSetIndex].kgs || 0)
      setCurrentReps(exerciseSets[currentSetIndex].reps || 0)
      const res = await postSet()
      if (res) {
        dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
        setCurrentSetIndex(0)
        workoutItems.length - 1 === activeWorkoutIndex
          ? setRoute(quickAccessRoutes.LOG_EXERCISE)
          : navigateToNextLink()
        return
      }
    }
    setCurrentSetIndex(currentSetIndex + 1)
    handleChangeSet('kgs', currentWeight)
    handleChangeSet('reps', currentReps)

    setCurrentWeight(exerciseSets[currentSetIndex + 1].kgs || 0)
    setCurrentReps(exerciseSets[currentSetIndex + 1].reps || 0)
    setIsResting(true)
    postSet()
    if (isRunning) {
      restartTimer(restTime)
    } else {
      startTimer()
    }
  }

  const skipRest = () => {
    setIsResting(false)
  }

  function skipExercise() {
    !log?.was_skipped && skipSets()
    dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
    workoutItems.length - 1 === activeWorkoutIndex
      ? setRoute(quickAccessRoutes.LOG_EXERCISE)
      : setRoute(quickAccessRoutes.WORKOUT_LOGGING_STRENGTH, {
          id: workoutItems[activeWorkoutIndex + 1].data._id,
          name: workoutItems[activeWorkoutIndex + 1].name,
          exercise: workoutItems[activeWorkoutIndex + 1],
          logData: workoutItems[activeWorkoutIndex + 1].data.log,
          workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
          workoutDate: workoutItems[activeWorkoutIndex + 1].date,
          lengthOfWorkout: routeParams.lengthOfWorkout
        })
  }

  const addSet = () => {
    if (editingSetIndex !== null) {
      return
    }
    setExerciseSets(
      exerciseSets.concat([
        {
          set: Number(exerciseSets[exerciseSets.length - 1].set) + 1,
          kgs: 0,
          reps: 0
        }
      ])
    )
  }

  return (
    <Styles>
      <div className="qa-logging-strength__container">
        <h3>{routeParams.name}</h3>
        <ProggressBar
          percent={workoutProgress}
          trailColor="#EDEDED"
          className="qa-logging-strength__progressbar"
        />
        <div className="qa-logging-strength__header">
          <div>
            <h2>{exercise.data.name}</h2>
            {exercise.data.link != null && (
              <span
                className="qa-logging-strength__video-link"
                onClick={() => setShowVideo(true)}
              >
                {t('quickaccess:logging-strength.video-link')}
              </span>
            )}
          </div>
          <div className="qa-logging-strength__exercise-info">
            <div>
              <span>{t('quickaccess:logging-strength.rest')}</span>
              <p>{exercise.data.info.rest_interval}</p>
            </div>
            <div>
              <span>{t('quickaccess:logging-strength.tempo')}</span>
              <p>{exercise.data.info.tempo}</p>
            </div>
          </div>
        </div>

        <div className="qa-logging-strength__body">
          <div className="qa-logging-strength__table-container">
            <table id="sets-table">
              <thead>
                <tr>
                  <th>{t('quickaccess:logging-strength.set')}</th>
                  <th>{t('quickaccess:logging-strength.weight')}</th>
                  <th>{t('quickaccess:logging-strength.reps')}</th>
                  <th>{t('quickaccess:logging-strength.volume')}</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {exerciseSets.map((set, index) => (
                  <tr key={index}>
                    {index === currentSetIndex ? (
                      <>
                        <td>{(index + 1).toString().padStart(2, '0')}</td>
                        <td className="current-set">
                          <span>
                            {t('quickaccess:logging-strength.logging')}
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                      </>
                    ) : (
                      <>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {(index + 1).toString().padStart(2, '0')}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index === editingSetIndex ? (
                            <Input
                              id="edit-set-kgs"
                              format={Formatter().number().min(1).max(999)}
                              value={exerciseSets[editingSetIndex].kgs}
                              onChange={(e) =>
                                handleChangeSet(
                                  'kgs',
                                  +e.target.value,
                                  editingSetIndex
                                )
                              }
                            />
                          ) : index > currentSetIndex ? (
                            '-'
                          ) : (
                            `${set.kgs.toString().padStart(2, '0')} kg`
                          )}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index === editingSetIndex ? (
                            <Input
                              id="edit-set-reps"
                              format={Formatter().number().min(1).max(999)}
                              value={exerciseSets[editingSetIndex].reps}
                              onChange={(e) =>
                                handleChangeSet(
                                  'reps',
                                  +e.target.value,
                                  editingSetIndex
                                )
                              }
                            />
                          ) : index > currentSetIndex ? (
                            '-'
                          ) : (
                            `${set.reps.toString().padStart(2, '0')}`
                          )}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index > currentSetIndex ? '-' : set.kgs * set.reps}
                        </td>
                        <td>
                          {index === editingSetIndex ? (
                            <button>
                              <CheckIcon
                                style={{ color: 'white' }}
                                onClick={onCheckIconClick}
                              />
                            </button>
                          ) : (
                            index < currentSetIndex && (
                              <EditIcon
                                onClick={() => setEditingSetIndex(index)}
                              />
                            )
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            className="qa-logging-strength__add-set-button"
            onClick={addSet}
          >
            <AddIcon />
            <span>{t('quickaccess:logging-strength.add-set-btn')}</span>
          </button>
        </div>

        <div className="qa-logging-strength__footer">
          <div className="qa-logging-strength__volume">
            {prevVolume != 0 && (
              <div>
                {t('quickaccess:logging-strength.previous-volume')}{' '}
                <span>{prevVolume}</span>
              </div>
            )}
            <div>
              {t('quickaccess:logging-strength.total-volume')}{' '}
              <span>{totalVolume}</span>
            </div>
          </div>

          <div
            className="qa-logging-strength__input-group"
            style={{ display: isResting ? 'block' : '' }}
          >
            {isResting ? (
              <QuickAccessExerciseInput
                id="log-exercise-rest-timer"
                label="Rest Time"
                disabled
                value={formatTime(minutes, seconds)}
                fullwidth
              />
            ) : (
              <>
                <QuickAccessExerciseInput
                  id="log-exercise-current-set"
                  label="Set"
                  disabled
                  value={(currentSetIndex + 1).toString().padStart(2, '0')}
                />
                <QuickAccessExerciseInput
                  id="log-exercise-current-kgs"
                  label="Kgs"
                  value={currentWeight}
                  previousValue={prevValues?.kgs}
                  format={Formatter().number().min(1).max(999)}
                  onChange={(e) => setCurrentWeight(+e.target.value)}
                />
                <QuickAccessExerciseInput
                  id="log-exercise-current-reps"
                  label="Reps"
                  value={currentReps}
                  previousValue={prevValues.reps}
                  format={Formatter().number().min(1).max(999)}
                  onChange={(e) => setCurrentReps(+e.target.value)}
                />
              </>
            )}
          </div>

          {isResting ? (
            <Button onClick={skipRest} style={{ width: '100%' }}>
              {t('quickaccess:logging-strength.skip-set-btn')}
            </Button>
          ) : (
            <div className="qa-logging-strength__button-group">
              <Button
                className="qa-logging-strength__button-skip"
                variant="dark"
                onClick={skipExercise}
              >
                {t('quickaccess:logging-strength.skip-exercise-btn')}
              </Button>
              <Button onClick={goToNextSet}>
                {workoutItems.length - 1 === activeWorkoutIndex &&
                (exerciseSets.length - 1 === currentSetIndex ||
                  exerciseSets.length === currentSetIndex)
                  ? `Finish Workout`
                  : `Next ${
                      exerciseSets.length - 1 === currentSetIndex ||
                      exerciseSets.length === currentSetIndex
                        ? 'Exercise'
                        : 'Set'
                    }`}
              </Button>
            </div>
          )}
        </div>

        <QuickAccessModal
          visible={showVideo}
          onCancel={() => setShowVideo(false)}
        >
          <img
            src={VideoImg}
            alt="workout-video"
            style={{ marginTop: '35px', width: '100%' }}
          />
        </QuickAccessModal>
      </div>
    </Styles>
  )
}

export default QuickAccessLoggingStrength
