import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import {
  ActivitiesV2Icon,
  BookmarkTrainerIcon,
  BrandLogoIcon,
  CalendarTrainerIcon,
  CalendarV2Icon,
  ChatTrainerIcon,
  ClientSolidIcon,
  FinancialsOrgIcon,
  FinancialsTrainerIcon,
  HomeTrainerIcon,
  HomeV2Icon,
  InvoiceV2Icon,
  LibraryV2Icon,
  NotificationTrainerIcon,
  NotificationV2Icon,
  ProgressIcon,
  ProgressTrainerIcon,
  ProgressV2Icon,
  RevenueIcon,
  SessionsV2Icon,
  SettingsOrgIcon,
  UserOrgIcon,
  UsersOrgIcon,
  UsersTrainerIcon,
  UserTrainerIcon
} from '../../assets/media/icons'
import UserBadgeCard from '../../components/cards/user-bardge-card/user-badge-card.component'
import ChatIcon from '../../components/chat-icon/chat-icon.component'
import NavSubMenu from '../../components/nav-submenus/nav-submenus.components'
import Tooltip from '../../components/tooltip/tooltip.component'
import { Routes } from '../../enums/routes.enum'
import userTypes from '../../enums/user-types.enum'
import useTrainerAccount from '../../hooks/api/accounts/useTrainerAccount'
import useChatOnline from '../../hooks/api/chat/useChatOnline'
import useChatUnread from '../../hooks/api/chat/useChatUnread'
import { useAuth } from '../../hooks/auth.hook'
import { useChats } from '../../modules/chat/contexts/chats.context'
import { useTranslation } from '../../modules/i18n/i18n.hook'
import { useUnreadNotifications } from '../../modules/notifications/hooks/notifications.hook'
import { capitalize } from '../../pipes/capitalize.pipe'
import { classes } from '../../pipes/classes.pipe'
import { getRoute } from '../../utils/routes'
import DesktopFooter from '../desktop-footer/desktop-footer.component'
import {
  ClientsStyles,
  OrgStyles,
  TrainerStyles
} from './desktop-sidebar.styles'

type MenuItemType = {
  name: string
  Icon: React.ComponentType
  url: string
  type?: string
  requireTrainer?: boolean
  occur?: string[]
  unreadCount?: number
  submenu?: {
    name: string
    url: string
    matchUrl?: string
  }[]
}

export default function DesktopSidebar() {
  const { type, id } = useAuth()
  const { pathname } = useLocation()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const unreadNotifications = useUnreadNotifications()

  useEffect(() => {
    if (
      pathname.includes('/training-split') &&
      !pathname.includes('/templates')
    ) {
      setIsCollapsed(true)
    } else {
      // setIsCollapsed(false)
    }
  }, [pathname])

  const clientMenuItems: MenuItemType[] = useMemo(
    () => [
      { name: 'home', url: Routes.HOME, Icon: HomeV2Icon },
      {
        name: 'activities',
        url: Routes.ACTIVITIES,
        Icon: ActivitiesV2Icon,
        submenu: [
          {
            name: 'Training Splits',
            url: getRoute(Routes.ACTIVITIES_TS, { clientId: id })
          },
          {
            name: 'Training Plans',
            url: getRoute(Routes.ACTIVITIES_TP, { clientId: id }),
            matchUrl: Routes.ACTIVITIES_TP
          },
          {
            name: 'Diet Plans',
            url: getRoute(Routes.ACTIVITIES_DP, { clientId: id }),
            matchUrl: Routes.ACTIVITIES_DP
          }
        ],
        occur: [Routes.ACTIVITIES]
      },
      {
        name: 'progress',
        url: Routes.PROGRESS_CLIENTS,
        Icon: ProgressIcon,
        type: userTypes.TRAINER,
        occur: ['progress']
      },
      {
        name: 'progress',
        url: Routes.PROGRESS_CLIENT_MEASUREMENTS,
        Icon: ProgressV2Icon,
        type: userTypes.CLIENT,
        occur: ['progress']
      },
      { name: 'sessions', url: Routes.SESSIONS, Icon: SessionsV2Icon },
      {
        name: 'clients',
        url: Routes.CLIENTS,
        Icon: ClientSolidIcon,
        type: userTypes.TRAINER
      },
      {
        name: 'invoices',
        url: Routes.INVOICES,
        Icon: InvoiceV2Icon,
        type: userTypes.CLIENT
      },
      {
        name: 'chat',
        url: Routes.CHAT,
        Icon: ChatIcon,
        type: userTypes.TRAINER
      },
      { name: 'calendar', url: Routes.CALENDAR, Icon: CalendarV2Icon },
      { name: 'library', url: Routes.ACTIVITIES_TM, Icon: LibraryV2Icon },
      {
        name: 'financials',
        url: Routes.FINANCIALS_OVERVIEW,
        Icon: RevenueIcon,
        type: userTypes.TRAINER
      },
      {
        name: 'notifications',
        url: Routes.NOTIFICATIONS,
        Icon: NotificationV2Icon,
        unreadCount: unreadNotifications
      }
    ],
    [id, unreadNotifications]
  )

  if (type === userTypes.CLIENT) {
    return (
      <>
        <ClientsStyles isCollapsed={isCollapsed} className={classes('sidebar')}>
          <div>
            <div className="sidebar__logo">
              {/* Letters are painted with white in the styles file */}
              <BrandLogoIcon />
            </div>

            {type === userTypes.CLIENT && (
              <TrainerBadge isCollapsed={isCollapsed} />
            )}

            <div className="sidebar__divider sidebar__divider_spacing" />

            <div className="sidebar__nav-spacer" />
            <nav className="sidebar__nav">
              <ul className="sidebar__menu">
                {clientMenuItems.map(
                  (
                    {
                      url,
                      name,
                      Icon,
                      type: permission,
                      requireTrainer,
                      occur,
                      submenu,
                      unreadCount
                    },
                    index
                  ) =>
                    !submenu ? (
                      (!permission || type === permission) &&
                      (!requireTrainer || type !== userTypes.CLIENT) && (
                        <Tooltip
                          title={isCollapsed ? capitalize(name) : ''}
                          placement="left"
                          color="#f18318"
                          key={index}
                        >
                          <Link
                            to={url}
                            key={url}
                            className={classes(
                              'sidebar__item',
                              (pathname === url ||
                                occur?.some((o) => pathname.includes(o))) &&
                                'sidebar__item_active'
                            )}
                          >
                            <Icon />
                            {unreadCount ? (
                              <span className="sidebar__item-unreadDot" />
                            ) : null}
                            <span className="sidebar__item-displayName">
                              {capitalize(name)}
                            </span>
                          </Link>
                        </Tooltip>
                      )
                    ) : submenu && isCollapsed ? (
                      <Tooltip
                        title={
                          <NavSubMenu
                            name={name}
                            url={url}
                            key={index}
                            submenu={submenu}
                            Icon={Icon}
                            pathname={pathname}
                            occur={occur}
                            styleType="Client"
                            isCollapsed
                          />
                        }
                        placement="left"
                        color="#0C0D0D"
                        align={{
                          offset: [15, 29],
                          targetOffset: [30, 30],
                          overflow: { adjustX: true, adjustY: true }
                        }}
                      >
                        <Link
                          to={`/${location.pathname}`}
                          key={url}
                          className={classes(
                            'sidebar__item',
                            (pathname === url ||
                              occur?.some((o) => pathname.includes(o))) &&
                              'sidebar__item_active'
                          )}
                          style={{ marginBottom: '3px' }}
                        >
                          <Icon />
                          {unreadCount ? (
                            <span className="sidebar__item-unreadDot" />
                          ) : null}
                          <span className="sidebar__item-displayName">
                            {capitalize(name)}
                          </span>
                        </Link>
                      </Tooltip>
                    ) : (
                      <NavSubMenu
                        name={name}
                        url={url}
                        key={index}
                        submenu={submenu}
                        Icon={Icon}
                        pathname={pathname}
                        occur={occur}
                        styleType="Client"
                      />
                    )
                )}
              </ul>
            </nav>
          </div>
        </ClientsStyles>

        <DesktopFooter
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </>
    )
  }

  if (type === userTypes.ORG) {
    return (
      <OrganizationDesktopSidebar
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
    )
  }

  return (
    <TrainerDesktopSidebar
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
    />
  )
}

function TrainerBadge({ isCollapsed }: { isCollapsed: boolean }) {
  const { t } = useTranslation()
  const { user: trainer, account } = useTrainerAccount()
  const { isOnline } = useChatOnline()
  const { rooms } = useChats()
  const { unread } = useChatUnread()

  const trainerRoom = rooms?.[Object.keys(rooms)[0]]

  if (!trainer.id) {
    return null
  }

  return (
    <Link to={Routes.CHAT}>
      <UserBadgeCard
        img={trainer.avatar?.url}
        firstName={trainer.first_name}
        lastName={trainer.last_name}
        userRole={t('your-trainer')}
        className="sidebar__trainer"
        unreadCount={unread}
        online={isOnline(account?.uuid, trainerRoom?.room?.meta?.lastSeenAt)}
        userBadgeSize={isCollapsed ? 'md' : undefined}
        hideContent={isCollapsed}
      />
    </Link>
  )
}

function TrainerDesktopSidebar({
  isCollapsed,
  setIsCollapsed
}: {
  isCollapsed: boolean
  setIsCollapsed: (collapsed: boolean) => void
}) {
  const { type } = useAuth()
  const { pathname } = useLocation()
  const { clientId } = useParams<any>()
  const unreadNotifications = useUnreadNotifications()
  const { unread: unreadChats } = useChatUnread()

  const trainerMenuItems: MenuItemType[] = [
    { name: 'home', url: Routes.HOME, Icon: HomeTrainerIcon },
    {
      name: 'activities',
      url: Routes.ACTIVITIES,
      Icon: ActivitiesV2Icon,
      submenu: [
        {
          name: 'Training Splits',
          url: getRoute(Routes.ACTIVITIES_TS, {
            clientId: clientId ? clientId : 'all'
          })
        },
        {
          name: 'Training Plans',
          url: getRoute(Routes.ACTIVITIES_TP, {
            clientId: clientId ? clientId : 'all'
          }),
          matchUrl: Routes.ACTIVITIES_TP
        },
        {
          name: 'Diet Plans',
          url: getRoute(Routes.ACTIVITIES_DP, {
            clientId: clientId ? clientId : 'all'
          }),
          matchUrl: Routes.ACTIVITIES_DP
        }
      ],
      occur: [Routes.ACTIVITIES]
    },
    {
      name: 'progress',
      url: Routes.PROGRESS_CLIENTS,
      Icon: ProgressTrainerIcon,
      type: userTypes.TRAINER,
      occur: ['progress']
    },
    { name: 'sessions', url: Routes.SESSIONS, Icon: UsersTrainerIcon },
    {
      name: 'clients',
      url: Routes.CLIENTS,
      Icon: UserTrainerIcon,
      type: userTypes.TRAINER
    },

    {
      name: 'chat',
      url: Routes.CHAT,
      Icon: ChatTrainerIcon,
      type: userTypes.TRAINER,
      unreadCount: unreadChats
    },
    { name: 'calendar', url: Routes.CALENDAR, Icon: CalendarTrainerIcon },
    { name: 'library', url: Routes.ACTIVITIES_TM, Icon: BookmarkTrainerIcon },
    {
      name: 'financials',
      url: Routes.FINANCIALS_OVERVIEW,
      Icon: FinancialsTrainerIcon,
      type: userTypes.TRAINER
    },
    {
      name: 'notifications',
      url: Routes.NOTIFICATIONS,
      Icon: NotificationTrainerIcon,
      unreadCount: unreadNotifications
    }
  ]

  return (
    <>
      <TrainerStyles isCollapsed={isCollapsed} className={classes('sidebar')}>
        <div>
          <div className="sidebar__logo">
            <BrandLogoIcon />
          </div>
          <div className="sidebar__nav-spacer" />
          <nav className="sidebar__nav">
            <ul className="sidebar__menu">
              {trainerMenuItems.map(
                ({
                  url,
                  name,
                  Icon,
                  type: permission,
                  requireTrainer,
                  occur,
                  submenu,
                  unreadCount
                }, index) =>
                  !submenu ? (
                    (!permission || type === permission) &&
                    !requireTrainer && (
                      <Tooltip
                        title={isCollapsed ? capitalize(name) : ''}
                        placement="left"
                        color="#3FC9AD"
                        key={index}
                      >
                        <Link
                          to={url}
                          key={url}
                          className={classes(
                            'sidebar__item',
                            (pathname === url ||
                              occur?.some((o) => pathname.includes(o))) &&
                              'sidebar__item_active'
                          )}
                        >
                          {unreadCount ? (
                            <span
                              className="sidebar__item-unreadDot"
                              style={{ left: name === 'chat' ? '37px' : '' }}
                            />
                          ) : null}
                          <div className="sidebar__item-icon__wrapper">
                            <Icon />
                          </div>

                          <span className="sidebar__item-displayName">
                            {capitalize(name)}
                          </span>
                        </Link>
                      </Tooltip>
                    )
                  ) : submenu && isCollapsed ? (
                    <Tooltip
                      title={
                        <NavSubMenu
                          name={name}
                          url={url}
                          key={url}
                          submenu={submenu}
                          Icon={Icon}
                          pathname={pathname}
                          occur={occur}
                          styleType="Trainer"
                          isCollapsed
                        />
                      }
                      placement="left"
                      color="#165D65"
                      align={{
                        // points: ['tl', 'tr'],
                        offset: [15, 29],
                        targetOffset: [30, 30],
                        overflow: { adjustX: true, adjustY: true }
                      }}
                    >
                      <Link
                        to={`/${location.pathname}`}
                        key={url}
                        className={classes(
                          'sidebar__item',
                          (pathname === url ||
                            occur?.some((o) => pathname.includes(o))) &&
                            'sidebar__item_active'
                        )}
                      >
                        {unreadCount ? (
                          <span
                            className="sidebar__item-unreadDot"
                            style={{ left: name === 'chat' ? '37px' : '' }}
                          />
                        ) : null}
                        <div className="sidebar__item-icon__wrapper">
                          <Icon />
                        </div>

                        <span className="sidebar__item-displayName">
                          {capitalize(name)}
                        </span>
                      </Link>
                    </Tooltip>
                  ) : (
                    <NavSubMenu
                      key={url}
                      name={name}
                      url={url}
                      submenu={submenu}
                      Icon={Icon}
                      pathname={pathname}
                      occur={occur}
                      styleType="Trainer"
                    />
                  )
              )}
            </ul>
          </nav>
        </div>
      </TrainerStyles>
      <DesktopFooter
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
    </>
  )
}

function OrganizationDesktopSidebar({
  isCollapsed,
  setIsCollapsed
}: {
  isCollapsed: boolean
  setIsCollapsed: (collapsed: boolean) => void
}) {
  const { pathname } = useLocation()

  const orgMenuItems: MenuItemType[] = [
    { name: 'home', url: Routes.ORG_HOME, Icon: HomeTrainerIcon },
    { name: 'trainers', url: Routes.ORG_TRAINERS, Icon: UsersOrgIcon },
    {
      name: 'clients',
      url: Routes.ORG_CLIENTS,
      Icon: UserOrgIcon
    },
    {
      name: 'financials',
      url: Routes.ORG_FINANCIALS,
      Icon: FinancialsOrgIcon
    },
    {
      name: 'settings',
      url: Routes.ORG_SETTINGS,
      Icon: SettingsOrgIcon
    }
  ]

  return (
    <>
      <OrgStyles isCollapsed={isCollapsed} className={classes('sidebar')}>
        <div>
          <div className="sidebar__logo">
            <BrandLogoIcon />
            <span>Enterprise</span>
          </div>
          <div className="sidebar__divider sidebar__divider_spacing" />
          <nav className="sidebar__nav">
            <ul className="sidebar__menu">
              {orgMenuItems.map(
                ({ url, name, Icon, occur, submenu, unreadCount }, index) =>
                  !submenu ? (
                    <Tooltip
                      title={isCollapsed ? capitalize(name) : ''}
                      placement="left"
                      color="#3FC9AD"
                      key={index}
                    >
                      <Link
                        to={url}
                        key={url}
                        className={classes(
                          'sidebar__item',
                          (pathname === url ||
                            occur?.some((o) => pathname.includes(o))) &&
                            'sidebar__item_active'
                        )}
                      >
                        {unreadCount ? (
                          <span
                            className="sidebar__item-unreadDot"
                            style={{ left: name === 'chat' ? '37px' : '' }}
                          />
                        ) : null}
                        <div className="sidebar__item-icon__wrapper">
                          <Icon />
                        </div>

                        <span className="sidebar__item-displayName">
                          {capitalize(name)}
                        </span>
                      </Link>
                    </Tooltip>
                  ) : submenu && isCollapsed ? (
                    <Tooltip
                      title={
                        <NavSubMenu
                          name={name}
                          url={url}
                          key={url}
                          submenu={submenu}
                          Icon={Icon}
                          pathname={pathname}
                          occur={occur}
                          styleType="Trainer"
                          isCollapsed
                        />
                      }
                      placement="left"
                      color="#165D65"
                      align={{
                        // points: ['tl', 'tr'],
                        offset: [15, 29],
                        targetOffset: [30, 30],
                        overflow: { adjustX: true, adjustY: true }
                      }}
                    >
                      <Link
                        to={`/${location.pathname}`}
                        key={url}
                        className={classes(
                          'sidebar__item',
                          (pathname === url ||
                            occur?.some((o) => pathname.includes(o))) &&
                            'sidebar__item_active'
                        )}
                      >
                        {unreadCount ? (
                          <span
                            className="sidebar__item-unreadDot"
                            style={{ left: name === 'chat' ? '37px' : '' }}
                          />
                        ) : null}
                        <div className="sidebar__item-icon__wrapper">
                          <Icon />
                        </div>

                        <span className="sidebar__item-displayName">
                          {capitalize(name)}
                        </span>
                      </Link>
                    </Tooltip>
                  ) : (
                    <NavSubMenu
                      key={url}
                      name={name}
                      url={url}
                      submenu={submenu}
                      Icon={Icon}
                      pathname={pathname}
                      occur={occur}
                      styleType="Trainer"
                    />
                  )
              )}
            </ul>
          </nav>
        </div>
      </OrgStyles>

      <DesktopFooter
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
    </>
  )
}
