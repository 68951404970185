import { ReactNode } from 'react'

import userTypes from '../../../enums/user-types.enum'
import { useAuth } from '../../../hooks/auth.hook'
import { classes } from '../../../pipes/classes.pipe'
import Ellipsis from '../../ellipsis/ellipsis.component'
import UserBadge from '../../user-badge/user-badge.component'
import Styles from './user-badge-card.styles'

interface UserBadgeCardProps {
  img?: string
  firstName: string
  lastName: string
  userRole: string
  component?: ReactNode
  className?: string
  onClick?: () => void
  online?: boolean
  circle?: boolean
  unreadCount?: number
  userBadgeSize?: 'sm' | 'md' | 'lg'
  hideContent?: boolean
}

export default function UserBadgeCard({
  img,
  firstName = '',
  lastName = '',
  userRole,
  component,
  className,
  onClick,
  online,
  circle,
  unreadCount,
  userBadgeSize,
  hideContent
}: UserBadgeCardProps) {
  const { type } = useAuth()

  return (
    <Styles className={classes('user-badge-card', className)} onClick={onClick}>
      <UserBadge
        avatarOnly
        square={!circle}
        avatar={img}
        firstName={firstName}
        lastName={lastName}
        online={online}
        unreadCount={unreadCount}
        size={userBadgeSize}
      />

      <div
        className="user-badge-card__content"
        style={{ display: hideContent ? 'none' : '' }}
      >
        <p className="user-badge-card__title">{firstName + ' ' + lastName}</p>
        {type === userTypes.ORG ? (
          <p className="user-badge-card__subtitle">{userRole}</p>
        ) : (
          <Ellipsis className="user-badge-card__subtitle">{userRole}</Ellipsis>
        )}
      </div>
      {component}
    </Styles>
  )
}
