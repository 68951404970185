import React, { FC, useMemo } from 'react'

import {
  ExerciseIconV3,
  FoodIconV3,
  MealIconV3,
  WorkoutIconV3_1
} from '../../../../assets/media/icons'
import { Routes } from '../../../../enums/routes.enum'
import userTypes from '../../../../enums/user-types.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useIsMobile } from '../../../../hooks/is-mobile.hook'
import QuickAccessAction from '../../components/quick-access-action/quick-access-action.component'
import QuickAccessSelectedClient from '../../components/quick-access-selected-client/quick-access-selected-client.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import QuickAccessSelectClient from '../quick-access-select-client/quick-access-select-client.component'
import Styles from './quick-access-add.styles'

type Props = {}
const QuickAccessAdd: FC<Props> = ({}) => {
  const { type } = useAuth()
  const isMobile = useIsMobile()
  const { client } = useQuickAccess()

  const options = useMemo(() => {
    const trainerOptions: { [key: string]: any }[] = [
      {
        icon: ExerciseIconV3,
        route: isMobile
          ? quickAccessRoutes.ADD_EXERCISE
          : `${Routes.LOGGING_SINGLE_EXERCISE_ADD}?clientId=${
              client?.id || ''
            }`,
        label: 'exercise',
        color: '#E49A0A'
      },
      {
        icon: WorkoutIconV3_1,
        route: isMobile
          ? quickAccessRoutes.ADD_WORKOUT
          : `${Routes.LOGGING_EXERCISE_ADD}?clientId=${client?.id || ''}`,
        label: 'workout',
        color: '#E49A0A'
      },
      {
        icon: FoodIconV3,
        route: isMobile
          ? quickAccessRoutes.ADD_FOOD
          : `${Routes.LOGGING_FOOD_ADD}?clientId=${client?.id || ''}`,
        label: 'food',
        color: '#13E428'
      },
      {
        icon: MealIconV3,
        route: isMobile
          ? quickAccessRoutes.ADD_MEAL
          : `${Routes.LOGGING_MEAL_ADD}?clientId=${client?.id || ''}`,
        label: 'meal',
        color: '#13E428'
      }
    ]

    const clientOptions: { [key: string]: any }[] = [
      {
        icon: ExerciseIconV3,
        route: isMobile
          ? quickAccessRoutes.ADD_EXERCISE
          : Routes.LOGGING_SINGLE_EXERCISE_ADD,
        label: 'exercise',
        color: '#E49A0A'
      },
      {
        icon: WorkoutIconV3_1,
        route: isMobile
          ? quickAccessRoutes.ADD_WORKOUT
          : Routes.LOGGING_EXERCISE_ADD,
        label: 'workout',
        color: '#E49A0A'
      },
      {
        icon: FoodIconV3,
        route: isMobile ? quickAccessRoutes.ADD_FOOD : Routes.LOGGING_FOOD_ADD,
        label: 'food',
        color: '#13E428'
      },
      {
        icon: MealIconV3,
        route: isMobile ? quickAccessRoutes.ADD_MEAL : Routes.LOGGING_MEAL_ADD,
        label: 'meal',
        color: '#13E428'
      }
    ]
    return type === userTypes.CLIENT ? clientOptions : trainerOptions
  }, [type, client])

  if (type !== userTypes.CLIENT && !client) return <QuickAccessSelectClient />

  return (
    <Styles>
      {type !== userTypes.CLIENT ? <QuickAccessSelectedClient /> : null}
      <div className={'qa-add__actions'}>
        {options.map((option: any) => (
          <QuickAccessAction key={option.route} {...option} />
        ))}
      </div>
    </Styles>
  )
}

export default QuickAccessAdd
