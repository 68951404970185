import { useState } from 'react'

import Styles from './meal.styles'
import MealItem from './meal-item.component'

interface MealsActivitiesProps {
  onSelect: (meal: any) => void
  alreadyUsedDietPlan?: any
  mealData: any[]
}

function MealActivities({
  onSelect,
  mealData,
  alreadyUsedDietPlan
}: MealsActivitiesProps) {
  const [activities, setActivities] = useState<any[]>(
    alreadyUsedDietPlan?.activities ?? []
  )

  return (
    <Styles>
      {activities.length === 0 &&
        mealData.map((meal: any, index: number) => (
          <MealItem
            variant="split"
            onSelect={() => {
              setActivities(meal.activities)
            }}
            meal={meal}
            key={index}
          />
        ))}

      {activities.length > 0 &&
        activities.map((activity: any, index) => (
          <MealItem
            variant="activity"
            onSelect={() => {
              onSelect(activity)
            }}
            meal={activity}
            key={`${index}_activity`}
          />
        ))}
    </Styles>
  )
}

export default MealActivities
