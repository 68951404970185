import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'

import { AddIcon, CheckIcon, EditIcon } from '../../../../assets/media/icons'
import VideoImg from '../../../../assets/media/quick-access-video.png'
import { useAuth } from '../../../../hooks/auth.hook'
import useRestTime from '../../../../hooks/useRestTime.hook'
import useExerciseSupersetLoggedInfo, {
  formatBaseObjectForLogging,
  formatDataPayloadArrayForLogging,
  formatSetsForEachExercise,
  formatSupersetLoggingPayload
} from '../../../../hooks/workout-log-superset-info.hook'
import Formatter from '../../../../managers/formatter.manager'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { getLoggedData } from '../../../../services/api/activities'
import { AddLogToActivityOfTheCalendar } from '../../../../services/api/logging'
import { INCREASE_ACTIVE_WORKOUT_INDEX } from '../../../../store/action-types'
import { RootState } from '../../../../store/reducers'
import { omitEmpty } from '../../../../utils/obj'
import { stringifyURL } from '../../../../utils/query'
import Button from '../../../buttons/button/button.component'
import Input from '../../../form/input/input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessExerciseInput from '../../components/quick-access-exercise-input/quick-access-exercise-input.component'
import QuickAccessModal from '../../components/quick-access-modal/quick-access-modal.component'
import ProggressBar from '../../components/quick-access-progress-bar/quick-access-progress-bar.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles, { AddSetModal } from './quick-access-logging-superset.styles'

const backendExercise = {
  id: 1,
  sets: [
    {
      exercise: 'A',
      exerciseName: 'Pushups',
      kgs: 40,
      reps: 10
    },
    {
      exercise: 'B',
      exerciseName: 'Dumbell Curl',
      kgs: 60,
      reps: 10
    },
    {
      exercise: 'C',
      exerciseName: 'Bench Press',
      kgs: 70,
      reps: 8
    }
  ]
}

function getKey(params: any) {
  return stringifyURL('/logged-info', {
    ...params,
    filter: omitEmpty(params?.filter)
  })
}

const QuickAccessLoggingSuperset: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const auth = useAuth()
  const [sets, setSets] = useState<any>([])
  const { routeParams, setRoute, workoutProgress, setWorkoutProgress } =
    useQuickAccess()
  const { workoutItems, activeWorkoutIndex, activeWorkoutData } = useSelector(
    (state: RootState) => state.logWorkout
  )

  const { exercise }: any = routeParams
  const { log }: any = exercise
  const supersetLogInfo = useExerciseSupersetLoggedInfo(log, exercise)
  const [currentSetIndex, setCurrentSetIndex] = useState(0)
  const [editingSetIndex, setEditingSetIndex] = useState<number | null>(null)
  const [exerciseSets, setExerciseSets] = useState<{ [key: string]: any }[]>(
    backendExercise.sets
  )

  const [currentWeight, setCurrentWeight] = useState(exerciseSets[0].kgs || 0)
  const [currentReps, setCurrentReps] = useState(exerciseSets[0].reps || 0)
  const [totalVolume, setTotalVolume] = useState(0)
  const [showVideo, setShowVideo] = useState(false)
  const [prevSets, setPrevSets] = useState<any>([])
  const [prevVolume, setPrevVolume] = useState<any>(0)
  const [showAddSetModal, setShowAddSetModal] = useState(false)
  const [isResting, setIsResting] = useState(false)
  // timer
  const restTimeHookValue = useRestTime(exerciseSets[currentSetIndex])

  const {
    seconds,
    minutes,
    isRunning,
    restart: restartTimer,
    start: startTimer
  } = useTimer({
    expiryTimestamp: restTimeHookValue,
    autoStart: false,
    onExpire: () => {
      if (isResting) {
        setIsResting(false)
        if (exerciseSets.length - 1 === currentSetIndex) {
          // go to next exercise
          setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO)
        } else {
          // go to next set
          setCurrentSetIndex(currentSetIndex + 1)
          restartTimer(restTimeHookValue, false)
        }
      }
    }
  })
  useEffect(() => {
    restartTimer(restTimeHookValue)
  }, [restTimeHookValue])

  useEffect(() => {
    if (supersetLogInfo.sets.length) {
      setExerciseSets(supersetLogInfo.sets)
      setCurrentSetIndex(supersetLogInfo.activeIndex)
    }
  }, [supersetLogInfo.sets])

  useEffect(() => {}, [supersetLogInfo.activeIndex])

  useEffect(() => {
    calculateTotalVolume()
  }, [currentSetIndex, editingSetIndex, exerciseSets.length])

  async function getValues() {
    const params = {
      filter: {
        account_id: auth.id,
        resource_type: 'workout_items',
        resource_id: exercise?._id
      }
    }
    const response = await getLoggedData(getKey(params))
    setSets(response?.resource?.log)
  }
  useEffect(() => {
    getValues()
  }, [])

  useEffect(() => {
    let total = 0
    if (prevSets?.length) {
      prevSets.forEach((set: any) => {
        total += set.kgs * set.reps
      })
    }
    setPrevVolume(total)
  }, [prevSets])

  const prevValues = useMemo(() => {
    const currentEx = sets?.find(
      (set: any) => set._id === exerciseSets[currentSetIndex].exerciseId
    )
    const kgs: any = currentEx?.info?.sets?.find(
      (set: any) => Number(exerciseSets[currentSetIndex].set) == set.set
    )?.kgs
    const reps: any = currentEx?.info?.sets?.find(
      (sets: any) => Number(exerciseSets[currentSetIndex].set) == sets.set
    )?.reps
    return { kgs, reps }
  }, [sets, exerciseSets?.[currentSetIndex]?.set, currentSetIndex])

  const handleChangeSet = (
    field: string,
    value: number,
    index = currentSetIndex
  ) => {
    const newSets = [...exerciseSets]
    newSets[index][field] = value
    setExerciseSets(newSets)
  }

  const calculateTotalVolume = () => {
    let totalVolume = 0
    exerciseSets.slice(0, currentSetIndex).forEach((set) => {
      totalVolume += set.kgs * set.reps
    })
    setTotalVolume(totalVolume)
  }

  const onCheckIconClick = () => {
    setEditingSetIndex(null)
    postSuperSet(true)
  }

  const postSuperSet = async (single?: boolean) => {
    const res = []
    if (single) {
      for (let index = 0; index < currentSetIndex; index++) {
        res.push(exerciseSets[index])
      }
    } else {
      for (let index = 0; index <= currentSetIndex; index++) {
        res.push(exerciseSets[index])
      }
    }
    const formattedBaseObj = formatBaseObjectForLogging(res)
    const formattedSets = formatSetsForEachExercise(formattedBaseObj, res)
    const formattedPayloadDataArray =
      formatDataPayloadArrayForLogging(formattedSets)
    const formatSupersetLoggingData = formatSupersetLoggingPayload(
      exercise._id,
      formattedPayloadDataArray
    )

    const response = await AddLogToActivityOfTheCalendar(
      routeParams.workoutDate,
      routeParams.workoutId,
      formatSupersetLoggingData
    )

    if (response) {
      toast.show({
        type: 'success',
        msg: 'Superset exercise successfully logged'
      })
    }
  }

  const formatTime = (minutes: number, seconds: number) => {
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`
  }

  const navigateToNextLink = () => {
    if (workoutItems[activeWorkoutIndex + 1].data.length) {
      setRoute(quickAccessRoutes.WORKOUT_LOG_SUPERSET, {
        name: workoutItems[activeWorkoutIndex + 1].workOutId.name,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        exercise: workoutItems[activeWorkoutIndex + 1],
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        logData: workoutItems[activeWorkoutIndex + 1].log,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    } else if (
      workoutItems[activeWorkoutIndex + 1].data.info.type === 'cardio'
    ) {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO, {
        id: activeWorkoutData.id,
        name: workoutItems[activeWorkoutIndex + 1].name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1].log,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        withinWorkout: 'true',
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    } else {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_STRENGTH, {
        id: workoutItems[activeWorkoutIndex + 1].data._id,
        name: workoutItems[activeWorkoutIndex + 1].name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1].data.log,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    }
  }

  const getSets = useCallback(async () => {
    const params = {
      filter: {
        account_id: auth.id,
        resource_type: 'workout_items',
        resource_id: exercise._id
      }
    }
    const response = await getLoggedData(getKey(params))
    setPrevSets(response?.resource?.log)
  }, [])

  useEffect(() => {
    const index = prevSets?.indexOf(
      prevSets.find(
        (ex: any) => ex?._id == exerciseSets[currentSetIndex]?.exerciseId
      )
    )
    let total = 0
    if (typeof index == 'number' && index != 0) {
      prevSets?.[index]?.info?.sets?.forEach((ex: any) => {
        total += ex?.kgs * ex?.reps
      })
      setPrevVolume(total)
    } else {
      setPrevVolume(total)
    }
  }, [prevSets])

  const goToNextSet = () => {
    getSets()
    if (
      exerciseSets.length - 1 === currentSetIndex ||
      exerciseSets.length === currentSetIndex
    ) {
      // go to next exercise
      setWorkoutProgress(
        ((activeWorkoutIndex + 1) / Number(routeParams.lengthOfWorkout)) * 100
      )
      handleChangeSet('kgs', currentWeight)
      handleChangeSet('reps', currentReps)
      setCurrentWeight(exerciseSets[currentSetIndex].kgs || 0)
      setCurrentReps(exerciseSets[currentSetIndex].reps || 0)
      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
      setCurrentSetIndex(0)
      postSuperSet()
      workoutItems.length - 1 === activeWorkoutIndex
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
      return
    }
    setCurrentSetIndex(currentSetIndex + 1)
    handleChangeSet('kgs', currentWeight)
    handleChangeSet('reps', currentReps)

    setCurrentWeight(exerciseSets[currentSetIndex + 1].kgs || 0)
    setCurrentReps(exerciseSets[currentSetIndex + 1].reps || 0)
    setIsResting(true)
    postSuperSet()
    if (isRunning) {
      restartTimer(restTimeHookValue)
    } else {
      startTimer()
    }
  }
  useEffect(() => {
    getSets()
  }, [])

  const skipExercise = () => {
    exerciseSets[currentSetIndex].reps = 0
    exerciseSets[currentSetIndex].kgs = 0
    setExerciseSets([...exerciseSets])
    getSets()
    if (
      exerciseSets.length - 1 === currentSetIndex ||
      exerciseSets.length === currentSetIndex
    ) {
      // go to next exercise
      setWorkoutProgress(
        ((activeWorkoutIndex + 1) / Number(routeParams.lengthOfWorkout)) * 100
      )
      handleChangeSet('kgs', 0)
      handleChangeSet('reps', 0)
      setCurrentWeight(0)
      setCurrentReps(0)
      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
      setCurrentSetIndex(0)
      postSuperSet()
      workoutItems.length - 1 === activeWorkoutIndex
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
      // setRoute(quickAccessRoutes.WORKOUT_OVERVIEW)
      return
    }
    setCurrentSetIndex(currentSetIndex + 1)
    handleChangeSet('kgs', 0)
    handleChangeSet('reps', 0)

    setCurrentWeight(exerciseSets[currentSetIndex + 1].kgs || 0)
    setCurrentReps(exerciseSets[currentSetIndex + 1].reps || 0)
    setIsResting(true)
    postSuperSet()
    if (isRunning) {
      restartTimer(restTimeHookValue)
    } else {
      startTimer()
    }
  }

  const skipRest = () => setIsResting(false)

  const addSet = (set: any, key: number) => {
    let index = 0
    for (let i = exerciseSets.length - 1; i >= 0; i--) {
      if (exerciseSets[i].exerciseId === set._id) {
        index = i
        break
      }
    }
    const newExerciseSets = [
      ...exerciseSets,
      {
        exercise: `${(10 + key).toString(36).toUpperCase()}${
          +exerciseSets[index].set + 1
        }`,
        exerciseId: exerciseSets[index].exerciseId,
        exerciseName: exerciseSets[index].exercise,
        kgs: 10,
        link: exerciseSets[index].link,
        reps: 10,
        rest_interval: exerciseSets[index].rest_interval,
        set: (+exerciseSets[index].set + 1).toString(),
        tempo: exerciseSets[index].tempo
      }
    ]
    setExerciseSets(newExerciseSets)
    setShowAddSetModal(false)
  }

  return (
    <Styles>
      <div className="qa-logging-superset__container">
        <h3>{routeParams.name}</h3>
        <ProggressBar
          percent={workoutProgress}
          trailColor="#EDEDED"
          className="qa-logging-superset__progressbar"
        />

        <div className="qa-logging-superset__body">
          <div className="qa-logging-superset__table-container">
            <table id="sets-table">
              <thead>
                <tr>
                  <th>{t('quickaccess:logging-strength.exercise')}</th>
                  <th>{t('quickaccess:logging-strength.set')}</th>
                  <th>{t('quickaccess:logging-strength.weight')}</th>
                  <th>{t('quickaccess:logging-strength.reps')}</th>
                  <th>{t('quickaccess:logging-strength.volume')}</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {exerciseSets.map((set, index) => (
                  <tr key={index}>
                    {index === currentSetIndex ? (
                      <>
                        <td>{set.exercise}</td>
                        <td className="current-set">
                          <span>
                            {t('quickaccess:logging-strength.logging')}
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                      </>
                    ) : (
                      <>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {set.exercise}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {set.set}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index === editingSetIndex ? (
                            <Input
                              id="edit-set-weight"
                              format={Formatter().number().min(1).max(999)}
                              value={exerciseSets[editingSetIndex].kgs}
                              onChange={(e) =>
                                handleChangeSet(
                                  'kgs',
                                  +e.target.value,
                                  editingSetIndex
                                )
                              }
                            />
                          ) : index > currentSetIndex ? (
                            '-'
                          ) : (
                            `${set.kgs.toString().padStart(2, '0')} kg`
                          )}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index === editingSetIndex ? (
                            <Input
                              id="edit-set-reps"
                              format={Formatter().number().min(1).max(999)}
                              value={exerciseSets[editingSetIndex].reps}
                              onChange={(e) =>
                                handleChangeSet(
                                  'reps',
                                  +e.target.value,
                                  editingSetIndex
                                )
                              }
                            />
                          ) : index > currentSetIndex ? (
                            '-'
                          ) : (
                            `${set.reps.toString().padStart(2, '0')}`
                          )}
                        </td>
                        <td
                          className={`${
                            (index > currentSetIndex && 'upcoming-set') || ''
                          }`}
                        >
                          {index > currentSetIndex ? '-' : set.kgs * set.reps}
                        </td>
                        <td>
                          {index === editingSetIndex ? (
                            <button>
                              <CheckIcon
                                style={{ color: 'white' }}
                                onClick={onCheckIconClick}
                              />
                            </button>
                          ) : (
                            index < currentSetIndex && (
                              <EditIcon
                                onClick={() => setEditingSetIndex(index)}
                              />
                            )
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            className="qa-logging-superset__add-set-button"
            onClick={() => setShowAddSetModal(true)}
          >
            <AddIcon />{' '}
            <span>{t('quickaccess:logging-strength.add-set-btn')}</span>
          </button>
        </div>

        <div className="qa-logging-superset__footer">
          <div className="qa-logging-superset__current-exercise">
            <div>
              <h2>{exerciseSets[currentSetIndex].exerciseName}</h2>
              {exerciseSets[currentSetIndex].link != null && (
                <span
                  className="qa-logging-superset__video-link"
                  onClick={() => setShowVideo(true)}
                >
                  {t('quickaccess:logging-strength.video-link')}
                </span>
              )}
            </div>
            <div className="qa-logging-superset__exercise-info">
              <div>
                <span>{t('quickaccess:logging-strength.rest')}</span>
                <p>{exerciseSets[currentSetIndex].rest_interval}</p>
              </div>
              <div>
                <span>{t('quickaccess:logging-strength.tempo')}</span>
                <p>{exerciseSets[currentSetIndex].tempo}</p>
              </div>
            </div>
          </div>
          <div className="qa-logging-superset__volume">
            {prevVolume != 0 && (
              <div>
                {t('quickaccess:logging-strength.previous-volume')}{' '}
                <span>{prevVolume}</span>
              </div>
            )}
            <div>
              {t('quickaccess:logging-strength.total-volume')}{' '}
              <span>{totalVolume}</span>
            </div>
          </div>

          <div
            className="qa-logging-superset__input-group"
            style={{ display: isResting ? 'block' : '' }}
          >
            {isResting ? (
              <QuickAccessExerciseInput
                id="log-exercise-rest-timer"
                label="Rest Time"
                disabled
                value={formatTime(minutes, seconds)}
                fullwidth
              />
            ) : (
              <>
                <QuickAccessExerciseInput
                  id="log-exercise-current-set"
                  label="Set"
                  disabled
                  value={Number(exerciseSets[currentSetIndex].set)
                    .toString()
                    .padStart(2, '0')}
                />
                <QuickAccessExerciseInput
                  id="log-exercise-current-weight"
                  label="Kgs"
                  value={currentWeight}
                  previousValue={prevValues.kgs}
                  format={Formatter().number().min(1).max(999)}
                  onChange={(e) => setCurrentWeight(+e.target.value)}
                />
                <QuickAccessExerciseInput
                  id="log-exercise-current-reps"
                  label="Reps"
                  value={currentReps}
                  previousValue={prevValues.reps}
                  format={Formatter().number().min(1).max(999)}
                  onChange={(e) => setCurrentReps(+e.target.value)}
                />
              </>
            )}
          </div>

          {isResting ? (
            <Button onClick={skipRest} style={{ width: '100%' }}>
              {t('quickaccess:logging-strength.skip-set-btn')}
            </Button>
          ) : (
            <div className="qa-logging-superset__button-group">
              <Button
                className="qa-logging-superset__button-skip"
                variant="dark"
                onClick={skipExercise}
              >
                {t('quickaccess:logging-strength.skip-exercise-btn')}
              </Button>
              <Button onClick={goToNextSet}>
                {workoutItems.length - 1 === activeWorkoutIndex &&
                exerciseSets.length - 1 == currentSetIndex
                  ? `Finish Workout`
                  : `Next ${
                      exerciseSets.length - 1 === currentSetIndex
                        ? 'Exercise'
                        : 'Set'
                    }`}
              </Button>
            </div>
          )}
        </div>

        <QuickAccessModal
          visible={showVideo}
          onCancel={() => setShowVideo(false)}
        >
          <img
            src={VideoImg}
            alt="workout-video"
            style={{ marginTop: '35px', width: '100%' }}
          />
        </QuickAccessModal>
        <AddSetModal
          visible={showAddSetModal}
          onCancel={() => setShowAddSetModal(false)}
          footer={null}
        >
          <h1>{t('quickaccess:logging-strength.add-set')}</h1>
          <h2>{t('quickaccess:logging-strength.select-exercise')}</h2>

          <div>
            {workoutItems?.[activeWorkoutIndex]?.data?.map(
              (set: any, index: number) => (
                <button
                  key={index}
                  onClick={() => {
                    addSet(set, index)
                  }}
                >
                  {`${(10 + index).toString(36).toUpperCase()} - ${set.name}`}
                </button>
              )
            )}
          </div>
        </AddSetModal>
      </div>
    </Styles>
  )
}

export default QuickAccessLoggingSuperset
