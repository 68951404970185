import moment from 'moment'

import { Activity } from '../types/activity.type'

export function modifyActivityTime(activity: Activity, start: Date) {
  const updatedEvent = {
    ...activity,
    start: start,
    end: start
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const finalData = {
    ...updatedEvent,
    resource_type: updatedEvent.resource_type,
    resource:
      updatedEvent.resource_type === 'meals'
        ? {
            _id: updatedEvent.resource?._id,
            name: updatedEvent.title,
            time: moment(updatedEvent.start).format('HH:mm'),
            items: updatedEvent.resource?.items,
            supplements: updatedEvent.resource?.supplements || []
          }
        : updatedEvent.resource_type === 'workout_items'
        ? {
            ...updatedEvent.resource,
            name: updatedEvent.title,
            time: moment(updatedEvent.start).format('HH:mm'),
            _id: updatedEvent.resource?._id
          }
        : {
            name: updatedEvent.title,
            time: moment(updatedEvent.start).format('HH:mm'),
            items: updatedEvent.resource?.items,
            _id: updatedEvent.resource?._id
          }
  }

  return {
    ...activity,
    start: start,
    end: start,
    ...(updatedEvent.resource_type == "sessions" && {date: moment(start).format('YYYY-MM-DD')}),
    time: moment(start).format('HH:mm:ss'),
    resource: {
      ...activity.resource,
      time: moment(updatedEvent.start).format('HH:mm')
    }
  } as Activity
}

export function calculateTotalWorkoutTime(activity: any) {
  let newTotalWorkoutTime = 0
  let totalWorkoutTimeString = ''

  activity?.items?.forEach((item: any) => {
    const workouts: any[] = item.is_superset ? item.data : [item]

    workouts.forEach((workout: any) => {
      const workoutSets =
        workout.data?.info?.sets ||
        workout.info?.sets ||
        workout.data?.[0]?.info?.sets
      const workoutReps =
        workout.data?.info?.reps ||
        workout.info?.reps ||
        workout.data?.[0]?.info?.reps
      const workoutTempo =
        workout.data?.info?.tempo ||
        workout.info?.tempo ||
        workout.data?.[0]?.info?.tempo
      const workoutRest =
        workout.data?.info?.rest_interval ||
        workout.info?.rest_interval ||
        workout.data?.[0]?.info?.rest_interval

      if (workoutSets && workoutReps && workoutTempo && workoutRest) {
        //Total exercise time= (reps x (tempo[sum all digits, X counts as 1]) + rest) x set

        //convert rest interval to seconds
        const restArr = workoutRest.toString().split(':')
        const restInSeconds = +restArr[0] * 60 + +restArr[1]

        //sum all tempo digits
        const tempoArr: string[] = workoutTempo.toString().split('')
        let sumTempo = 0

        tempoArr.forEach((element) => {
          if (!isNaN(+element)) {
            sumTempo += +element
          } else if (element.toLowerCase() === 'x') {
            sumTempo += 1
          }
        })

        //Get reps max value
        let repsMaxValue = 0
        const repsValue: string = workoutReps.toString()

        if (!isNaN(+repsValue)) {
          repsMaxValue = +repsValue
        } else {
          ;['-', ',', '/'].forEach((charact) => {
            if (repsValue.includes(charact)) {
              const repsValueArr = repsValue.split(charact)
              repsValueArr.forEach((val) => {
                if (!isNaN(+val) && +val > repsMaxValue) {
                  repsMaxValue = +val
                }
              })
            }
          })
        }

        const totalExerciseTime =
          (repsMaxValue * sumTempo + restInSeconds) * +workoutSets
        newTotalWorkoutTime += totalExerciseTime
      }
    })
  })

  if (!isNaN(newTotalWorkoutTime)) {
    const workoutHours = Math.floor(newTotalWorkoutTime / 3600)
    const workoutMins = Math.floor((newTotalWorkoutTime % 3600) / 60)
    const workoutHoursString =
      (workoutHours + '').length === 1 ? '0' + workoutHours : workoutHours + ''
    const workoutMinsString =
      (workoutMins + '').length === 1 ? '0' + workoutMins : workoutMins + ''
    totalWorkoutTimeString = `${workoutHoursString}:${workoutMinsString}`
  }

  return totalWorkoutTimeString
}
