import styled, { css } from 'styled-components'

import { getColorCarry } from '../../../../pipes/theme-color.pipe'

export default styled.div<any>`
  padding: 0.75rem 0 0.75rem 0;
  background-color: ${getColorCarry('neutral_10')};
  border-radius: 15px;
  margin: 10px 0;
  .label-no-wrap{
    > label {
    white-space: nowrap
    }
}
    ${(props) =>
      props.$isDragging &&
      css`
        border: 1px dashed ${getColorCarry('orange_60')};
      `};

  .food {
    &__table {
      border-collapse: separate;
      border-spacing: 15px 0;
      margin-bottom: 20px;
    }
    &__d-flex {
      display: flex;
    }
    &__vertical-bottom {
      vertical-align: bottom;
    }
    &__danger {
      color: ${getColorCarry('red')};
    }
  }
  .Food {
    &__prefix {
      margin-top: 0;
      position: absolute;
      top: -1.5rem;
    }
    &__drag {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-btn {
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        color: ${getColorCarry('secondary2_v2')};
      }
    }
`
