import { Tooltip as AntdTooltip, TooltipProps } from 'antd'
import { CSSProperties } from 'react'

import { classes } from '../../pipes/classes.pipe'

const overlayInnerStyleCustom: CSSProperties = {
  padding: '0.75rem',
  borderRadius: 8,
  fontSize: '0.625rem'
}

export default function Tooltip({
  overlayClassName,
  overlayInnerStyle,
  ...props
}: TooltipProps) {
  return (
    <AntdTooltip
      overlayClassName={classes('tooltip', overlayClassName)}
      overlayInnerStyle={{
        ...overlayInnerStyleCustom,
        ...overlayInnerStyle
      }}
      {...props}
    />
  )
}
