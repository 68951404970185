import styled from 'styled-components'

import { getColorCarry } from '../../../pipes/theme-color.pipe'

export default styled.div`
  .meals-page {
    &__footer {
      margin-top: 20px;
      display: flex;
      justify-content: end;
    }
    &__action-btn {
      font-size: 0.75rem;

      & svg {
        margin-right: 0.25rem;
        width: 14px;
        height: 14px;
      }
    }
    &__link {
      color: ${getColorCarry('orange_90')};
      margin-right: 20px;
    }
    &__summary {
      background-color: ${getColorCarry('neutral_10')};
      border-radius: 10px;
      padding: 0.938rem 1.25rem;
    }
    &__bg-neutral_10 {
      background-color: ${getColorCarry('neutral_10')};
      padding-top: 30px;
    }
    
    &__other-meals {
      margin-top: 1.8rem;
    }
    &__other-meals-title {
      font-size: 28px;
      font-weight: bold;
    }
    &__other-meals-day-title {
      font-size: 18px;
      font-weight: bold;
    }
  }
`
