export const formatSingleWorkout = (data: any) => {
  return {
    resource_type: 'workouts',
    resource: {
      name: data.name,
      time: data.time,
      total_workout_time: data.total_workout_time,
      items: [
        {
          is_superset: false,
          save_as_template: false,
          data: {
            name: data.name,
            time: data.time,
            link: data.link,
            info: data.info
          }
        }
      ]
    }
  }
}

export const formatSingleCardio = (data: any) => {
  return {
    resource_type: 'workout_items',
    resource: {
      is_superset: false,
      time: data.time,
      data: {
        name: data.name,
        info: data.info,
        link: data.link
      }
    }
  }
}

export const formatSingleWorkoutSuperset = (data: any) => {
  const items = data.data.map((item: object) => {
    return item
  })

  return {
    resource_type: 'workouts',
    resource: {
      name: data.name,
      time: data.time,
      total_workout_time: data.total_workout_time,
      items: [
        {
          is_superset: true,
          save_as_template: false,
          data: items
        }
      ]
    }
  }
}

export const formatWorkout = (data: any) => {
  const items = data.items.map((elem: any) => {
    const fakeItem = { ...elem }
    delete fakeItem.sort_order
    if (!fakeItem.is_superset) {
      fakeItem.data = Array.isArray(fakeItem.data)
        ? fakeItem.data[0]
        : fakeItem.data
      delete fakeItem.data.sort_order
    } else {
      fakeItem.data.forEach((e: any) => {
        delete e.sort_order
      })
    }
    return fakeItem
  })

  return {
    resource_type: 'workouts',
    resource: {
      name: data.name,
      time: data.time,
      total_workout_time: data.total_workout_time,
      items: items
    }
  }
}

export const formatWorkoutOtherExercise = (data: any) => {
  const items = data.items.map((elem: any) => {
    const fakeItem = { ...elem }
    delete fakeItem.sort_order
    if (!fakeItem.is_superset) {
      fakeItem.data = fakeItem.data[0]
      delete fakeItem.data.sort_order
    } else {
      fakeItem.data.forEach((e: any) => {
        delete e.sort_order
      })
    }
    return fakeItem
  })

  return {
    resource_type: 'workout_items',
    resource: {
      ...items[0],
      name: data.name,
      time: data.time
    }
  }
}

export const formatMeal = (data: any) => {
  return {
    resource_type: 'meals',
    resource: {
      name: data.name,
      time: data.time,
      items: data.items,
      supplements: data.supplements || []
    }
  }
}

export const formatFood = (data: any) => {
  return {
    resource_type: 'meals',
    resource: {
      name: data.name,
      time: data.time,
      items: [
        {
          data: {
            name: data.name,
            info: data.info
          }
        }
      ]
    }
  }
}

export const formatLogCardioPayload = (
  execId: string,
  duration: string,
  intensity: string,
  avg_heart_rate: string | number,
  wasSkipped?: boolean,
  logAll?: boolean
) => {
  return {
    items: [
      {
        _id: execId, // <———— specify the ID of the item in the items array of the Workout
        data: {
          was_skipped: wasSkipped,
          log_all: logAll,
          info: {
            avg_heart_rate,
            duration,
            intensity
          }
        }
      }
    ]
  }
}

export const formatCardioWithoutWorkoutPayload = (
  duration: string,
  intensity: string,
  avg_heart_rate: string | number,
  wasSkipped?: boolean,
  logAll?: boolean
) => {
  return {
    data: {
      was_skipped: wasSkipped,
      log_all: logAll,
      info: {
        avg_heart_rate,
        duration,
        intensity
      }
    }
  }
}

export const formatLogMarkCompletedPayload = () => {
  return {
    log_all: true
  }
}

export const formatLogSingleExerciseMarkCompletedPayload = (execId: string) => {
  return {
    items: [
      {
        _id: execId, // <———— specify the ID of the item in the items array of the Workout
        data: {
          log_all: true
        }
      }
    ]
  }
}

export const formatLogSingleCardioMarkCompletedPayload = (execId: string) => {
  return {
    _id: execId, // <———— specify the ID of the item in the items array of the Workout
    data: {
      log_all: true
    }
  }
}
