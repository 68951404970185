import { getTemplatesData } from '../../services/api/templates'
import { getTemplateUrlParamsType } from '../../types/get-template-url-params.type'
import { getKey } from '../query'

export const getTemplates = async (
  url: string,
  params: getTemplateUrlParamsType
) => {
  const { data } = await getTemplatesData(getKey(params, url))
  return data
}
