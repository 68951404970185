import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'

import { AddIcon, DeleteOutlinedIcon } from '../../../../assets/media/icons'
import { ReactComponent as DownArrowIcon } from '../../../../assets/media/icons/down-arrow.svg'
import userTypes from '../../../../enums/user-types.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { AddLoggingResource } from '../../../../services/api/logging'
import { formatMeal } from '../../../../utils/api/logging/add'
import { getTemplates } from '../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../utils/arrays'
import Button from '../../../buttons/button/button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import DatePicker from '../../../form/date-picker/date-picker.component'
import Input from '../../../form/input/input.component'
import Label from '../../../form/label/label.component'
import TimeInput from '../../../form/TimeInput/time-input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import Macronutrient from '../../components/quick-access-macronutrient/quick-access-macronutrient.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-add-meal.styles'
import { mealValidationSchema } from './validation/mealValidationSchema'

const QuickAccessAddMealComponent: FC = () => {
  const { t } = useTranslation()
  const [accordionKey, setAccordionKey] = useState(null)
  const [accordionKeySup, setAccordionKeySup] = useState(null)
  const [totalMacros, setTotalMacros] = useState({
    proteins: 0,
    fat: 0,
    net_carbs: 0,
    sugar: 0,
    fiber: 0,
    total_carbs: 0,
    calories: 0
  })
  const [food, setFood] = useState('')
  const [supplement, setSupplement] = useState('')
  const [foods, setFoods] = useState<any>([])
  const [supplementTemplate, setSupplementTemplate] = useState([])
  const getTwoDecimal = (value: any) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  const { type } = useAuth()
  const { client, setOpen } = useQuickAccess()

  const formik: any = useFormik({
    initialValues: {
      name: '',
      time: '',
      date: '',
      saveMeal: false,
      items: [],
      supplements: []
    },
    validationSchema: mealValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      values.date = moment(values.date).format('YYYY-MM-DD')
      const finalData = formatMeal(values)
      const response = await AddLoggingResource(
        finalData,
        values.date,
        type === userTypes.TRAINER && client?.id ? client.id.toString() : null
      )

      if (response) {
        toast.show({ type: 'success', msg: 'Meal successfully created' })
        setOpen(false)
      }
    }
  })

  const calculateMacros = () => {
    const macros: any = {
      proteins: 0,
      fat: 0,
      net_carbs: 0,
      sugar: 0,
      fiber: 0,
      total_carbs: 0,
      calories: 0
    }

    formik.values.items.forEach((item: any) => {
      item.data.info.calories = getTwoDecimal(
        (item.data.info?.proteins || 0) * 4 +
          (item.data.info?.net_carbs || 0) * 4 +
          (item.data.info?.fat || 0) * 9
      )
      item.data.info.total_carbs = getTwoDecimal(
        (+item.data.info?.net_carbs || 0) + (+item.data.info?.fiber || 0)
      )

      Object.keys(item.data.info).forEach((elem) => {
        macros[elem] += +item.data.info[elem]
      })
    })

    setTotalMacros(macros)
  }

  const nutrients: { [key: string]: string } = {
    Proteins: 'proteins',
    Fat: 'fat',
    'Net Carbs': 'net_carbs',
    Sugar: 'sugar',
    Fiber: 'fiber',
    'Total Carbs': 'total_carbs',
    Calories: 'calories'
  }

  const instanceOfSupplement = {
    public: false,
    name: '',
    info: {
      grams: '',
      description: ''
    }
  }

  const instanceOfFoods = {
    data: {
      name: '',
      info: {
        grams: '',
        proteins: '',
        fat: '',
        net_carbs: '',
        sugar: '',
        fiber: '',
        total_carbs: '',
        calories: ''
      }
    }
  }

  const useTemplateFoods = async (name: string, clientId?: string) => {
    const data = await getTemplates('/foods', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setFoods(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = foods
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(formik.values.name?.toLowerCase()) &&
          w?.name !== food
      )
      ?.map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions?.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [foods, formik.values.name])

  const onFoodSelected = (value: string, key: number) => {
    const foodTemplate = foods.find((m: any) => m._id === value)
    if (foodTemplate) {
      formik.setFieldValue(`items.${key}.data.name`, foodTemplate.name)
      formik.setFieldValue(
        `items.${key}.data.info.grams`,
        foodTemplate.info.grams
      )
      formik.setFieldValue(`items.${key}.data.info.fat`, +foodTemplate.info.fat)
      formik.setFieldValue(
        `items.${key}.data.info.fiber`,
        +foodTemplate.info.fiber
      )
      formik.setFieldValue(
        `items.${key}.data.info.net_carbs`,
        +foodTemplate.info.net_carbs
      )
      formik.setFieldValue(
        `items.${key}.data.info.proteins`,
        +foodTemplate.info.proteins
      )
      formik.setFieldValue(
        `items.${key}.data.info.sugar`,
        +foodTemplate.info.sugar
      )
      formik.setFieldValue(
        `items.${key}.data.info.total_carbs`,
        +foodTemplate.info.total_carbs
      )
      formik.setFieldValue(
        `items.${key}.data.info.calories`,
        +foodTemplate.info.calories
      )
      calculateMacros()
    }
  }

  const useTemplateSupplement = async (name: string, clientId?: string) => {
    const data = await getTemplates('/supplements', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setSupplementTemplate(data)
  }

  const nameOptionsSup = useMemo(() => {
    const templateOptions = supplementTemplate
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(supplement?.toLowerCase()) &&
          w?.name !== supplement
      )
      ?.map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions?.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }

    return options.length ? options : []
  }, [supplement, supplementTemplate])
  const onSupplementSelected = (value: string, key: any) => {
    // find in templates
    const supplement: any = supplementTemplate.find((m: any) => m._id === value)

    if (supplement) {
      formik.setFieldValue(`supplements.${key}.name`, supplement.name)
      formik.setFieldValue(
        `supplements.${key}.info.description`,
        supplement.info.description
      )
      formik.setFieldValue(
        `supplements.${key}.info.grams`,
        supplement.info.grams
      )
    }
  }

  return (
    <Styles>
      <QuickAccessBack label={'add'} route={quickAccessRoutes.ADD} />
      <h3>{t('quickaccess:add-meal.add_meal')}</h3>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Input
            className="qa-add-meal__form-group"
            id="qa-add-meal-name"
            label={t('quickaccess:add-meal.name_of_meal')}
            name="name"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
          <TimeInput
            id="qa-add-meal-time"
            label={t('quickaccess:add-meal.schedule')}
            placeholder="hh:mm"
            name="time"
            value={formik.values.time}
            className="qa-add-meal__form-group"
            format="hh:mm"
            onChange={(e) => {
              formik.setFieldValue('time', e.target.value)
            }}
          />
          <DatePicker
            id="qa-add-meal-schedule"
            format={'YYYY-MM-DD'}
            name="date"
            label={`Date`}
            value={formik.values.date}
            onChange={(value) => formik.setFieldValue('date', value)}
            placeholder={t('to')}
            className="qa-add-meal__form-group"
          />

          <div className="qa-add-meal__form-group qa-add-meal__mb-20">
            <Label className="qa-add-meal__negative-bottom">
              {t('quickaccess:add-meal.micronutrients')}
            </Label>
            <div className="qa-add-meal__meal-overview">
              {Object.keys(nutrients).map((k) => (
                <div key={k}>
                  <Macronutrient
                    key={k}
                    title={k}
                    amount={`${getTwoDecimal(
                      (totalMacros as any)[nutrients[k]]
                    )}
                ${k === 'Calories' ? 'KCal' : 'g'}`}
                  />
                </div>
              ))}
            </div>
          </div>

          <FieldArray
            name="items"
            render={(arrayHelpers) => (
              <div>
                {formik.values.items.map((superset: any, key: any) => (
                  <div key={key} className={`qa-add-meal__box`}>
                    <div
                      className={`qa-add-meal__header ${
                        accordionKey == key ? 'qa-add-meal__mb-20' : false
                      }`}
                    >
                      <h3 className={`qa-add-meal__flex`}>
                        {t('quickaccess:add-meal.food')} {key + 1}
                        <DeleteOutlinedIcon
                          onClick={() => arrayHelpers.remove(key)}
                          className={
                            'food__danger qa-add-meal__ml-10 qa-add-meal__red'
                          }
                        />
                      </h3>

                      <DownArrowIcon
                        onClick={() =>
                          setAccordionKey(accordionKey == key ? null : key)
                        }
                        className={`${
                          accordionKey == key ? `accordion__icon_open` : false
                        }`}
                      />
                    </div>

                    <div
                      className={
                        accordionKey == key
                          ? 'qa-add-meal__open'
                          : 'qa-add-meal__close'
                      }
                    >
                      <Label>{t('quickaccess:add-meal.food_name')}</Label>
                      <AutoCompleteInput
                        id="qa-add-food-name"
                        name={`items.${key}.data.name`}
                        placeholder={t('quickaccess:add-food.placeholder-food')}
                        value={formik.values.items[key].data.name}
                        options={nameOptions}
                        onSelect={(value) => onFoodSelected(value, key)}
                        onChange={(value) => {
                          setFood(value)
                          formik.setFieldValue(`items.${key}.data.name`, value)
                          if (value.length >= 3) useTemplateFoods(value)
                        }}
                      />

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div>
                          <Label>{t('quickaccess:add-meal.qty')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.grams`}
                            placeholder="-"
                            type="number"
                          />
                        </div>

                        <div className="qa-add-meal__ml-10">
                          <Label>{t('quickaccess:add-meal.proteins')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.proteins`}
                            onKeyUp={calculateMacros}
                            placeholder="-"
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div>
                          <Label>{t('quickaccess:add-meal.fat')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.fat`}
                            placeholder="-"
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>

                        <div className="qa-add-meal__ml-10">
                          <Label>{t('quickaccess:add-meal.net_carbs')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.net_carbs`}
                            placeholder="-"
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div>
                          <Label>{t('quickaccess:add-meal.sugar')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.sugar`}
                            placeholder="-"
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>

                        <div className="qa-add-meal__ml-10">
                          <Label>{t('quickaccess:add-meal.fiber')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.fiber`}
                            placeholder="-"
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>
                      </div>

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div>
                          <Label>{t('quickaccess:add-meal.total_carbs')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.total_carbs`}
                            placeholder="-"
                            disabled
                            readOnly
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>

                        <div className="qa-add-meal__ml-10">
                          <Label>{t('quickaccess:add-meal.calories')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`items.${key}.data.info.calories`}
                            placeholder="-"
                            disabled
                            readOnly
                            onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="qa-add-meal__form-group">
                  <button
                    type="button"
                    className="qa-add-meal__add-another-button"
                    onClick={() => {
                      arrayHelpers.push(instanceOfFoods)
                    }}
                  >
                    <AddIcon />{' '}
                    <span>{t('quickaccess:add-meal.add_another_food')}</span>
                  </button>
                </div>
              </div>
            )}
          />

          <FieldArray
            name="supplements"
            render={(arrayHelpers) => (
              <div className="qa-add-meal__mt-20">
                {formik.values.supplements.map((supplement: any, key: any) => (
                  <div key={key} className={`qa-add-meal__box`}>
                    <div
                      className={`qa-add-meal__header ${
                        accordionKey == key ? 'qa-add-meal__mb-20' : false
                      }`}
                    >
                      <h3 className={`qa-add-meal__flex`}>
                        {t('quickaccess:add-meal.supplement')} {key + 1}
                        <DeleteOutlinedIcon
                          onClick={() => arrayHelpers.remove(key)}
                          className={
                            'food__danger qa-add-meal__ml-10 qa-add-meal__red'
                          }
                        />
                      </h3>

                      <DownArrowIcon
                        onClick={() =>
                          setAccordionKeySup(
                            accordionKeySup == key ? null : key
                          )
                        }
                        className={`${
                          accordionKeySup == key
                            ? `accordion__icon_open`
                            : false
                        }`}
                      />
                    </div>

                    <div
                      className={
                        accordionKeySup == key
                          ? 'qa-add-meal__open'
                          : 'qa-add-meal__close'
                      }
                    >
                      <Label className="qa-add-meal__mt-10">
                        {t('quickaccess:add-meal.supplement_name')}
                      </Label>
                      <AutoCompleteInput
                        id="qa-add-supplement-name"
                        name={`supplements.${key}.name`}
                        placeholder={t('quickaccess:add-meal.supplement')}
                        value={formik.values.supplements[key].name}
                        options={nameOptionsSup}
                        onSelect={(value) => onSupplementSelected(value, key)}
                        onChange={(value) => {
                          setSupplement(value)
                          formik.setFieldValue(`supplements.${key}.name`, value)
                          if (value.length >= 3) useTemplateSupplement(value)
                        }}
                      />

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div className="qa-add-meal__w-100">
                          <Label>{t('quickaccess:add-meal.description')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`supplements.${key}.info.description`}
                            placeholder="-"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="qa-add-meal__form-group qa-add-meal__flex-form-group">
                        <div className="qa-add-meal__w-100">
                          <Label>{t('quickaccess:add-meal.qty')}</Label>
                          <Field
                            className="qa-add-meal__formik-input ant-input input__input"
                            name={`supplements.${key}.info.grams`}
                            placeholder="-"
                            // onKeyUp={calculateMacros}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="qa-add-meal__form-group">
                  <button
                    type="button"
                    className="qa-add-meal__add-another-button"
                    onClick={() => {
                      arrayHelpers.push(instanceOfSupplement)
                    }}
                  >
                    <AddIcon />{' '}
                    <span>
                      {t('quickaccess:add-meal.add_another_supplement')}
                    </span>
                  </button>
                </div>
              </div>
            )}
          />

          <div className="qa-add-meal__form-group qa-add-meal__pb-20">
            <Button htmlType={'submit'} className="qa-add-meal__w-100">
              {t('quickaccess:add-meal.add_meal')}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Styles>
  )
}
export default QuickAccessAddMealComponent
