import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  HealthDataIconV3,
  MealIconV3,
  MeasurementIconV3,
  WorkoutIconV3_1
} from '../../../../assets/media/icons'
import { EP_CALENDAR } from '../../../../enums/api.enum'
import { Routes } from '../../../../enums/routes.enum'
import userTypes from '../../../../enums/user-types.enum'
import useTrainingSplit from '../../../../hooks/api/activities/useTrainingSplit'
import { useAuth } from '../../../../hooks/auth.hook'
import { useIsMobile } from '../../../../hooks/is-mobile.hook'
import api from '../../../../managers/api.manager'
import {
  AssignDietPlanActivities,
  AssignDietPlanDay,
  EditLoggingResource
} from '../../../../services/api/logging'
import {
  useDietPlanCurrentRevision,
  useTrainingPlanCurrentRevision
} from '../../../../store/query'
import { DATE_FORMAT } from '../../../../utils/date'
import { getRoute } from '../../../../utils/routes'
import QuickAccessAction from '../../components/quick-access-action/quick-access-action.component'
import { useOpenContext } from '../../components/quick-access-popup/openContext'
import QuickAccessSelectedClient from '../../components/quick-access-selected-client/quick-access-selected-client.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import QuickAccessSelectClient from '../quick-access-select-client/quick-access-select-client.component'
import MealActivities from './meal/meal.component'
import Styles from './quick-access-log.styles'
import WorkoutsActivities from './workout/workout.component'

type Props = {}
const QuickAccessLog: FC<Props> = ({}) => {
  const { setOpen }: any = useOpenContext()
  const auth = useAuth()
  const [todayDate] = useState(moment(new Date()).format(DATE_FORMAT))
  const { type } = useAuth()
  const { client } = useQuickAccess()
  const { setRoute } = useQuickAccess()
  const isMobile = useIsMobile()
  const history = useHistory()
  const { open }: any = useOpenContext()
  const [openWorkout, setOpenWorkout] = useState(false)
  const [openMeal, setOpenMeal] = useState(false)
  const [workoutData, setWorkoutData] = useState<any>([])
  const [mealData, setMealData] = useState<any>([])
  const [alreadyUsedDietPlan, setAlreadyUsedDietPlan] = useState<any>()

  const changeLogRoute = (route: any, apply?: boolean) => {
    if (isMobile) {
      setRoute(route)
    } else {
      if (history.location.pathname !== `${route}`) {
        history.push(`${route}`)
      } else {
        history.push(
          `${route}`,
          apply
            ? {
                changedCount: true
              }
            : null
        )
      }
    }
  }
  useEffect(() => {
    if (!open) {
      setOpenMeal(false)
      setOpenWorkout(false)
    }
  }, [open])
  const applyWorkout = async (workout: any) => {
    //Apparently this function works for workouts too...
    const r = await AssignDietPlanDay(
      client?.id || auth.id,
      [workout],
      todayDate,
      'training-plans'
    )

    const event = r[0]

    const updatedEvent = { ...event }
    updatedEvent.resource.time = moment().format('HH:mm')

    await EditLoggingResource(
      updatedEvent,
      moment().format('YYYY-MM-DD'),
      updatedEvent._id,
      client?.id
    )

    const route = getRoute(Routes.LOGGING_EXERCISE_LOG, {
      date: moment(new Date()).format('YYYY-MM-DD'),
      id: event._id
    }) + (client ? `?client_id=${client?.id}` : '')

    changeLogRoute(route, true)
    !isMobile && setOpen(false)
  }
  const applyMeal = async (meal: any) => {
    const data: any = {}
    data['date'] = moment().format(DATE_FORMAT)
    data['resource_type'] = 'meals'
    data['resource'] = [meal]
    data['multiple_resources'] = true

    const res = await AssignDietPlanActivities(client?.id || auth.id, data, data.date)

    const event = res[0]

    const updatedEvent = { ...event }
    updatedEvent.resource.time = moment().format('HH:mm')

    await EditLoggingResource(
      updatedEvent,
      moment().format('YYYY-MM-DD'),
      updatedEvent._id,
      client?.id
    )

    const route = getRoute(Routes.LOGGING_MEAL, {
      date: data['date'],
      id: event._id
    }) + (client ? `?client_id=${client?.id}` : '')

    changeLogRoute(route, true)
    !isMobile && setOpen(false)
  }

  const [activitiesForToday, setActivitiesForToday] = useState<any[]>()

  const { id: userId } = useAuth()

  useEffect(() => {
    const link =
      EP_CALENDAR +
      '?filter[date]=' +
      moment(new Date()).format('YYYY-MM-DD') +
      `&filter[account_id]=${client?.id || userId}`

    api.get(link).then((res: any) => {
      setActivitiesForToday(res.data.data[0].activities)
    })
  }, [userId, client])

  const logUrlForWorkouts = useMemo(() => {
    const workouts =
      activitiesForToday?.filter(
        (activity: any) => activity.resource_type === 'workouts'
      ) ?? []

    if (workouts.length !== 1) {
      return isMobile
        ? quickAccessRoutes.LOG_EXERCISE
        : Routes.LOGGING_EXERCISES
    } else {
      return getRoute(Routes.LOGGING_EXERCISE_LOG, {
        date: moment(new Date()).format('YYYY-MM-DD'),
        id: workouts[0]._id
      }) + (client ? `?client_id=${client?.id}` : '')
    }
  }, [activitiesForToday, isMobile])

  const logUrlForMeals = useMemo(() => {
    const meals =
      activitiesForToday?.filter(
        (activity: any) => activity.resource_type === 'meals'
      ) ?? []

    if (meals.length !== 1) {
      return isMobile ? quickAccessRoutes.LOG_MEAL : Routes.LOGGING_MEALS
    } else {
      return getRoute(Routes.LOGGING_MEAL, {
        date: moment(new Date()).format('YYYY-MM-DD'),
        id: meals[0]._id
      }) + (client ? `?client_id=${client?.id}` : '')
    }
  }, [activitiesForToday, isMobile])

  const { trainingSplit } = useTrainingSplit()//client?.id
  const dietPlanRevision = useDietPlanCurrentRevision(client?.id)
  const trainingPlanRevision = useTrainingPlanCurrentRevision(client?.id)
  // console.log({client, trainingPlanRevision})
  const logWorkoutsEnabled = useMemo(() => {
    return (
      Object.keys(trainingSplit).length !== 0 || !!trainingPlanRevision.data
    )
  }, [trainingSplit, trainingPlanRevision])

  const logMealsEnabled = useMemo(() => {
    return Object.keys(trainingSplit).length !== 0 || !!dietPlanRevision.data
  }, [trainingSplit, dietPlanRevision])

  const options: any = useMemo(
    () => [
      {
        icon: WorkoutIconV3_1,
        route: isMobile
          ? quickAccessRoutes.LOG_EXERCISE
          : Routes.LOGGING_EXERCISES + (
              type !== userTypes.CLIENT
              ? (client ? '?client_id='+client.id : '')
              : ''
            ),
        label: 'log-workout',
        color: '#E49A0A !important'
      },
      {
        icon: HealthDataIconV3,
        route: isMobile
          ? quickAccessRoutes.LOG_HEALTH_DATA
          : type === userTypes.CLIENT
            ? getRoute(Routes.PROGRESS_CLIENT_LOG_HEALTH_DATA, {
                date: moment(new Date()).format('YYYY-MM-DD')
              })
            : getRoute(Routes.PROGRESS_LOG_HEALTH_DATA, {
                date: moment(new Date()).format('YYYY-MM-DD'),
                clientId: ''+client?.id
              }),
        label: 'health-data',
        color: '#2E81ED'
      },
      {
        icon: MealIconV3,
        route: isMobile
          ? quickAccessRoutes.LOG_MEAL
          : Routes.LOGGING_MEALS + (
            type !== userTypes.CLIENT
            ? (client ? '?client_id='+client.id : '')
            : ''
          ),
        label: 'log-meal',
        color: '#13E428'
      },
      {
        icon: MeasurementIconV3,
        route: type === userTypes.CLIENT
          ? getRoute(Routes.PROGRESS_CLIENT_LOG_MEASUREMENTS)
          : getRoute(Routes.PROGRESS_LOG_MEASUREMENTS, {
              date: moment(new Date()).format('YYYY-MM-DD'),
              clientId: ''+client?.id
            }),
        label: 'measurement',
        color: '#404040'
      }
    ],
    [type, logUrlForMeals, logUrlForWorkouts, client]
  )

  if (type !== userTypes.CLIENT && !client) return <QuickAccessSelectClient />

  return (
    <Styles
      singleColumn={(openWorkout || openMeal)}
    >
      {type !== userTypes.CLIENT ? <QuickAccessSelectedClient /> : null}
      <div className={'qa-log__actions'}>
        {openWorkout ? (
          <WorkoutsActivities
            changeLogRoute={changeLogRoute}
            onSelectWorkout={(workout) => {
              applyWorkout(workout)
            }}
            workoutData={workoutData}
          />
        ) : openMeal ? (
          <MealActivities
            onSelect={applyMeal}
            mealData={mealData}
            alreadyUsedDietPlan={alreadyUsedDietPlan}
          />
        ) : (
          <>
            {options.map((option: any) => (
              <QuickAccessAction
                disabled={
                  (option.label === 'log-workout' && !logWorkoutsEnabled) ||
                  (option.label === 'log-meal' && !logMealsEnabled)
                }
                setWorkoutData={setWorkoutData}
                setMealData={(dietPlan: any, alreadyUsed: any) => {
                  setMealData(dietPlan)
                  setAlreadyUsedDietPlan(alreadyUsed)
                }}
                setOpenMeal={setOpenMeal}
                setOpenWorkout={setOpenWorkout}
                key={option.route}
                {...option}
              />
            ))}
          </>
        )}

        {/* {(openWorkout || openMeal) && <div className="buttons-container" />} */}
      </div>
    </Styles>
  )
}

export default QuickAccessLog
