import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import {
  CaretDownIcon,
  CaretLeftIcon,
  DeleteOutlinedIcon,
  EditIcon
} from '../../../../../assets/media/icons'
import Formatter from '../../../../../managers/formatter.manager'
import { useTranslation } from '../../../../../modules/i18n/i18n.hook'
import { getTemplates } from '../../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../../utils/arrays'
import AutoCompleteInput from '../../../../form/autoCompleteInput/autoCompleteInput.component'
import Input from '../../../../form/input/input.component'
import Macronutrient from '../../../components/quick-access-macronutrient/quick-access-macronutrient.component'
import Styles from './quick-access-food-item.styles'

interface Props {
  macronutrients: any
  handleRemove: Function
  setStateNutrients: Function
  index: number
  item: any
}

const QuickAccessFoodItem: FC<Props> = ({
  macronutrients,
  handleRemove,
  index,
  setStateNutrients,
  item
}) => {
  const { t } = useTranslation()
  const [showMacronutrients, setShowMacronutrients] = useState(false)
  const [food, setFood] = useState(item)
  const [foods, setFoods] = useState<any>([])
  const [macros] = useState<any>({
    proteins: food.data.info.proteins / food.data.info.grams,
    fat: food.data.info.fat / food.data.info.grams,
    net_carbs: food.data.info.net_carbs / food.data.info.grams,
    sugar: food.data.info.sugar / food.data.info.grams,
    fiber: food.data.info.fiber / food.data.info.grams,
    total_carbs: food.data.info.total_carbs / food.data.info.grams,
    calories: food.data.info.calories / food.data.info.grams
  })
  const foodRef = useRef<HTMLDivElement | null>(null)

  const [calcCalories, setCalcCalories] = useState(1)
  const getTwoDecimal = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }
  const [totalMacros, setTotalMacros] = useState({
    proteins: 0,
    fat: 0,
    net_carbs: 0,
    sugar: 0,
    fiber: 0,
    total_carbs: 0,
    calories: 0
  })

  useEffect(() => {
    if (food.data.new) {
      foodRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [])

  const useTemplateFoods = async (name: string) => {
    const data = await getTemplates('/foods', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: 'all'
      }
    })
    setFoods(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = foods
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(food.data.name?.toLowerCase()) &&
          w?.name !== food.data.name
      )
      ?.map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions?.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [foods, food.data.name])
  const onFoodSelected = (value: string) => {
    // find in templates
    const foodTemplate = foods?.find((m: any) => m._id === value)
    if (food) {
      food.data.name = foodTemplate.name
      food.data.info.grams = foodTemplate.info.grams || 0
      food.data.info.fat = foodTemplate.info.fat || 0
      food.data.info.fiber = foodTemplate.info.fiber || 0
      food.data.info.net_carbs = foodTemplate.info.net_carbs || 0
      food.data.info.proteins = foodTemplate.info.proteins || 0
      food.data.info.sugar = foodTemplate.info.sugar || 0
      food.data.info.total_carbs = foodTemplate.info.total_carbs || 0
      food.data.info.calories = foodTemplate.info.calories || 0
      const mockFood = { ...food }
      setFood(mockFood)
      setCalcCalories(calcCalories + 1)
      setStateNutrients(calcCalories + 1)
    }
  }

  useEffect(() => {
    const macros: any = {
      proteins: 0,
      fat: 0,
      net_carbs: 0,
      sugar: 0,
      fiber: 0,
      total_carbs: 0,
      calories: 0
    }

    Object.keys(food.data.info).forEach((elem) => {
      macros[elem] += +food.data.info[elem]
    })

    setTotalMacros(macros)
  }, [calcCalories])
  // saddff
  useEffect(() => {
    food.data.info.calories = getTwoDecimal(
      (food.data.info?.proteins || 0) * 4 +
        (food.data.info?.net_carbs || 0) * 4 +
        (food.data.info?.fat || 0) * 9
    )
    food.data.info.total_carbs = getTwoDecimal(
      (+food.data.info?.net_carbs || 0) + (+food.data.info?.fiber || 0)
    )
    setFood(food)
  }, [calcCalories])

  const multiplyMacros = (grams: any) => {
    food.data.info.proteins = getTwoDecimal(macros.proteins * grams || 0)
    food.data.info.fat = getTwoDecimal(macros.fat * grams || 0)
    food.data.info.net_carbs = getTwoDecimal(macros.net_carbs * grams || 0)
    food.data.info.sugar = getTwoDecimal(macros.sugar * grams || 0)
    food.data.info.fiber = getTwoDecimal(macros.fiber * grams || 0)
  }

  return (
    <Styles>
      <div
        className="qa-food-item__card"
        style={{
          width: showMacronutrients ? '' : 'calc(100% + 80px)'
        }}
        ref={foodRef}
      >
        <div className="qa-food-item__card-header">
          <h2>{food.data.name}</h2>
        </div>
        <div className="qa-food-item__card-delete-button-container">
          <button
            className="qa-food-item__card-macronutrients-button"
            onClick={() => setShowMacronutrients((prevState) => !prevState)}
          >
            {showMacronutrients ? (
              <>
                <span>Hide macronutrients</span>
                <CaretDownIcon />
              </>
            ) : (
              <>
                <span>Show macronutrients</span>
                <CaretLeftIcon style={{ margin: '-3px' }} />
              </>
            )}
          </button>
          <button className="qa-food-item__card-delete-button__button">
            <DeleteOutlinedIcon
              onClick={() => handleRemove(index)}
              color="red"
            />
          </button>
          <button className="qa-food-item__card-edit-button">
            <EditIcon
              onClick={() => {
                food.data.new = true
                setFood({...food})
              }}
              color="red"
            />
          </button>
        </div>

        {showMacronutrients && (
          <div className="qa-food-item__card-macronutrients">
            <>
              {Object.keys(macronutrients).map((k) => (
                <Macronutrient
                  key={k}
                  title={k}
                  amount={`${getTwoDecimal(
                    (totalMacros as any)[macronutrients[k]]
                  )}${k === 'calories' ? 'KCal' : 'g'}`}
                />
              ))}
            </>
          </div>
        )}

        <div
          className={`qa-food-item__card-input-group ${
            food.data.new && 'qa-food-item__grid-template'
          }`}
          style={{ maxWidth: showMacronutrients ? 'calc(100% - 430px)' : '' }}
        >
          {food.data.new && (
            <AutoCompleteInput
              id="qa-food-item-name"
              label="Food name"
              value={food.data.name}
              onChange={(name) => {
                food.data.name = name
                const mockFood = { ...food }
                setFood(mockFood)
                if (name.length >= 3) useTemplateFoods(name)
              }}
              onSelect={onFoodSelected}
              options={nameOptions}
              log={true}
            />
          )}

          <Input
            id="qa-food-item-quantity"
            label="Quantity (gram)"
            type={`number`}
            value={food.data.info.grams}
            format={Formatter().number()}
            onChange={(e) => {
              food.data.info.grams = +e.target.value
              // if (!food.data.new) 
              multiplyMacros(e.target.value)
              setFood(food)
              setCalcCalories(calcCalories + 1)
              setStateNutrients(calcCalories)
            }}
          />

          {food.data.new && (
            <>
              {Object.keys(food.data.info).map((k) => {
                if (k != 'calories' && k != 'grams')
                  return (
                    <div key={k}>
                      <Input
                        id={`qa-food-item-${k}`}
                        label={t(`quickaccess:add-food.label-${k}`)}
                        value={food.data.info[k]}
                        disabled={k == 'total_carbs'}
                        format={Formatter().number()}
                        readOnly={k == 'total_carbs'}
                        onChange={(e) => {
                          food.data.info[k] = e.target.value
                          setFood(food)
                          setCalcCalories(calcCalories + 1)
                          setStateNutrients(calcCalories)
                        }}
                      />
                    </div>
                  )
              })}
            </>
          )}

          <Input
            id="qa-food-item-calories"
            label="Calories"
            readOnly
            disabled
            value={food.data.info.calories}
            format={Formatter().number()}
          />
        </div>
      </div>
    </Styles>
  )
}

export default QuickAccessFoodItem
