import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { MealIconV3 } from '../../../assets/media/icons'
import Button from '../../../components/buttons/button/button.component'
import Card from '../../../components/cards/card/card.component'
import Link from '../../../components/link/link.component'
import { toast } from '../../../components/toast/toast.component'
import { Routes } from '../../../enums/routes.enum'
import { useAuth } from '../../../hooks/auth.hook'
import { AssignDietPlanActivities } from '../../../services/api/logging'
import { useActivitiesForToday, useDietPlanCurrentRevision } from '../../../store/query'
import { DATE_FORMAT } from '../../../utils/date'
import { getRoute } from '../../../utils/routes'
import DayAccordion from '../../activities/components/day-accordion/day-accordion.component'
import SingleFood from './food/single-food.component'
import Styles from './main.styles'
import SupplementViewComponent from './supplement/SupplementView.component'

export const formatData = (data: any, date: any) => {
  return [
    {
      resource_type: 'meals',
      created_by: null,
      updated_at: data?.[0]?.activities?.[0]?.updated_at,
      created_at: data?.[0]?.activities?.[0]?.created_at,
      _id: data?.[0]?.activities?.[0]?._id?.toString(),
      resource: {
        name: data?.[0]?.activities?.[0]?.name,
        time: data?.[0]?.activities?.[0]?.time,
        updated_at: data?.[0]?.activities?.[0]?.updated_at,
        created_at: data?.[0]?.activities?.[0]?.created_at,
        _id: data?.[0]?.activities?.[0]?._id?.toString(),
        items: data?.[0]?.activities?.[0]?.items.map((item: any) => {
          return {
            updated_at: item?.updated_at,
            created_at: item?.created_at,
            _id: item?._id?.toString(),
            data: {
              public: item?.data?.public,
              name: item?.data?.name,
              info: {
                grams: item?.data?.info?.grams,
                proteins: item?.data?.info?.proteins,
                fat: item?.data?.info?.fat,
                net_carbs: item?.data?.info?.net_carbs,
                sugar: item?.data?.info?.sugar,
                fiber: item?.data?.info?.fiber,
                total_carbs: item?.data?.info?.total_carbs,
                calories: item?.data?.info?.calories
              },
              updated_at: item?.data?.updated_at,
              created_at: item?.data?.created_at,
              _id: item?.data?._id?.toString()
            },
            status: 'nothing_logged',
            log: null
          }
        }),
        total_target: data?.[0]?.activities?.[0]?.total_target,
        supplements: data?.[0]?.activities[0]?.supplements.map(
          (supplement: any) => {
            return {
              public: supplement?.public,
              name: supplement?.name,
              info: {
                grams: supplement?.info?.grams,
                description: supplement?.info?.description
              },
              updated_at: supplement?.updated_at,
              created_at: supplement?.created_at,
              _id: supplement?._id?.toString()
            }
          }
        )
      },
      activities_count: null,
      status: 'nothing_logged',
      date: date
    }
  ]
}

const mainComponent = () => {
  const params = new URLSearchParams(document.location.search)
  const clientId = params.get('client_id')
  const { data, refetch: refetchMeals } = useActivitiesForToday({
    filters: { resource_type: 'meals' },
    options: {
      refetchOnMount: true
    },
    clientId: clientId ? +clientId : undefined
  })

  const auth = useAuth()
  const dietPlanRevision = useDietPlanCurrentRevision(clientId ? +clientId : undefined)

  const meals = useMemo(() => {
    return data
      .map((activity) => {
        //Old implementatios used to set status to 'nothing_logged' when
        //there are no items in the meal. So here it is
        if (activity.resource?.items.length === 0) {
          return {
            ...activity,
            status:
              activity.resource?.items.length === 0
                ? 'nothing_logged'
                : activity.status
          }
        } else {
          return activity
        }
      })
      .sort((a, b) => {
        const first = moment(`${a.date} ${a.resource?.time}`)
        const second = moment(`${b.date} ${b.resource?.time}`)

        return first.isBefore(second) ? -1 : 1
      })
  }, [data])
  
  const otherMealsInDietPlan = useMemo(() => {
 
    const revisionData = dietPlanRevision.data
    const newRevisionData: {[key: string]: any[]} = {}

    revisionData?.forEach((dayMeals: any) => {
      const newMeals = dayMeals.activities.filter((meal: any) => {
        if (
          meals?.find(
            (item: any) => item.resource._id == meal._id
          ) ||
          !meal.items.length
        ) {
          return false
        }
        return true
      })

      newRevisionData[dayMeals.name] = newMeals
    })

    return newRevisionData
  }, [meals, dietPlanRevision])

  const actionComponent = useCallback((item: any) => {
    if (!item?._id) {
      return
    }
    return (
      <div>
        {item?.status != 'everything_logged' ? (
          <Link
            className={`meals-page__link`}
            to={getRoute(Routes.LOGGING_MEAL, {
              id: item?._id,
              date: item?.date
            })+`${clientId ? '?client_id='+clientId : ''}`}
          >
            Log Meal
          </Link>
        ) : (
          <Link
            className={`meals-page__link`}
            to={getRoute(Routes.LOGGING_MEAL, {
              id: item?._id,
              date: item?.date
            })+`${clientId ? '?client_id='+clientId : ''}`}
          >
            Logged
          </Link>
        )}

        {
          !clientId &&
          <Link
            className={`meals-page__link`}
            to={{
              pathname: getRoute(Routes.LOGGING_MEAL_ID, {
                id: item?._id
              }),
              state: { item }
            }}
          >
            Edit
          </Link>
        }
      </div>
    )
  }, [])

  const handleAssignMeal = async (item: any) => {
    const data: any = {}
    data['date'] = moment(new Date()).format(DATE_FORMAT)
    data['resource_type'] = 'meals'
    data['resource'] = [item]
    data['multiple_resources'] = false
    data['_id'] = item._id
    const res = await AssignDietPlanActivities(
      clientId ? +clientId : auth.id,
      data,
      data.date,
      ''
    )
    refetchMeals()
    dietPlanRevision.refetch()
    if (res) {
      toast.show({ type: 'success', msg: 'Activity successfully added' })
    }
  }

  const otherMealsActionComponent = useCallback((item: any) => {
    if (!item?._id) {
      return
    }
    return (
      <div>
        <Button
          className={`meals-page__link`}
          onClick={() => handleAssignMeal(item)}
          variant={'text'}
        >
          Add
        </Button>
      </div>
    )
  }, [])

  const handleFoodChange = () => {}
  return (
    <Styles>
      <Card>
        {meals.map((item: any, key: number) => (
          <DayAccordion
            status={item.status}
            key={key}
            title={item.resource.name}
            icon={<MealIconV3 />}
            iconColor={item.status == 'nothing_logged' ? '#2E2F31' : '#f8f8f8'}
            onRemove={() => {}}
            summeryClass={`meals-page__summary`}
            contentClass={`meals-page__bg-neutral_10`}
            actionComponent={actionComponent(item)}
            defaultOpen={false}
          >
            <div>
              {(item['log'] || item['resource']).items?.map(
                (row: object, index: any) => (
                  <SingleFood
                    key={`${index}-${Math.random()}`}
                    mealKey={key}
                    foodKey={index}
                    row={row}
                    //handleRemove={handleRemove}
                    handleFoodChange={handleFoodChange}
                  />
                )
              )}
              {item.resource.supplements.map(
                (supplement: any, index: number) => (
                  <SupplementViewComponent
                    key={`${index}`}
                    index={index}
                    meal={item.resource}
                  />
                )
              )}
            </div>
          </DayAccordion>
        ))}
        <div className={'meals-page__footer'}>
          <Link to={Routes.LOGGING_MEAL_ADD}>
            <Button>Add New Meal</Button>
          </Link>
        </div>
      </Card>

      {
        Object.values(otherMealsInDietPlan).find((meals: any[]) => meals.length > 0) &&
        <div className='meals-page__other-meals'>

          <div className='meals-page__other-meals-title'>Other Meals</div>
          <Card>
            {Object.entries(otherMealsInDietPlan).map((meals, i) => (
              meals[1].length ?
              <div key={i}>
                <div className='meals-page__other-meals-day-title'>{meals[0]}</div>
                {meals[1].map((meal, idx) => (
                  <DayAccordion
                    status={'nothing_logged'}
                    key={idx}
                    title={meal.name}
                    icon={<MealIconV3 />}
                    iconColor={'#2E2F31'}
                    onRemove={() => {}}
                    summeryClass={`meals-page__summary`}
                    contentClass={`meals-page__bg-neutral_10`}
                    actionComponent={otherMealsActionComponent(meal)}
                    defaultOpen={false}
                  >
                    <div>
                      {meal.items?.map(
                        (row: object, index: any) => (
                          <SingleFood
                            key={`${index}-${Math.random()}`}
                            mealKey={i}
                            foodKey={index}
                            row={row}
                            //handleRemove={handleRemove}
                            handleFoodChange={handleFoodChange}
                          />
                        )
                      )}
                      {meal.supplements.map(
                        (supplement: any, index: number) => (
                          <SupplementViewComponent
                            key={`${index}`}
                            index={index}
                            meal={meal}
                          />
                        )
                      )}
                    </div>
                  </DayAccordion>
                ))}
              </div>
              : <></>
            ))}
          </Card>
        </div>
      }
    </Styles>
  )
}

export default mainComponent
