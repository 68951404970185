import { useEffect, useMemo, useState } from 'react'

import AutoCompleteInput from '../../../../components/form/autoCompleteInput/autoCompleteInput.component'
import Input from '../../../../components/form/input/input.component'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { getTemplates } from '../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../utils/arrays'
import Styles from './food.styles'

interface SingleFoodProps {
  row: any
  mealKey?: number
  foodKey?: number
  addFood?: boolean
  addMeal?: boolean
  handleRemove?: Function
  handleFoodChange: Function
  totalMacros?: any
}

const singleFoodComponent = ({
  row,
  addFood,
  handleFoodChange,
  addMeal
}: SingleFoodProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
  const [foods, setFoods] = useState<any>([])
  const [macros, setMacros] = useState<any>({
    proteins: row.data.info.proteins / row.data.info.grams,
    fat: row.data.info.fat / row.data.info.grams,
    net_carbs: row.data.info.net_carbs / row.data.info.grams,
    sugar: row.data.info.sugar / row.data.info.grams,
    fiber: row.data.info.fiber / row.data.info.grams,
    total_carbs: row.data.info.total_carbs / row.data.info.grams,
    calories: row.data.info.calories / row.data.info.grams
  })
  const multiplyMacros = (grams: any) => {
    row.data.info.proteins = getTwoDecimal(macros.proteins * grams || 0)
    row.data.info.fat = getTwoDecimal(macros.fat * grams || 0)
    row.data.info.net_carbs = getTwoDecimal(macros.net_carbs * grams || 0)
    row.data.info.sugar = getTwoDecimal(macros.sugar * grams || 0)
    row.data.info.fiber = getTwoDecimal(macros.fiber * grams || 0)
  }
  const { t } = useTranslation()
  const getTwoDecimal = (value: any) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  const nutrients: any = {
    Qty: 'grams',
    Proteins: 'proteins',
    Fat: 'fat',
    'Net Carbs': 'net_carbs',
    Sugar: 'sugar',
    Fiber: 'fiber',
    'Total Carbs': 'total_carbs',
    Calories: 'calories'
  }

  const useTemplateFoods = async (name: string, clientId?: string) => {
    const data = await getTemplates('/foods', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setFoods(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = foods
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(row.data.name?.toLowerCase()) &&
          w?.name !== row.data.name
      )
      ?.map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [foods, row.data.name])
  const onFoodSelected = (value: string) => {
    // find in templates
    const food = foods.find((m: any) => m._id === value)
    if (food) {
      row.data.name = food.name
      row.data.info.grams = food.info.grams || 0
      row.data.info.fat = food.info.fat || 0
      row.data.info.fiber = food.info.fiber || 0
      row.data.info.net_carbs = food.info.net_carbs || 0
      row.data.info.proteins = food.info.proteins || 0
      row.data.info.sugar = food.info.sugar || 0
      row.data.info.total_carbs = food.info.total_carbs || 0
      row.data.info.calories = food.info.calories || 0
      setMacros({
        proteins: row.data.info.proteins / row.data.info.grams,
        fat: row.data.info.fat / row.data.info.grams,
        net_carbs: row.data.info.net_carbs / row.data.info.grams,
        sugar: row.data.info.sugar / row.data.info.grams,
        fiber: row.data.info.fiber / row.data.info.grams,
        total_carbs: row.data.info.total_carbs / row.data.info.grams,
        calories: row.data.info.calories / row.data.info.grams
      })
      handleFoodChange()
      setSelectedTemplate(true)
    } else {
      setSelectedTemplate(null)
    }
  }

  useEffect(() => {
    row.data.info.calories = getTwoDecimal(
      (row.data.info?.proteins || 0) * 4 +
        (row.data.info?.net_carbs || 0) * 4 +
        (row.data.info?.fat || 0) * 9
    )
    row.data.info.total_carbs = getTwoDecimal(
      (+row.data.info?.net_carbs || 0) + (+row.data.info?.fiber || 0)
    )
    handleFoodChange()
  }, [
    row.data.info.proteins,
    row.data.info.net_carbs,
    row.data.info.fat,
    row.data.info.fiber
  ])

  return (
    <Styles>
      <table className={'food__table'}>
        <tbody>
          <tr>
            {!addMeal ? (
              <td width={'20%'}>
                {!addFood ? (
                  <Input
                    id={`food-name`}
                    name={addFood ? 'data.name' : ''}
                    placeholder={`-`}
                    disabled={!addFood}
                    readOnly={!addFood}
                    defaultValue={row.data.name}
                    label={'Food Name'}
                    onChange={(e) => {
                      row.data.name = e.target.value
                      handleFoodChange()
                    }}
                  />
                ) : (
                  <AutoCompleteInput
                    id={`food-name`}
                    name={addFood ? 'data.name' : ''}
                    placeholder={`-`}
                    value={row.data.name}
                    label={'Food Name*'}
                    onChange={(name) => {
                      row.data.name = name
                      handleFoodChange()
                      if (name.length >= 3) useTemplateFoods(name)
                    }}
                    onSelect={onFoodSelected}
                    options={nameOptions}
                  />
                )}
              </td>
            ) : null}
            {Object.values(nutrients).map((item: any, key: any) => (
              <td key={key}>
                <Input
                  className="label-no-wrap"
                  id={`food-${item}`}
                  disabled={
                    !addFood || item == 'calories' || item == 'total_carbs'
                  }
                  readOnly={
                    !addFood || item == 'calories' || item == 'total_carbs'
                  }
                  name={addFood ? `data.info.${item}` : ''}
                  value={row.data.info[item]}
                  placeholder={`-`}
                  onChange={(e) => {
                    if (item === 'grams' && selectedTemplate) {
                      multiplyMacros(+e.target.value)
                    }
                    row.data.info[item] = e.target.value
                    handleFoodChange()
                  }}
                  required={[
                    'total_carbs',
                    'proteins',
                    'fat',
                    'net_carbs',
                    'grams',
                    'calories'
                  ].includes(item)}
                  label={t(`quickaccess:add-food.label-${item}`)}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Styles>
  )
}

export default singleFoodComponent
