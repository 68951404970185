import * as Yup from 'yup'

export const foodValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  time: Yup.string(),
  date: Yup.string().required(),
  info: Yup.object().shape({
    grams: Yup.string().required(),
    proteins: Yup.number().required(),
    fat: Yup.number().required(),
    net_carbs: Yup.number().required(),
    sugar: Yup.string(),
    fiber: Yup.string(),
    total_carbs: Yup.string().required(),
    calories: Yup.string().required()
  })
})
