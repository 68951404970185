// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react'

interface useRestTime {
  activeExercise: any
}

const useRestTime = (activeExercise: any): Date => {
  const [restTime, setRestTime] = useState<Date>(new Date())

  useEffect(() => {
    if (activeExercise?.rest_interval) {
      const execIntervalMinutesInSeconds =
        +activeExercise.rest_interval?.split(':')[0] * 60

      const execIntervalSeconds = +activeExercise.rest_interval?.split(':')[1]
      const res123 = new Date()

      res123.setSeconds(
        res123.getSeconds() +
          (execIntervalMinutesInSeconds + execIntervalSeconds)
      )
      setRestTime(res123)
    }
  }, [activeExercise])

  return restTime
}

export default useRestTime
