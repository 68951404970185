import * as Yup from 'yup'

const validation: any = {
  strengthValidationSchema: Yup.object().shape({
    name: Yup.string().required(),
    date: Yup.string().required(),
    time: Yup.string(),
    total_workout_time: Yup.string().nullable(),
    link: Yup.string(),
    info: Yup.object().shape({
      type: Yup.string().required(),
      sets: Yup.string().required().matches(/^\d+$/, 'Only whole numbers!'),
      reps: Yup.string().reps().nullable(),
      tempo: Yup.string().max(5),
      rest_interval: Yup.string(),
      notes: Yup.string().nullable()
    })
  }),

  cardioValidationSchema: Yup.object().shape({
    name: Yup.string().required(),
    date: Yup.string().required(),
    time: Yup.string(),
    info: Yup.object().shape({
      type: Yup.string().required(),
      duration: Yup.string().required(),
      intensity: Yup.string().required(),
      avg_heart_rate: Yup.number(),
      notes: Yup.string().nullable()
    })
  }),

  supersetValidationSchema: Yup.object().shape({
    name: Yup.string().required(),
    date: Yup.string().required(),
    time: Yup.string(),
    total_workout_time: Yup.string().nullable(),
    info: Yup.object().shape({
      type: Yup.string().required()
    }),
    data: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        link: Yup.string(),
        info: Yup.object().shape({
          sets: Yup.string().required().matches(/^\d+$/, 'Only whole numbers!'),
          reps: Yup.string().reps().nullable(),
          tempo: Yup.string().max(5),
          rest_interval: Yup.string(),
          notes: Yup.string().nullable()
        })
      })
    )
  })
}

export default validation
