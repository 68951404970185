import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import moment from 'moment'

import { EP_ACTIVITY_DATA, EP_CALENDAR } from '../../../enums/api.enum'
import { useAuth } from '../../../hooks/auth.hook'
import api from '../../../managers/api.manager'
import {
  AddLogToActivityOfTheCalendar,
  EditLoggingResource
} from '../../../services/api/logging'
import { Activity, Status } from '../../../types/activity.type'
import { modifyActivityTime } from '../../../utils/activities'
import {
  formatWeekActivities,
  parseActivities
} from '../../../utils/api/calendar'
import { DATE_FORMAT, DateView } from '../../../utils/date'
import {
  addActivityInQueries,
  deleteActivityInQueries,
  getCalendarKeysForDate,
  getKeysForDate,
  parseSingleActivity,
  updateViewQueries
} from './utils'

export const useActivity = (id: string, date: string) => {
  const data = useQuery(['activity', date, id], async () => {
    const r = await api.get(EP_ACTIVITY_DATA(id, date))

    return r
  })

  return data
}

export const useActivityMutations = () => {
  const queryClient = useQueryClient()

  const rescheduleActivityMutation = useMutation(
    async (activity) => {
      if (!activity.start) {
        return
      }

      if (activity.resource_type === 'sessions') {
        const payload = {
          type: activity.title,
          date: activity.date,
          notes: activity.resource?.notes,
          session_id: activity.resource?.id,
          client_request: null,
          duration: activity.resource?.duration,
          time: activity?.time
        }

        await api.put(
          '/sessions/' + payload.session_id + '?include=client',
          payload
        )
      } else {
        const res = await EditLoggingResource(
          activity,
          moment(activity.start).format('YYYY-MM-DD'),
          activity.id,
          activity.user_id
        )

        return parseSingleActivity(res, activity.start)
      }
    },
    {
      onMutate: (activity: Activity) => {
        updateViewQueries({
          queryClient,
          activity
        })
      }
    }
  )

  const logActivityMutation = useMutation(
    async ({
      activity,
      shouldHaveTimeScheduled,
      performRequest = true,
      forceSchedule,
      status = 'everything_logged',
      userId
    }: {
      activity: Activity
      shouldHaveTimeScheduled?: boolean
      forceSchedule?: boolean
      performRequest: boolean
      status: Status
      userId?: number
    }) => {
      let _activity = activity
      activity.user_id = userId
      if (
        forceSchedule ||
        (shouldHaveTimeScheduled && !activity.resource?.time)
      ) {
        _activity = (await rescheduleActivityMutation.mutateAsync(
          modifyActivityTime(
            {
              ...activity,
              status
            },
            moment().minutes(0).toDate()
          ), 
        ))!
      }

      if (performRequest) {
        const response = await AddLogToActivityOfTheCalendar(
          _activity.date,
          _activity.id,
          _activity.resource_type === 'meals'
            ? _activity.resource!
            : { log_all: true },
          userId
        )

        return parseSingleActivity(response, moment(_activity.date).toDate())
      }
    },
    {
      onMutate: ({ activity, status, shouldHaveTimeScheduled }) => {
        const updated: Activity = { ...activity, status }
        if (shouldHaveTimeScheduled && !activity.resource?.time) {
          updated.start = moment().minutes(0).toDate()
          updated.end = moment().minutes(0).toDate()
          updated.resource!.time = moment().minutes(0).format('HH:mm')
        }

        updateViewQueries({
          queryClient,
          activity: updated
        })
      }
    }
  )

  return {
    updateActivity: (activity: Activity) => {
      updateViewQueries({
        queryClient,
        activity
      })
    },
    rescheduleActivity: async (activity: Activity, start: Date) => {
      const modifiedActivity = modifyActivityTime(activity, start)
      return rescheduleActivityMutation.mutateAsync(modifiedActivity)
    },
    logActivity: ({
      activity,
      shouldHaveTimeScheduled,
      performRequest = true,
      status = 'everything_logged',
      userId
    }: {
      activity: Activity
      status: Status

      //Hack to avoid breaking cardio exercise logging
      performRequest?: boolean

      /** checks if activity.resource.time is scheduled */
      shouldHaveTimeScheduled?: boolean
      userId?: number
    }) => {
      logActivityMutation.mutate({
        //For whatever reason, after passing the activity to the mutation,
        //the time in activity.resource.time changes to something else
        activity,

        //To make sure the time is scheduled when it should,
        //we use the forceSchedule flag
        forceSchedule: shouldHaveTimeScheduled && !activity.resource?.time,
        shouldHaveTimeScheduled,

        performRequest,
        status,
        userId: userId
      })
    },
    deleteActivity: (activity: Activity) => {
      deleteActivityInQueries({
        activity,
        queryClient
      })
    },
    addActivities: ({
      activities,
      date
    }: {
      activities: Activity[]
      date: Date
    }) => {
      addActivityInQueries({
        activities,
        date,
        queryClient
      })
    }
  }
}

export const useActivitiesForToday = (params?: {
  filters: {
    resource_type: 'meals' | 'workouts'
  }
  options?: UseQueryOptions<Activity[]>
  clientId?: number
}) => {
  const data = useActivities(
    new Date(),
    'day',
    params?.options,
    params?.clientId
  )

  if (!params?.filters) {
    return data
  } else {
    return {
      ...data,
      data: data.data.filter(
        (activity) => activity.resource_type === params.filters.resource_type
      )
    }
  }
}

export const useActivities = (
  date: Date,
  view: DateView,
  options?: UseQueryOptions<Activity[]>,
  clientId?: number,
  isCalendarPage?: boolean
) => {
  const { id: userId } = useAuth()

  const keys = isCalendarPage
    ? getCalendarKeysForDate(date)
    : getKeysForDate(date)

  const key = keys[view]

  const mStart = moment(date).startOf(view)
  const mEnd = moment(date).endOf(view)

  const queryClient = useQueryClient()

  const populateDays = (activities: Activity[]) => {
    const days: Record<string, Activity[]> = {}

    activities.forEach((activity) => {
      const start = activity.date
      const day = moment(start).toString()
      if (!days[day]) {
        days[day] = []
      }
      days[day].push(activity)
    })

    Object.entries(days).map(([day, activities]) => {
      queryClient.setQueryData(['activities', day], activities)
    })
  }

  const populateWeeks = (activities: Activity[]) => {
    const weekNoStart = mStart.isoWeek()
    const weekNoEnd = mEnd.isoWeek()

    const weeks: Record<string, Activity[]> = {}
    activities.forEach((activity) => {
      const weekNo = moment(activity.date).isoWeek()
      if (weekNo >= weekNoStart && weekNo <= weekNoEnd) {
        if (!weeks[weekNo]) {
          weeks[weekNo] = []
        }
        weeks[weekNo].push(activity)
      }
    })

    Object.entries(weeks).map(([week, activities]) => {
      queryClient.setQueryData(['activities', week], activities)
    })
  }

  const result = useQuery<Activity[]>(
    key,
    async () => {
      const start = mStart.format(DATE_FORMAT)
      const end = (view === 'week' ? mEnd.add(1, 'day') : mEnd).format(
        DATE_FORMAT
      )

      const link =
        EP_CALENDAR +
        `?filter[date]=${start},${end}&filter[account_id]=${
          isCalendarPage ? userId : clientId || userId
        }`

      const mTagKey = 'month_tag' + start
      const mDataKey = 'month_cache' + start
      const monthTag = localStorage.getItem(mTagKey)
      const cached = localStorage.getItem(mDataKey)

      const config =
        monthTag && cached && view === 'month'
          ? { headers: { 'If-None-Match': monthTag } }
          : {}

      const response = await api.get(link, config)

      const shouldReuseOldData = response.status === 304

      const days = response.data?.data

      const parsed = shouldReuseOldData
        ? JSON.parse(cached!)
        : parseActivities(days)

      if (view === 'month') {
        if (!shouldReuseOldData) {
          const etag = response.headers['etag']
          localStorage.setItem(mTagKey, etag)
          localStorage.setItem(mDataKey, JSON.stringify(parsed))
        }

        const activities = formatWeekActivities(parsed)
        populateDays(activities)
        populateWeeks(activities)

        return parsed
      } else {
        const data = formatWeekActivities(parsed)
        data.forEach((activity) => {
          if (!activity.resource.time) {
            return
          }

          //Count activities on the same date and time
          activity.count = data.filter(
            (a) =>
              a.date === activity.date &&
              a._id !== activity._id &&
              a.resource.time === activity.resource.time
          ).length
        })
        return data
      }
    },
    {
      refetchOnMount: true,
      ...options,
      initialData: () => {
        return (queryClient.getQueryData(key) ?? []) as Activity[]
      }
    }
  )

  return result
}
