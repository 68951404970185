import moment from 'moment'

import { EP_CALENDAR } from '../../enums/api.enum'
import api from '../../managers/api.manager'

export async function addTrainingPlan(data: any) {
  const response = await api.post('/training-plans', data)
  return response.data.data
}

export async function editTrainingPlan(
  id: string,
  revisionId: string,
  data: any
) {
  const response = await api.put(
    `/training-plans/${id}/revisions/${revisionId}`,
    data
  )
  return response.data.data
}

export async function getTrainingPlans(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingPlanRevision(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingPlanExercises(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingPlan(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function addDietPlan(data: any) {
  const response = await api.post('/diet-plans', data)
  return response.data.data
}

export async function editDietPlan(id: string, revisionId: string, data: any) {
  const response = await api.put(
    `/diet-plans/${id}/revisions/${revisionId}`,
    data
  )
  return response.data.data
}

export async function getDietPlans(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getDietPlanRevision(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getDietPlan(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingSplits(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingSplitRevision(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function getTrainingSplit(url: string) {
  const response = await api.get(url)
  return response.data
}

export async function addTrainingSplit(data: any) {
  const response = await api.post('/training-splits', data)
  return response.data.data
}

export async function editTrainingSplit(
  id: string,
  revisionId: string,
  data: any
) {
  const response = await api.put(
    `/training-splits/${id}/revisions/${revisionId}`,
    data
  )
  return response.data.data
}

export async function getLoggedData(url: string) {
  const response = await api.get(url)
  return response?.data?.data?.[0]
}

export async function getActivitiesForToday(userId: number) {
  const link =
    EP_CALENDAR +
    '?filter[date]=' +
    moment(new Date()).format('YYYY-MM-DD') +
    `&filter[account_id]=${userId}`

  const resp = await api.get(link)

  return resp.data.data[0].activities
}

export async function getMealsForToday(userId: number) {
  const activities = await getActivitiesForToday(userId)

  return activities.filter(
    (activity: any) => activity.resource_type === 'meals'
  )
}

export async function getWorkoutsForToday(userId: number) {
  const activities = await getActivitiesForToday(userId)

  return activities.filter(
    (activity: any) => activity.resource_type === 'workouts'
  )
}
