import * as Yup from 'yup'

export const workoutValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  date: Yup.string().required(),
  time: Yup.string().required(),
  total_workout_time: Yup.string().nullable(),
  items: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        data: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(),
            info: Yup.object().shape({
              type: Yup.string().required(),
              sets: Yup.string()
                .matches(/^\d+$/, 'Only whole numbers!')
                .nullable()
                .when('type', {
                  is: 'strength',
                  then: (schema) => schema.required()
                }),
              reps: Yup.string().reps().nullable(),
              tempo: Yup.string()
                .max(5)
                .nullable()
                .when('type', {
                  is: 'strength',
                  then: (schema) => schema.required()
                }),
              rest_interval: Yup.string()
                .nullable()
                .when('type', {
                  is: 'strength',
                  then: (schema) => schema.required()
                }),

              duration: Yup.string()
                .nullable()
                .when('type', {
                  is: 'cardio',
                  then: (schema) => schema.required()
                }),
              intensity: Yup.string()
                .nullable()
                .when('type', {
                  is: 'cardio',
                  then: (schema) => schema.required()
                }),
              avg_heart_rate: Yup.string()
                .nullable()
                .when('type', {
                  is: 'cardio',
                  then: (schema) => schema.required()
                }),
              notes: Yup.string().nullable()
            })
          })
        )
      })
    )
})
