import userTypes from '../../../enums/user-types.enum'
import { useChats } from '../../../modules/chat/contexts/chats.context'
import { getUnreadCount } from '../../../utils/api/chat'
import { useAuth } from '../../auth.hook'
import useTrainerAccount from '../accounts/useTrainerAccount'

interface UseChatUnread {
  unread: number
}

export default function useChatUnread(): UseChatUnread {
  const { rooms } = useChats()
  const { type } = useAuth()
  const { user: trainer } = useTrainerAccount()
  const unread = getUnreadCount(rooms, type === userTypes.CLIENT, trainer?.uuid)
  return {
    unread
  }
}
