import styled from 'styled-components'

import { mediaQueries } from '../../enums/screen-sizes.enum'
import { getColor } from '../../pipes/theme-color.pipe'

export default styled.div<any>`
  font-size: 0.875rem;
  font-weight: 450;
  line-height: 1.25rem;
  display: flex;
  border-radius: 10px;

  height: 36px;
  align-items: center;
  white-space: nowrap;

  @media ${mediaQueries.MOBILE} {
    height: auto !important;
    align-items: flex-start !important;
    white-space: normal !important;
  }

  &::before {
    @media ${mediaQueries.MOBILE} {
      margin-top: 5px;
    }
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    aspect-ratio: 1;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: ${getTextColor};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getBgColor(props: any): string {
  switch (props.$type) {
    case 'due-soon':
    case 'due_soon':
      return getColor(props, 'orange_20')
    case 'scheduled':
    case 'outstanding':
      return getColor(props, 'blue_20')
    case 'overdue':
    case 'cancelled':
    case 'inactive':
      return getColor(props, 'red_10')
    case 'paid':
    case 'issued':
    case 'active':
      return getColor(props, 'green_90')
    case 'draft':
      return getColor(props, 'secondary3_v2')
    case 'info':
      return getColor(props, 'secondary')
    default:
      return ''
  }
}

function getTextColor(props: any): string {
  switch (props.$type) {
    case 'due-soon':
    case 'due_soon':
      return getColor(props, 'orange_100')
    case 'outstanding':
    case 'scheduled':
      return '#FF6B40'
    case 'overdue':
    case 'cancelled':
    case 'inactive':
      return '#C62127'
    case 'paid':
    case 'issued':
    case 'active':
    case 'active/scheduled':
      return '#006D39'
    case 'draft':
      return getColor(props, 'secondary2_v2')
    case 'info':
      return getColor(props, 'primaryDark_v2')
    default:
      return ''
  }
}
