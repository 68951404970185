import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'

import userTypes from '../../../../enums/user-types.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { AddLoggingResource } from '../../../../services/api/logging'
import { formatFood } from '../../../../utils/api/logging/add'
import { getTemplates } from '../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../utils/arrays'
import Button from '../../../buttons/button/button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import DatePicker from '../../../form/date-picker/date-picker.component'
import Input from '../../../form/input/input.component'
import TimeInput from '../../../form/TimeInput/time-input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import Macronutrient from '../../components/quick-access-macronutrient/quick-access-macronutrient.component'
import MacronutrientComparison from '../../components/quick-access-macronutrient-comparison/quick-access-macronutrient-comparison.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-add-food.styles'
import { foodValidationSchema } from './validation/foodValidationSchema'

const nutrients: { [key: string]: string } = {
  Proteins: 'proteins',
  Fat: 'fat',
  'Net Carbs': 'net_carbs',
  Sugar: 'sugar',
  Fiber: 'fiber',
  'Total Carbs': 'total_carbs',
  Calories: 'calories'
}

const nutrientValues: { [key: string]: number } = {
  Proteins: 11,
  Fat: 9,
  'Net Carbs': 12,
  Sugar: 10,
  Fiber: 20,
  'Total Carbs': 5,
  Calories: 10
}

const QuickAccessAddFood: FC = () => {
  const { t } = useTranslation()
  const { routeParams, client, setOpen } = useQuickAccess()
  const getTwoDecimal = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null)
  const [foods, setFoods] = useState<any>([])
  const [totalMacros, setTotalMacros] = useState({
    proteins: 0,
    fat: 0,
    net_carbs: 0,
    sugar: 0,
    fiber: 0,
    total_carbs: 0,
    calories: 0
  })

  const { type } = useAuth()

  const formik = useFormik({
    initialValues: {
      name: '',
      date: '',
      time: '',
      info: {
        grams: '',
        proteins: '',
        fat: '',
        net_carbs: '',
        sugar: '',
        fiber: '',
        total_carbs: '',
        calories: ''
      }
    } as { [key: string]: any },
    validationSchema: foodValidationSchema,
    onSubmit: async (values) => {
      const finalData = formatFood(values)
      const response = await AddLoggingResource(
        finalData,
        values.date,
        type === userTypes.TRAINER && client?.id ? client.id.toString() : null
      )

      if (response) {
        toast.show({ type: 'success', msg: 'Food successfully created' })
        setOpen(false)
      }
    }
  })

  const [macros, setMacros] = useState<any>({
    proteins: formik.values.info.proteins / formik.values.info.grams,
    fat: formik.values.info.fat / formik.values.info.grams,
    net_carbs: formik.values.info.net_carbs / formik.values.info.grams,
    sugar: formik.values.info.sugar / formik.values.info.grams,
    fiber: formik.values.info.fiber / formik.values.info.grams,
    total_carbs: formik.values.info.total_carbs / formik.values.info.grams,
    calories: formik.values.info.calories / formik.values.info.grams
  })

  const multiplyMacros = (grams: any) => {
    formik.setFieldValue('info.fat', getTwoDecimal(macros.fat * grams || 0))
    formik.setFieldValue(
      'info.proteins',
      getTwoDecimal(macros.proteins * grams || 0)
    )
    formik.setFieldValue(
      'info.net_carbs',
      getTwoDecimal(macros.net_carbs * grams || 0)
    )
    formik.setFieldValue('info.sugar', getTwoDecimal(macros.sugar * grams || 0))
    formik.setFieldValue('info.fiber', getTwoDecimal(macros.fiber * grams || 0))
    formik.setFieldValue(
      'info.total_carbs',
      getTwoDecimal(macros.total_carbs * grams || 0)
    )
    formik.setFieldValue(
      'info.calories',
      getTwoDecimal(macros.calories * grams || 0)
    )
  }

  const calculateMacros = () => {
    const macros: any = {
      proteins: 0,
      fat: 0,
      net_carbs: 0,
      sugar: 0,
      fiber: 0,
      total_carbs: 0,
      calories: 0
    }

    formik.setFieldValue(
      'info.total_carbs',
      getTwoDecimal(
        (+formik.values.info?.net_carbs || 0) +
          (+formik.values.info?.fiber || 0)
      )
    )
    formik.setFieldValue(
      'info.calories',
      getTwoDecimal(
        (+formik.values.info?.proteins || 0) * 4 +
          (+formik.values.info?.net_carbs || 0) * 4 +
          (+formik.values.info?.fat || 0) * 9
      )
    )
    Object.keys(formik.values.info).forEach((elem) => {
      macros[elem] += +formik.values.info[elem]
    })

    setTotalMacros(macros)
  }

  const useTemplateFoods = async (name: string, clientId?: string) => {
    const data = await getTemplates('/foods', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setFoods(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = foods
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(formik.values.name?.toLowerCase()) &&
          w?.name !== formik.values.name
      )
      ?.map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions?.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [foods, formik.values.name])

  const onFoodSelected = (value: string) => {
    // find in templates
    const foodTemplate = foods.find((m: any) => m._id === value)
    if (foodTemplate) {
      formik.setFieldValue('name', foodTemplate.name)
      formik.setFieldValue('info.grams', foodTemplate.info.grams || 0)
      formik.setFieldValue('info.fat', foodTemplate.info.fat || 0)
      formik.setFieldValue('info.fiber', foodTemplate.info.fiber || 0)
      formik.setFieldValue('info.net_carbs', foodTemplate.info.net_carbs || 0)
      formik.setFieldValue('info.proteins', foodTemplate.info.proteins || 0)
      formik.setFieldValue('info.sugar', foodTemplate.info.sugar || 0)
      formik.setFieldValue(
        'info.total_carbs',
        foodTemplate.info.total_carbs || 0
      )
      formik.setFieldValue('info.calories', foodTemplate.info.calories || 0)
      setMacros({
        proteins: foodTemplate.info.proteins / foodTemplate.info.grams,
        fat: foodTemplate.info.fat / foodTemplate.info.grams,
        net_carbs: foodTemplate.info.net_carbs / foodTemplate.info.grams,
        sugar: foodTemplate.info.sugar / foodTemplate.info.grams,
        fiber: foodTemplate.info.fiber / foodTemplate.info.grams,
        total_carbs: foodTemplate.info.total_carbs / foodTemplate.info.grams,
        calories: foodTemplate.info.calories / foodTemplate.info.grams
      })
      setTotalMacros({
        proteins: foodTemplate.info.proteins,
        fat: foodTemplate.info.fat,
        net_carbs: foodTemplate.info.net_carbs,
        sugar: foodTemplate.info.sugar,
        fiber: foodTemplate.info.fiber,
        total_carbs: foodTemplate.info.total_carbs,
        calories: foodTemplate.info.calories
      })
      setSelectedTemplate(true)
    } else {
      setSelectedTemplate(null)
    }
  }

  return (
    <Styles>
      <QuickAccessBack
        label={routeParams?.parentRoute === 'meal-overview' ? 'detail' : 'add'}
        route={
          routeParams?.parentRoute === 'meal-overview'
            ? quickAccessRoutes.MEAL_OVERVIEW
            : quickAccessRoutes.ADD
        }
      />

      <div className="qa-add-food__body">
        <h2>{t('quickaccess:add-food.title')}</h2>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <DatePicker
              className="qa-add-food__date"
              id="qa-add-food-date"
              defaultPickerValue={moment()}
              name="date"
              format={'YYYY-MM-DD'}
              defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
              allowClear={false}
              label={t('quickaccess:add-food.label-date')}
              value={formik.values.date}
              onChange={(value, dateStr) =>
                formik.setFieldValue('date', dateStr)
              }
            />

            <div className="qa-add-food__time">
              <TimeInput
                id="qa-add-food-time"
                label="Schedule"
                placeholder="hh:mm"
                name="time"
                value={formik.values.time}
                className={'meal-log__date-container__input'}
                format="hh:mm"
                onChange={(e) => {
                  formik.setFieldValue('time', e.target.value)
                }}
              />
            </div>

            <div className="qa-add-food__input-group">
              <AutoCompleteInput
                className="qa-add-food__input-food"
                id="qa-add-food-food"
                label={t('quickaccess:add-food.label-food')}
                name="name"
                placeholder={t('quickaccess:add-food.placeholder-food')}
                value={formik.values.name}
                // suffix={<SearchIcon />}
                options={nameOptions}
                onSelect={onFoodSelected}
                onChange={(value) => {
                  formik.setFieldValue('name', value)
                  if (value.length >= 3) useTemplateFoods(value)
                }}
              />

              <Input
                className="qa-add-food__input-quantity"
                id="qa-add-food-quantity"
                label={t('quickaccess:add-food.label-quantity')}
                name="info.grams"
                onKeyUp={calculateMacros}
                value={formik.values.info.grams}
                placeholder="-"
                onChange={(e) => {
                  !isNaN(+e.target.value) &&
                    formik.setFieldValue('info.grams', e.target.value)
                  selectedTemplate && multiplyMacros(+e.target.value)
                }}
              />
            </div>

            <div className="qa-add-food__input-group-nutrients">
              {Object.keys(nutrients).map((k) => (
                <Input
                  key={k}
                  className="qa-add-food__input-nutrient"
                  id={`qa-add-food-${nutrients[k]}`}
                  onKeyUp={calculateMacros}
                  label={t(`quickaccess:add-food.label-${nutrients[k]}`)}
                  name={`info.${nutrients[k]}`}
                  value={formik.values.info[nutrients[k]]}
                  disabled={k == 'Total Carbs' || k == 'Calories'}
                  placeholder="-"
                  onChange={(e) =>
                    !isNaN(+e.target.value) &&
                    formik.setFieldValue(`info.${nutrients[k]}`, e.target.value)
                  }
                />
              ))}
            </div>

            <h3>{t('quickaccess:add-food.food-overview')}</h3>
            <div className="qa-add-food__meal-overview">
              {routeParams?.parentRoute === 'meal-overview'
                ? Object.keys(nutrients).map((k) => (
                    <MacronutrientComparison
                      key={k}
                      title={k}
                      amount={nutrientValues[k]}
                      target={10}
                    />
                  ))
                : Object.keys(nutrients).map((k) => (
                    <Macronutrient
                      key={k}
                      title={k}
                      amount={`${getTwoDecimal(
                        (totalMacros as any)[nutrients[k]]
                      )}`}
                    />
                  ))}
            </div>

            <div className="qa-add-food__button-group">
              <Button htmlType={'submit'} className="qa-add-food__button">
                {t('quickaccess:add-food.add-food')}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </Styles>
  )
}

export default QuickAccessAddFood
