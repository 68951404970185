// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react'

interface useExerciseSupersetLoggedInfo {
  activeIndex: number
  sets: Array<any>
}

const useExerciseSupersetLoggedInfo = (
  logWorkout: any,
  activeExercise: any
): useExerciseSupersetLoggedInfo => {
  const [sets, setSets] = useState<any>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)

  useEffect(() => {
    if (logWorkout.length) {
      const maximumSets = extractLogMaximumSetNumber(activeExercise.log)
      const formattedArray = formatLogWorkoutLogSuperSetArray(
        maximumSets,
        activeExercise
      )

      const maximumSetsData = extractMaximumSetNumber(activeExercise.data)
      const formattedArrayData = formatWorkoutLogSuperSetArray(
        maximumSetsData,
        activeExercise
      )

      const res = [...formattedArrayData]
      res.splice(0, formattedArray.length, ...[...formattedArray])
      setSets(res)
      const length =
        formattedArray.length > formattedArrayData.length
          ? formattedArray.length - 1
          : formattedArray.length
      setActiveIndex(length)
    } else {
      const maximumSets = extractMaximumSetNumber(activeExercise.data)
      const formattedArray = formatWorkoutLogSuperSetArray(
        maximumSets,
        activeExercise
      )
      setSets(formattedArray)
    }
  }, [])

  return { activeIndex, sets }
}

export default useExerciseSupersetLoggedInfo

const extractMaximumSetNumber = (data: Array<any>): number => {
  let maximumSetNumber = 1
  data.map((item: any) => {
    maximumSetNumber =
      maximumSetNumber < Number(item.info.sets)
        ? Number(item.info.sets)
        : maximumSetNumber
  })
  return maximumSetNumber
}

const extractLogMaximumSetNumber = (data: Array<any>): number => {
  let maximumSetNumber = 1
  data.map((item: any) => {
    maximumSetNumber =
      maximumSetNumber < item.info.sets.length
        ? item.info.sets.length
        : maximumSetNumber
  })
  return maximumSetNumber
}

const formatWorkoutLogSuperSetArray = (
  maximumSets: number,
  activeExercise: any
) => {
  const mockupData = new Array(maximumSets).fill(null)

  const result: Array<any> = []

  mockupData.map((_, index) => {
    const iteration = index + 1
    activeExercise.data.map((exec: any, indx: number) => {
      if (Number(exec.info.sets) >= iteration) {
        result.push({
          exerciseId: exec._id,
          exercise: ((indx + 10).toString(36) + iteration).toUpperCase(),
          exerciseName: exec.name,
          kgs: 10,
          reps: 10,
          set: iteration.toString(),
          tempo: exec.info.tempo,
          rest_interval: exec.info.rest_interval,
          link: exec.link,
          notes: exec.info.notes
        })
      }
    })
  })

  return result
}

const formatLogWorkoutLogSuperSetArray = (
  maximumSets: number,
  activeExercise: any
) => {
  const mockupData = new Array(maximumSets).fill(null)

  const result: Array<any> = []

  mockupData.map((_, index) => {
    const iteration = index + 1
    activeExercise.log.map((exec: any, indx: number) => {
      const uniqueArray = exec.info.sets.filter((value: any, index: number) => {
        const _value = JSON.stringify(value)
        return (
          index ===
          exec.info.sets.findIndex((obj: any) => {
            return (
              JSON.stringify(obj) === _value &&
              !result.find(
                (item) =>
                  JSON.stringify({
                    set: item.set,
                    kgs: item.kgs,
                    reps: item.reps
                  }) == _value && item.exerciseId == exec._id
              )
            )
          })
        )
      })

      uniqueArray.map((set: any) => {
        const generalInfoItem = activeExercise.data
          .map((item: any) => item)
          .find((fndItem: any) => fndItem._id === exec._id)
        if (
          !result.find(
            (item) =>
              item.exercise ==
              ((indx + 10).toString(36) + iteration).toUpperCase()
          )
        ) {
          result.push({
            exerciseId: exec._id,
            exercise: ((indx + 10).toString(36) + iteration).toUpperCase(),
            exerciseName: generalInfoItem.name,
            tempo: generalInfoItem.info.tempo,
            rest_interval: generalInfoItem.info.rest_interval,
            ...set
          })
        }
      })
    })
  })

  return result
}

export const formatBaseObjectForLogging = (dataArray: Array<any>) => {
  const obj: any = {}

  dataArray.map((item: any) => (obj[item.exerciseId] = []))

  return obj
}

export const formatSetsForEachExercise = (
  baseObj: { [key: string]: [] },
  dataArray: Array<any>
) => {
  const baseObjCopy: any = { ...baseObj }

  dataArray.map((loggedSet: any) => {
    baseObjCopy[loggedSet.exerciseId] = [
      ...baseObjCopy[loggedSet.exerciseId],
      {
        set: loggedSet.set,
        kgs: loggedSet.kgs,
        reps: loggedSet.reps
      }
    ]
  })

  return baseObjCopy
}

export const formatDataPayloadArrayForLogging = (
  formattedObjWithSets: any,
  wasSkipped?: boolean
) => {
  const formattedObjWithSetsCopy: any = { ...formattedObjWithSets }
  const result: Array<any> = []

  Object.entries(formattedObjWithSetsCopy).forEach(
    ([exerciseId, arrayWithSets]) => {
      result.push({
        _id: exerciseId,
        was_skipped: wasSkipped ? true : null,
        info: {
          sets: arrayWithSets
        }
      })
    }
  )

  return result
}

export const formatSupersetLoggingPayload = (
  superSetId: string,
  dataArray: Array<any>
) => {
  return {
    items: [{ _id: superSetId, is_superset: true, data: dataArray }]
  }
}
