import styled from 'styled-components'

import { getColorCarry } from '../../../../pipes/theme-color.pipe'

export default styled.div`
  .qa-add-exercise {
    &__radio-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 1rem;
      overflow-x: auto;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__w-100 {
      width: 100%;
    }
    &__trash {
      color: ${getColorCarry('red')};
      margin-left: 10px;
    }
    &__ml-10 {
      margin-left: 10px;
    }
    &__now-wrap {
      white-space: nowrap;
    }
    &__box-headline {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    &__date,
    &__time,
    &__workout-name,
    &__exercise {
      margin-bottom: 1rem;
    }

    &__radio-input-wrapper {
      width: 100%;
    }

    &__input-group {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
    }

    &__input-group-item {
      width: 150px;
      @media (max-width: 359px) {
        width: 130px;
      }
    }

    &__input-group-item-big {
      width: 100%;
    }

    &__button-group {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__button {
      width: 100%;
    }

    &__add-another-button {
      display: flex;
      justify-content: center;
      gap: 9px;
      color: ${getColorCarry('neutral_70')};
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23757575' stroke-width='1' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 10px;
      border: none;
      padding: 0.75rem;
      width: 100%;
      background-color: ${getColorCarry('white')};
      cursor: pointer;
    }
  }
`
