import styled from 'styled-components'

export default styled.div<any>`
  .qa-log {
    &__actions {
      display: grid;
      grid-template-columns: ${({ singleColumn }) => singleColumn ? '1fr' : '1fr 1fr'};
      width: fit-content;
      margin: 0 auto;
      column-gap: 17px;
    }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`
