import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { set } from 'lodash'
import moment from 'moment'
import React, { FC, useMemo, useState } from 'react'

import {
  AddIcon,
  ClockFilledGrey,
  DeleteOutlinedIcon
} from '../../../../assets/media/icons'
import userTypes from '../../../../enums/user-types.enum'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { AddLoggingResource } from '../../../../services/api/logging'
import { calculateTotalWorkoutTime } from '../../../../utils/activities'
import { formatWorkout } from '../../../../utils/api/logging/add'
import { getTemplates } from '../../../../utils/api/templates'
import { getUniqueItemsByProperties } from '../../../../utils/arrays'
import Button from '../../../buttons/button/button.component'
import IconButton from '../../../buttons/icon-button/icon-button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import DatePicker from '../../../form/date-picker/date-picker.component'
import Input from '../../../form/input/input.component'
import Label from '../../../form/label/label.component'
import Select from '../../../form/select/select.component'
import Textarea from '../../../form/textarea/textarea.component'
import TimeInput from '../../../form/TimeInput/time-input.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-add-workout.styles'
import { workoutValidationSchema } from './validation/workoutValidationSchema'

const QuickAccessAddWorkoutComponent: FC = () => {
  const { t } = useTranslation()
  const [exerciseTemplate, setExerciseTemplate] = useState<any>([])
  const [name, setName] = useState<any>('')
  const [exType, setExType] = useState('strength')

  const { type } = useAuth()
  const { client, setOpen } = useQuickAccess()

  const validate = async () => {
    const res = await workoutValidationSchema.isValid(formik.values)
    if (!res) toast.show({ type: 'error', msg: 'Please fill correct data' })
  }
  const formik: any = useFormik({
    initialValues: {
      name: '',
      time: '',
      total_workout_time: '',
      date: '',
      items: []
    },
    enableReinitialize: true,
    validationSchema: workoutValidationSchema,
    onSubmit: async (values) => {
      const finalData = formatWorkout(values)
      const response = await AddLoggingResource(
        finalData,
        values.date,
        type === userTypes.TRAINER && client?.id ? client.id.toString() : null
      )

      if (response) {
        toast.show({ type: 'success', msg: 'Workout successfully created' })
        setOpen(false)
      }
    }
  })

  const useTemplateExercise = async (name: string, clientId?: string) => {
    const data = await getTemplates('/exercises', {
      page: 1,
      per_page: 10,
      filter: {
        name: name || '',
        account_id: clientId || 'all'
      }
    })
    setExerciseTemplate(data)
  }

  const nameOptions = useMemo(() => {
    const templateOptions = exerciseTemplate
      ?.filter(
        (w: any) =>
          w?.name?.toLowerCase()?.includes(name.toLowerCase()) &&
          w.info.type === exType
      )
      .map((w: any) => ({
        label: w.name,
        value: w._id
      }))

    const options = []

    if (templateOptions.length) {
      options.push({
        label: 'From Templates',
        options: getUniqueItemsByProperties(templateOptions, ['label'])
      })
    }
    return options.length ? options : []
  }, [exerciseTemplate, name])

  const onExerciseSelected = (
    value: string,
    index: number,
    index2?: number
  ) => {
    const ex = exerciseTemplate.find((m: any) => m._id === value)
    if (ex) {
      if (index2) {
        formik.setFieldValue(`items.${index}.data.${index2}.name`, ex.name)
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.sets`,
          ex.info.sets
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.rest_interval`,
          ex.info.rest_interval
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.reps`,
          ex.info.reps
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.tempo`,
          ex.info.tempo
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.duration`,
          ex.info.duration
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.intensity`,
          ex.info.intensity
        )
        formik.setFieldValue(
          `items.${index}.data.${index2}.info.notes`,
          ex.info.notes
        )
        formik.setFieldValue(`items.${index}.data.${index2}.link`, ex.link)
      } else {
        formik.setFieldValue(`items.${index}.data.0.name`, ex.name)
        formik.setFieldValue(`items.${index}.data.0.info.sets`, ex.info.sets)
        formik.setFieldValue(`items.${index}.data.0.info.reps`, ex.info.reps)
        formik.setFieldValue(`items.${index}.data.0.info.tempo`, ex.info.tempo)
        formik.setFieldValue(
          `items.${index}.data.0.info.rest_interval`,
          ex.info.rest_interval
        )
        formik.setFieldValue(
          `items.${index}.data.0.info.duration`,
          ex.info.duration
        )
        formik.setFieldValue(
          `items.${index}.data.0.info.intensity`,
          ex.info.intensity
        )
        formik.setFieldValue(
          `items.${index}.data.0.info.avg_heart_rate`,
          ex.info.avg_heart_rate
        )
        formik.setFieldValue(`items.${index}.data.0.info.notes`, ex.info.notes)
        formik.setFieldValue(`items.${index}.data.0.link`, ex.link)
      }
    }
  }
  const instanceOfExercise = {
    is_superset: false,
    sort_order: null,
    data: [
      {
        name: '',
        info: {
          avg_heart_rate: '',
          duration: '',
          intensity: '',
          reps: '',
          rest_interval: '',
          sets: '',
          tempo: '',
          type: 'strength',
          notes: ''
        },
        link: '',
        sort_order: ''
      }
    ]
  }

  const instanceOfSuperset = {
    is_superset: true,
    sort_order: null,
    data: [
      {
        name: '',
        info: {
          avg_heart_rate: '',
          duration: '',
          intensity: '',
          reps: '',
          rest_interval: '',
          sets: '',
          tempo: '',
          type: 'strength',
          notes: ''
        },
        link: '',
        sort_order: ''
      }
    ]
  }

  const instanceOfCardio = {
    is_superset: false,
    sort_order: null,
    data: [
      {
        name: '',
        info: {
          type: 'cardio',
          duration: '',
          intensity: '',
          avg_heart_rate: '',
          sets: '',
          reps: '',
          tempo: '',
          rest_interval: '',
          notes: ''
        },
        link: '',
        sort_order: ''
      }
    ]
  }

  const handleCalculateTotalTime = (name?: string, value?: string | number) => {
    let data = { ...formik.values }

    if (name !== undefined && value !== undefined) {
      data = set(data, name, value)
    }

    const totalWorkoutTime = calculateTotalWorkoutTime(data)

    formik.setFieldValue('total_workout_time', totalWorkoutTime)
  }

  return (
    <Styles>
      <QuickAccessBack label={'add'} route={quickAccessRoutes.ADD} />
      <h3>{t('quickaccess:add-workout.add-workout')}</h3>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Input
            className="qa-add-workout__form-group"
            id="qa-add-workout-name"
            label={t('quickaccess:add-workout.name-of-workout') + '*'}
            name="name"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
          <TimeInput
            className="qa-add-workout__form-group"
            id="qa-add-workout-schedule"
            label={t('quickaccess:add-workout.schedule') + '*'}
            name="time"
            value={formik.values.time}
            format="hh:mm"
            onChange={(e: any) => formik.setFieldValue('time', e.target.value)}
          />
          <DatePicker
            className="qa-add-workout__form-group"
            id="qa-add-workout-date"
            name={'date'}
            defaultPickerValue={moment()}
            format={'YYYY-MM-DD'}
            defaultValue={moment('2015-01-01', 'YYYY-MM-DD')}
            allowClear={false}
            label={t('quickaccess:add-exercise.label-date')}
            required
            value={formik.values.date}
            onChange={(value, dateStr) => formik.setFieldValue('date', dateStr)}
          />

          <TimeInput
            id="workout-time"
            format={'hh:mm'}
            name={'total_workout_time'}
            label="Total Exercise Time"
            placeholder="hh:mm"
            value={formik.values.total_workout_time}
            className="qa-add-exercise__time"
            onChange={() => {}}
            disabled
            tooltip="Total workout duration in hours and minutes"
            suffix={<ClockFilledGrey />}
          />

          <div className="qa-add-workout__form-group">
            <FieldArray
              name="items"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.items.map((exercise: any, index: any) => (
                    <div key={index}>
                      {exercise.is_superset ? (
                        <div>
                          <div className="qa-add-workout__box-headline">
                            <h3 className="qa-add-workout__now-wrap">
                              {t('quickaccess:add-workout.super_set')}{' '}
                              {index + 1}
                            </h3>
                            <DeleteOutlinedIcon
                              onClick={() => {
                                arrayHelpers.remove(index)
                                handleCalculateTotalTime()
                              }}
                              className={`qa-add-workout__trash`}
                            />
                            <hr className="qa-add-workout__w-100 qa-add-workout__ml-10" />
                          </div>

                          <FieldArray
                            name={`items.${index}.data`}
                            render={(arrayHelpers) => (
                              <div>
                                {formik.values.items[index]?.data.map(
                                  (set: any, index2: any) => (
                                    <div
                                      key={index2}
                                      className={`qa-add-workout__box`}
                                    >
                                      <Label>
                                        {t(
                                          'quickaccess:add-workout.exercise_name'
                                        )}
                                        <span style={{ color: 'red' }}>*</span>
                                      </Label>
                                      <AutoCompleteInput
                                        id="Exercise-name"
                                        name={`items.${index}.data.${index2}.name`}
                                        value={
                                          formik.values.items[index].data[
                                            index2
                                          ].name
                                        }
                                        options={nameOptions}
                                        onSelect={(value) => {
                                          onExerciseSelected(
                                            value,
                                            index,
                                            index2
                                          )
                                        }}
                                        onChange={(name) => {
                                          setName(name)
                                          if (name.length >= 3) {
                                            exType != 'strength' &&
                                              setExType('strength')
                                            useTemplateExercise(name)
                                          }
                                          formik.setFieldValue(
                                            `items.${index}.data.${index2}.name`,
                                            name
                                          )
                                        }}
                                      />

                                      <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                                        <div>
                                          <Label>
                                            {t('quickaccess:add-workout.sets')}
                                            <span style={{ color: 'red' }}>
                                              *
                                            </span>
                                          </Label>
                                          <Input
                                            id="sets"
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.sets
                                            }
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.sets`,
                                                e.target.value
                                              )
                                              handleCalculateTotalTime(
                                                `items.${index}.data.${index2}.info.sets`,
                                                e.target.value
                                              )
                                            }}
                                            name={`items.${index}.data.${index2}.info.sets`}
                                            placeholder="-"
                                            type="number"
                                          />
                                        </div>

                                        <div className="qa-add-workout__ml-10">
                                          <Label>
                                            {t('quickaccess:add-workout.reps')}
                                          </Label>
                                          <Input
                                            id="reps"
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.reps
                                            }
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.reps`,
                                                e.target.value
                                              )
                                              handleCalculateTotalTime(
                                                `items.${index}.data.${index2}.info.reps`,
                                                e.target.value
                                              )
                                            }}
                                            name={`items.${index}.data.${index2}.info.reps`}
                                            placeholder="-"
                                            type="text"
                                          />
                                        </div>
                                      </div>

                                      <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                                        <div>
                                          <Label>
                                            {t('quickaccess:add-workout.tempo')}
                                          </Label>
                                          <Input
                                            id="tempo"
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.tempo
                                            }
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.tempo`,
                                                e.target.value
                                              )
                                              handleCalculateTotalTime(
                                                `items.${index}.data.${index2}.info.tempo`,
                                                e.target.value
                                              )
                                            }}
                                            name={`items.${index}.data.${index2}.info.tempo`}
                                            placeholder="-"
                                            type="text"
                                          />
                                        </div>

                                        <div className="qa-add-workout__ml-10">
                                          <Label>
                                            {t(
                                              'quickaccess:add-workout.rest_interval'
                                            )}
                                          </Label>
                                          <TimeInput
                                            id={'rest_interval_input'}
                                            name={`items.${index}.data.${index2}.info.rest_interval`}
                                            placeholder="-"
                                            value={
                                              formik.values.items[index].data[
                                                index2
                                              ].info.rest_interval
                                            }
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `items.${index}.data.${index2}.info.rest_interval`,
                                                e.target.value
                                              )
                                              handleCalculateTotalTime(
                                                `items.${index}.data.${index2}.info.rest_interval`,
                                                e.target.value
                                              )
                                            }}
                                            format="mm:ss"
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className={`qa-add-workout__form-group qa-add-workout__flex-form-group qa-add-workout__flex-last`}
                                      >
                                        <div
                                          className={`qa-add-workout__w-100`}
                                        >
                                          <Label>
                                            {t('quickaccess:add-workout.link')}
                                          </Label>
                                          <Field
                                            className="qa-add-workout__formik-input ant-input input__input"
                                            name={`items.${index}.data.${index2}.link`}
                                            placeholder="https://"
                                            type="text"
                                          />
                                        </div>
                                        {formik.values.items[index]?.data
                                          .length > 1 ? (
                                          <IconButton
                                            className="qa-add-workout__ml-10"
                                            onClick={() => {
                                              arrayHelpers.remove(index2)
                                              handleCalculateTotalTime()
                                            }}
                                          >
                                            <DeleteOutlinedIcon color="red" />
                                          </IconButton>
                                        ) : null}
                                      </div>

                                      <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                                        <Textarea
                                          className="Exercise__notes-textarea"
                                          id="Exercise-notes"
                                          label="Notes"
                                          placeholder="You can add your notes here..."
                                          value={
                                            formik.values.items[index].data[
                                              index2
                                            ].info?.notes
                                          }
                                          rows={
                                            formik.values.items[index].data[
                                              index2
                                            ].info?.notes?.search('\n') >= 0
                                              ? 4
                                              : 1
                                          }
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `items.${index}.data.${index2}.info.notes`,
                                              e.target.value
                                            )
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )
                                )}

                                <Button
                                  variant="text"
                                  onClick={() => {
                                    arrayHelpers.push(
                                      instanceOfExercise.data[0]
                                    )
                                  }}
                                  className="qa-workout-overview-add-exercise__add-superset-exercise-btn qa-add-workout__negative-space"
                                >
                                  <AddIcon /> <span>Add exercise to group</span>
                                </Button>
                              </div>
                            )}
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="qa-add-workout__box-headline">
                            <h3 className="qa-add-workout__now-wrap">
                              {t('quickaccess:add-workout.exercise')}{' '}
                              {index + 1}
                            </h3>
                            <DeleteOutlinedIcon
                              onClick={() => {
                                arrayHelpers.remove(index)
                                handleCalculateTotalTime()
                              }}
                              className={`qa-add-workout__trash`}
                            />
                            <hr className="qa-add-workout__w-100 qa-add-workout__ml-10" />
                          </div>
                          <div className={`qa-add-workout__box`}>
                            <Label>
                              {exercise.data[0].info.type === 'cardio'
                                ? t('quickaccess:add-workout.cardio_name')
                                : t('quickaccess:add-workout.exercise_name')}
                              <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <AutoCompleteInput
                              id="exercise_name"
                              name={`items.${index}.data.0.name`}
                              value={formik.values.items[index].data[0].name}
                              options={nameOptions}
                              onSelect={(value) => {
                                onExerciseSelected(value, index)
                              }}
                              onChange={(name) => {
                                setName(name)
                                if (name.length >= 3) {
                                  setExType(exercise.data[0].info.type)
                                  useTemplateExercise(name)
                                }
                                formik.setFieldValue(
                                  `items.${index}.data.0.name`,
                                  name
                                )
                              }}
                            />

                            <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                              <div>
                                {exercise.data[0].info.type === 'cardio' ? (
                                  <TimeInput
                                    id="cardio-duration"
                                    name={`items.${index}.data.0.info.duration`}
                                    label={
                                      t('quickaccess:add-workout.duration') +
                                      '*'
                                    }
                                    placeholder="hh:mm"
                                    value={
                                      formik.values.items[index].data[0].info
                                        .duration
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        `items.${index}.data.0.info.duration`,
                                        e.target.value
                                      )
                                    }
                                    format="hh:mm"
                                  />
                                ) : (
                                  <>
                                    <Label>
                                      {t('quickaccess:add-workout.sets')}
                                    </Label>
                                    <Input
                                      id="sets"
                                      name={`items.${index}.data.0.info.sets`}
                                      value={
                                        formik.values.items[index].data[0].info
                                          .sets
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `items.${index}.data.0.info.sets`,
                                          e.target.value
                                        )
                                        handleCalculateTotalTime(
                                          `items.${index}.data.0.info.sets`,
                                          e.target.value
                                        )
                                      }}
                                      placeholder="-"
                                    />
                                  </>
                                )}
                              </div>

                              <div className="qa-add-workout__ml-10">
                                {exercise.data[0].info.type === 'cardio' ? (
                                  <Select
                                    label={t(
                                      'quickaccess:add-workout.intensity'
                                    )}
                                    name={`items.${index}.data.0.info.intensity`}
                                    id="cardio-intensity"
                                    value={
                                      formik.values.items[index].data[0].info
                                        .intensity
                                    }
                                    onChange={(value) =>
                                      formik.setFieldValue(
                                        `items.${index}.data.0.info.intensity`,
                                        value
                                      )
                                    }
                                    options={t('quickaccess:intensity-options')}
                                    required
                                  />
                                ) : (
                                  <>
                                    <Label>
                                      {t('quickaccess:add-workout.reps')}
                                    </Label>
                                    <Input
                                      id="reps"
                                      name={`items.${index}.data.0.info.reps`}
                                      value={
                                        formik.values.items[index].data[0].info
                                          .reps
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `items.${index}.data.0.info.reps`,
                                          e.target.value
                                        )
                                        handleCalculateTotalTime(
                                          `items.${index}.data.0.info.reps`,
                                          e.target.value
                                        )
                                      }}
                                      placeholder="-"
                                      type="text"
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                              {exercise.data[0].info.type === 'cardio' ? (
                                <div>
                                  <Label>
                                    {t(
                                      'quickaccess:add-workout.avg_heart_rate'
                                    )}
                                  </Label>
                                  <Input
                                    id="avg_heart_rate"
                                    name={`items.${index}.data.0.info.avg_heart_rate`}
                                    value={
                                      formik.values.items[index].data[0].info
                                        .avg_heart_rate
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        `items.${index}.data.0.info.avg_heart_rate`,
                                        e.target.value
                                      )
                                    }
                                    placeholder="-"
                                    type="text"
                                  />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <Label>
                                      {t('quickaccess:add-workout.tempo')}
                                    </Label>
                                    <Input
                                      id="tempo"
                                      name={`items.${index}.data.0.info.tempo`}
                                      value={
                                        formik.values.items[index].data[0].info
                                          .tempo
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `items.${index}.data.0.info.tempo`,
                                          e.target.value
                                        )
                                        handleCalculateTotalTime(
                                          `items.${index}.data.0.info.tempo`,
                                          e.target.value
                                        )
                                      }}
                                      placeholder="-"
                                      type="text"
                                    />
                                  </div>

                                  <div className="qa-add-workout__ml-10">
                                    <Label>
                                      {t(
                                        'quickaccess:add-workout.rest_interval'
                                      )}
                                    </Label>
                                    <TimeInput
                                      id={'rest_interval_input'}
                                      name={`items.${index}.data.0.info.rest_interval`}
                                      placeholder="-"
                                      value={
                                        formik.values.items[index].data[0].info
                                          .rest_interval
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `items.${index}.data.0.info.rest_interval`,
                                          e.target.value
                                        )
                                        handleCalculateTotalTime(
                                          `items.${index}.data.0.info.rest_interval`,
                                          e.target.value
                                        )
                                      }}
                                      format="mm:ss"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            {exercise.data[0].info.type != 'cardio' && (
                              <div
                                className={`qa-add-workout__w-100  qa-add-workout__mt-10`}
                              >
                                <Label>
                                  {t('quickaccess:add-workout.link')}
                                </Label>
                                <Field
                                  className="qa-add-workout__formik-input ant-input input__input"
                                  name={`items.${index}.data.0.link`}
                                  placeholder="https://"
                                  type="text"
                                />
                              </div>
                            )}
                            <div className="qa-add-workout__form-group qa-add-workout__flex-form-group">
                              <Textarea
                                id="Exercise-notes"
                                label="Notes"
                                placeholder="You can add your notes here..."
                                value={
                                  formik.values.items[index].data[0].info?.notes
                                }
                                rows={
                                  formik.values.items[
                                    index
                                  ].data[0].info?.notes?.search('\n') >= 0
                                    ? 4
                                    : 1
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `items.${index}.data.0.info.notes`,
                                    e.target.value
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  <button
                    type="button"
                    className="qa-add-workout__add-another-button"
                    onClick={() => {
                      arrayHelpers.push(instanceOfExercise)
                    }}
                  >
                    <AddIcon />{' '}
                    <span>
                      {t('quickaccess:add-workout.add_another_exercise')}
                    </span>
                  </button>

                  <div className={`qa-add-workout__form-group`}>
                    <button
                      type="button"
                      className="qa-add-workout__add-another-button"
                      onClick={() => {
                        arrayHelpers.push(instanceOfSuperset)
                      }}
                    >
                      <AddIcon />{' '}
                      <span>
                        {t('quickaccess:add-workout.add_another_superset')}
                      </span>
                    </button>
                  </div>
                  <div className={`qa-add-workout__form-group`}>
                    <button
                      type="button"
                      className="qa-add-workout__add-another-button"
                      onClick={() => {
                        arrayHelpers.push(instanceOfCardio)
                      }}
                    >
                      <AddIcon />{' '}
                      <span>
                        {t('quickaccess:add-workout.add_another_cardio')}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            />
          </div>

          <div className="qa-add-workout__form-group qa-add-workout__pb-20">
            <Button
              htmlType={'submit'}
              onClick={validate}
              className="qa-add-workout__w-100"
            >
              {t('quickaccess:add-workout.add_workout')}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Styles>
  )
}

export default QuickAccessAddWorkoutComponent
