/* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects'

import { toast } from '../../components/toast/toast.component'
import {
  EP_ADD_ACCOUNT,
  EP_LOGOUT,
  EP_UPDATE_PROFILE,
  EP_UPDATE_USER
} from '../../enums/api.enum'
import { Routes } from '../../enums/routes.enum'
import api from '../../managers/api.manager'
import cookieManager from '../../managers/cookie.manager'
import logger from '../../managers/logger.manager'
import notificationManager from '../../modules/notifications/notifications.manager'
import { identity } from '../../pipes/identity.pipe'
import { serverError } from '../../pipes/server-error.pipe'
import { AccountObjType, AccountType } from '../../types/account.type'
import { CallbackType } from '../../types/callback.type'
import { ProfileDataType } from '../../types/profile-data.type'
import {
  ACTION_ADD_ACCOUNT_REQUEST,
  ACTION_ADD_ACCOUNT_SUCCESS,
  ACTION_LOGOUT_REQUEST,
  ACTION_SWITCH_ACCOUNT_REQUEST,
  ACTION_SWITCH_ACCOUNT_SUCCESS,
  ACTION_UPDATE_ACCOUNT_REQUEST,
  ACTION_UPDATE_ACCOUNT_SUCCESS,
  ACTION_UPDATE_AUTH_REQUEST,
  ACTION_UPDATE_AUTH_SUCCESS,
  ACTION_UPDATE_PROFILE_REQUEST,
  ActionType
} from '../action-types'

export function* sagaAuthWatcher() {
  yield takeLatest(ACTION_LOGOUT_REQUEST, logoutWorker)
  yield takeLatest(ACTION_UPDATE_AUTH_REQUEST, updateAuthWorker)
  yield takeLatest(ACTION_SWITCH_ACCOUNT_REQUEST, switchAccountWorker)
  yield takeLatest(ACTION_ADD_ACCOUNT_REQUEST, addAccountWorker)
  yield takeLatest(ACTION_UPDATE_ACCOUNT_REQUEST, updateAccountWorker)
  yield takeLatest(ACTION_UPDATE_PROFILE_REQUEST, updateProfileWorker)
}

function* logoutWorker() {
  notificationManager.unsubscribeFromNotifications()
  yield call(logoutCall) as {}
}

async function logoutCall() {
  return api
    .post(
      EP_LOGOUT,
      {},
      {
        baseURL: process.env.REACT_APP_BASE_ALLRIGHT_URL
      }
    )
    .then(() => {
      localStorage.clear()
      cookieManager.removeAll()
      document.location.href = identity(Routes.LOGIN)
      logger.success('logout success')
    })
    .catch((error) => {
      logger.error(error)
      logger.error('Unable to logout')
    })
}
function* updateAccountWorker({
  payload
}: ActionType<ProfileDataType & CallbackType<void>>) {
  const { onSuccess, onError, ...data } = payload
  logger.info('UPDATE ACCOUNT - worker')

  try {
    const res = (yield call(() =>
      api.put(EP_UPDATE_PROFILE, payload).then((res) => res.data.data)
    )) as ProfileDataType
    yield call(() => logger.info('UPDATE ACCOUNT - response', res))
    yield put({ type: ACTION_UPDATE_ACCOUNT_SUCCESS, payload: res })
    onSuccess && onSuccess()
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
    onError && onError(serverError(e))
  }
}

function* updateAuthWorker({
  payload
}: ActionType<AccountObjType & CallbackType<void>>) {
  const { onSuccess, onError, ...data } = payload
  try {
    const res = (yield call(() =>
      api.put(EP_UPDATE_USER, data).then((res) => res.data.data)
    )) as AccountObjType
    yield put({ type: ACTION_UPDATE_AUTH_SUCCESS, payload: res })
    onSuccess && onSuccess()
  } catch (e: any) {
    logger.error(e)
    toast.show({ type: 'error', msg: serverError(e) })
    onError && onError(serverError(e))
  }
}

async function updateCall(payload: AccountObjType) {
  api.put(EP_UPDATE_USER, payload).then((res) => res.data)
}

function* switchAccountWorker({
  payload
}: ActionType<{ uuid: string } & CallbackType<void>>) {
  const { uuid, onError, onSuccess } = payload
  cookieManager.set('uuid', uuid)
  logger.info('SWITCH_ACCOUNT', 2, uuid)
  yield put({ type: ACTION_SWITCH_ACCOUNT_SUCCESS, payload: uuid })
  onSuccess && onSuccess()
}

function* addAccountWorker({
  payload
}: ActionType<{ type: string } & CallbackType<void>>) {
  const { type, onError, onSuccess } = payload
  try {
    const res = (yield call(() => addAccountCall(type))) as AccountType
    logger.success('GET NEW ACCOUNT', res)
    onSuccess && onSuccess()
    yield put({
      type: ACTION_ADD_ACCOUNT_SUCCESS,
      payload: { ...res, profile: null }
    })
  } catch (e: any) {
    logger.error(e)
    onError && onError(e)
    toast.show({ type: 'error', msg: serverError(e) })
  }
}

async function addAccountCall(type: string) {
  return api.post(EP_ADD_ACCOUNT, { type }).then((res) => res.data.data)
}

function* updateProfileWorker({
  payload
}: ActionType<
  ProfileDataType &
    AccountObjType &
    AccountType & { tnb: null | File } & CallbackType<void>
>) {}
