import moment from 'moment'
import { FC, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { BikeIcon, WorkoutIconV3 } from '../../../../assets/media/icons'
import useCalendar from '../../../../hooks/api/calendar/useCalendar'
import useTrainingPlan from '../../../../hooks/api/quick-access/useTrainingPlan'
import useTrainingPlans from '../../../../hooks/api/quick-access/useTrainingPlans'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { assignSingleWorkout } from '../../../../services/api/logging'
import {
  SET_ACTIVE_WORKOUT_DATA,
  SET_DEFAULT_ACTIVE_WORKOUT_INDEX
} from '../../../../store/action-types'
import { parseActivities } from '../../../../utils/api/calendar'
import { DATE_FORMAT } from '../../../../utils/date'
import Button from '../../../buttons/button/button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import WorkoutItem from '../../components/quick-access-log-item/quick-access-log-item.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-log-exercise.styles'

const QuickAccessLogExercise: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setRoute, clientId, client } = useQuickAccess()

  const [search, setSearch] = useState('')

  const auth = useAuth()
  const [todayDate] = useState(moment(new Date()).format(DATE_FORMAT))
  const { activities, isLoading, refetch: refetchActivities } = useCalendar({
    startDate: todayDate,
    endDate: todayDate,
    clientId: client?.id
  })

  const parsedActivities = parseActivities(activities)
  const filteredParsedActivities = parsedActivities.filter(
    (value) =>
      value.resource_type === 'workouts' ||
      value.resource_type === 'workout_items'
  )

  const { trainingPlans } = useTrainingPlans({
    clientId: client?.id,
    status: 'active'
  })

  const activeTrainingPlanId = trainingPlans?.length ? trainingPlans[0]._id : ''
  const activeRevisionId = trainingPlans?.length
    ? trainingPlans[0].revisions.find(
        (revision: any) => revision.status === 'active'
      )?._id || ''
    : ''

  const { revision } = useTrainingPlan({
    id: activeTrainingPlanId,
    revisionId: activeRevisionId,
    clientId: client?.id || auth.id
  })

  const workouts = revision?.activities || []
  const options = useMemo(() => {
    return (
      workouts
        ?.filter((workout: any) =>
          workout.name.toLowerCase().includes(search.toLowerCase())
        )
        .map((workout: any) => ({
          value: workout.name,
          label: workout.name
        })) || []
    )
  }, [search])

  const otherWorkoutsInTrainingPlan = useMemo(() => {
 
    const revisionData = revision.activities
    const newRevisionData: any[] = revisionData?.filter(
      (workout: any) => {
        if (
          filteredParsedActivities?.find(
            (item: any) => item.resource._id == workout._id
          ) ||
          !workout.items.length
        ) {
          return false
        }
        return true
      }
    )

    return newRevisionData
  }, [filteredParsedActivities, revision])

  const handleAssignWorkout = async (item: any) => {
    const isCardio = !item.items.find(
      (item: any) => item.data?.info?.type !== 'cardio'
    )
    const data: any = {}
    data['date'] = moment(new Date()).format(DATE_FORMAT)
    data['resource_type'] = isCardio ? 'workout_items' : 'workouts'
    data['resource'] = [item]
    data['multiple_resources'] = false
    data['_id'] = item._id
    const res = await assignSingleWorkout(
      client?.id ? client.id : auth.id,
      data,
      data.date
    )
    refetchActivities()
    revision.refetch()
    if (res) {
      toast.show({ type: 'success', msg: 'Workout successfully added' })
    }
  }
  
  return (
    <Styles>
      <QuickAccessBack label={'log'} route={quickAccessRoutes.LOG} />

      <h3>{t('quickaccess:log-exercise.title')}</h3>

      <AutoCompleteInput
        id="exercises-search"
        placeholder={t('quickaccess:log-exercise.search-placeholder')}
        className="qa-log-exercise__search"
        options={options}
        value={search}
        onChange={(value) => setSearch(value)}
      />

      <h4>{t('quickaccess:log-exercise.today-exercises')}</h4>
      {isLoading ? (
        <div className="qa-log-exercise__loading">
          <h4>Loading...</h4>
        </div>
      ) : (
        filteredParsedActivities.map((workout: any) => (
          <WorkoutItem
            key={workout.resource._id}
            name={workout.resource.name || workout.resource.data.name}
            completed={
              workout.status === 'everything_logged' ||
              workout.status === 'not_everything_logged'
            }
            Icon={
              workout.resource_type === 'workout_items'
                ? BikeIcon
                : WorkoutIconV3
            }
            iconColor={
              workout.resource_type === 'workout_items' ? '#EF1733' : '#E49A0A'
            }
            amount={
              workout.resource_type === 'workout_items'
                ? '1 exercise'
                : `${workout?.resource?.items?.length} exercise${
                    workout?.resource?.items?.length > 1 ? 's' : ''
                  }`
            }
            onClick={() => {
              dispatch({ type: SET_DEFAULT_ACTIVE_WORKOUT_INDEX })
              if (workout.resource_type === 'workout_items') {
                setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO, {
                  name: workout.resource.data.name,
                  workoutId: workout._id,
                  id: workout.resource._id,
                  exercise: workout.resource,
                  workoutDate: workout.date,
                  logData: workout.resource.log,
                  withinWorkout: 'false',
                  isLoggedAll: workout.status
                })
              } else {
                dispatch({
                  type: SET_ACTIVE_WORKOUT_DATA,
                  payload: {
                    id: workout._id,
                    name: workout.resource.name,
                    date: workout.date,
                    logData: workout.log
                  }
                })
                setRoute(quickAccessRoutes.WORKOUT_OVERVIEW)
              }
            }}
          />
        ))
      )}

      {
        otherWorkoutsInTrainingPlan?.length > 0 &&
        <div style={{ marginTop: "2rem"}}>
          <h4>Other Workouts</h4>
          {
            otherWorkoutsInTrainingPlan?.map((workout: any) => (
              <WorkoutItem
                key={workout._id}
                name={workout.name || workout.data.name}
                completed={false}
                isAddable={true}
                Icon={
                  workout?.resource_type === 'workout_items'
                    ? BikeIcon
                    : WorkoutIconV3
                }
                iconColor={
                  workout?.resource_type === 'workout_items' ? '#EF1733' : '#E49A0A'
                }
                amount={
                  workout.resource_type === 'workout_items'
                    ? '1 exercise'
                    : `${workout?.items?.length} exercise${
                        workout?.items?.length > 1 ? 's' : ''
                      }`
                }
                onClick={() => handleAssignWorkout(workout)}
              />
            ))
          }
        </div>
      }

      <Button
        variant="text"
        className="qa-log-exercise__button"
        size="sm"
        onClick={() => setRoute(quickAccessRoutes.ADD_WORKOUT)}
      >
        {t('quickaccess:log-exercise.add-new')}
      </Button>
    </Styles>
  )
}

export default QuickAccessLogExercise
