import Input from '../../../../components/form/input/input.component'
import Styles from './supplement.styles'

interface SupplementComponentProps {
  meal: any
  index: number
}

const SupplementViewComponent = ({ meal, index }: SupplementComponentProps) => {
  return (
    <Styles>
      <div className="supplement mb-20">
        <Input
          id="supplement-name"
          label="Supplement Name"
          placeholder="-"
          value={meal.supplements[index].name}
          disabled={true}
          readOnly={true}
        />
        <Input
          id="supplement-description"
          label="Description"
          placeholder="-"
          value={meal.supplements[index].info.description}
          disabled={true}
          readOnly={true}
        />
        <Input
          id="supplement-qty"
          label="Qty"
          placeholder="-"
          value={meal.supplements[index].info.grams}
          disabled={true}
          readOnly={true}
        />
      </div>
    </Styles>
  )
}

export default SupplementViewComponent
