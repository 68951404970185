import styled from 'styled-components'

import Card from '../../../../components/cards/card/card.component'
import { mediaQueries } from '../../../../enums/screen-sizes.enum'
import { getColor, getColorCarry } from '../../../../pipes/theme-color.pipe'

export const Styles = styled(Card)<any>`
  padding: 0;
  margin-bottom: 1rem;
  border-radius: 15px;
  &:last-child {
    margin-bottom: 1.25rem;
  }
  .checkIcon {
    margin-left: 8px;
    color: #3fc9ad;
  }
  .CaretDownIconColor {
    color: ${(props) =>
      props.$status === 'nothing_logged' ? '#757575' : null};
  }
  .DayAccordion {
    &__summary {
      padding: 1.5rem 1.875rem;
      display: flex;
      align-items: center;
      background-color: ${(props) =>
        props.$status == 'nothing_logged'
          ? '#2E2F31 !important'
          : 'white !important'};
      border-bottom: ${(props) =>
        props.$open ? '1px solid #e0e0e0' : 'unset'};
      justify-content: space-between;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: ${({ $open }) => (!$open ? '8px' : 'unset')};
      border-bottom-left-radius: ${({ $open }) => (!$open ? '8px' : 'unset')};

      @media ${mediaQueries.TABLET} {
        padding: 1rem;
      }
      &-icon {
        width: 34px;
        height: 34px;
        min-width: 34px;
        min-height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        background-color: ${(props: any) => props.$iconColor};
        margin-right: 1rem;
        color: ${(props: any) => (props.$status ? '#90BF45' : '#fff')};
        & svg {
          width: ${(props: any) => (props.$status ? '30px' : '20px')};
          height: ${(props: any) => (props.$status ? '30px' : '20px')};
          * {
            fill: 'inherit'; //${({ $iconFill }) => $iconFill ?? 'inherit'};
          }
        }
      }
      &-title {
        font-size: 1rem;
        font-weight: 700;
        color: ${(props: any) =>
          props.$error
            ? getColor(props, 'red')
            : props.$status === 'nothing_logged'
            ? '#FFFFFF'
            : getColor(props, 'primaryDark_v2')};

        &-container {
          display: flex;
          align-items: center;
        }
      }
      &-time {
        color: ${getColorCarry('neutral_70')};
      }

      &-btn {
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
          transform: ${(props) => (props.$open ? 'rotate(180deg)' : 'none')};
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__delete-btn {
      width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${getColorCarry('red')};
      margin-right: 0.5rem;
    }

    &__content {
      padding: 0 1.875rem 1.5rem 1.875rem;

      @media ${mediaQueries.TABLET} {
        padding: 1rem;
      }
    }
  }
`
