/* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function,require-yield */
import { call, put } from 'redux-saga/effects'

import { CallbackType } from '../../types/callback.type'
import { ProfileDataType } from '../../types/profile-data.type'
import { ACTION_UPDATE_ACCOUNT_SUCCESS, ActionType } from '../action-types'

export function* sagaProfileWatcher() {}

function* updateProfileAction(
  action: ActionType<ProfileDataType & CallbackType<void>>
) {
  const { onSuccess, onError, ...data } = action.payload
  yield put({ type: ACTION_UPDATE_ACCOUNT_SUCCESS, payload: data })
  yield call(() => onSuccess && onSuccess())
}

function* getProfileAction() {}

async function getProfileCall() {}
