import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'

import { TimerIcon } from '../../../../assets/media/icons'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { getColorCarry } from '../../../../pipes/theme-color.pipe'
import { AddLogToActivityOfTheCalendar } from '../../../../services/api/logging'
import { INCREASE_ACTIVE_WORKOUT_INDEX } from '../../../../store/action-types'
import { RootState } from '../../../../store/reducers'
import {
  formatCardioWithoutWorkoutPayload,
  formatLogCardioPayload
} from '../../../../utils/api/logging/add'
import { TIME_FORMAT } from '../../../../utils/date'
import Button from '../../../buttons/button/button.component'
import Select from '../../../form/select/select.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import QuickAccessProgressBar from '../../components/quick-access-progress-bar/quick-access-progress-bar.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-logging-cardio.styles'

interface Props {}

const QuickAccessLoggingCardio: FC<Props> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { routeParams, setRoute, workoutProgress } = useQuickAccess()
  const { workoutItems, activeWorkoutIndex, activeWorkoutData } = useSelector(
    (state: RootState) => state.logWorkout
  )
  useEffect(() => {
    if (workoutItems[activeWorkoutIndex]?.log?.was_skipped) {
      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
      workoutItems.length - 1 === activeWorkoutIndex || !withinWorkout
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
    }
  }, [activeWorkoutIndex])
  const { exercise }: any = routeParams
  const [timerStarted, setTimerStarted] = useState(false)
  const isEverythingLogged =
    exercise.status === 'everything_logged' ||
    routeParams.isLoggedAll === 'everything_logged'

  const [selectIntensity, setSelectIntensity] = useState(
    exercise.log?.info?.intensity || exercise?.data?.info?.intensity
  )

  const withinWorkout = routeParams.withinWorkout === 'true'
  const exerciseDuration = exercise.data.info.duration + ':00'
  const time = new Date()
  const notLoggedTime = moment
    .utc(
      moment
        .duration(exerciseDuration)
        .subtract(moment.duration(exercise.log?.info?.duration))
        .as('milliseconds')
    )
    .format(TIME_FORMAT)

  const execIntervalHoursInSeconds = +notLoggedTime?.split(':')[0] * 3600
  const execIntervalMinutesInSeconds = +notLoggedTime?.split(':')[1] * 60
  const execIntervalSeconds = +notLoggedTime?.split(':')[2]

  time.setSeconds(
    isEverythingLogged
      ? 0
      : time.getSeconds() +
          (execIntervalHoursInSeconds +
            execIntervalMinutesInSeconds +
            execIntervalSeconds)
  )

  const {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    restart,
    pause: pauseTimer,
    resume: resumeTimer
  } = useTimer({
    expiryTimestamp: time,
    autoStart: false,
    onExpire: async () => {
      const logPayload =
        routeParams.withinWorkout === 'false'
          ? formatCardioWithoutWorkoutPayload(
              exercise.data.info.duration,
              selectIntensity,
              +exercise?.data.info.avg_heart_rate,
              false,
              true
            )
          : formatLogCardioPayload(
              exercise._id,
              exercise.data.info.duration,
              selectIntensity,
              +exercise?.data.info.avg_heart_rate,
              false,
              true
            )
      const response = await AddLogToActivityOfTheCalendar(
        routeParams.workoutDate,
        routeParams.workoutId,
        logPayload
      )

      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
      if (response) {
        toast.show({
          type: 'success',
          msg: 'Exercise successfully fully logged'
        })
      }
      workoutItems.length - 1 === activeWorkoutIndex || !withinWorkout
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
      console.warn('onExpired called')
    }
  })

  useEffect(() => {
    if (exercise?.log?.was_skipped) {
      workoutItems.length - 1 === activeWorkoutIndex || !withinWorkout
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
      toast.show({
        type: 'success',
        msg: 'Everything is logged'
      })
    }
  }, [])

  useEffect(() => {
    const time = new Date()
    const notLoggedTime = moment
      .utc(
        moment
          .duration(exerciseDuration)
          .subtract(moment.duration(exercise.log?.info?.duration))
          .as('milliseconds')
      )
      .format(TIME_FORMAT)

    const execIntervalHoursInSeconds = +notLoggedTime?.split(':')[0] * 3600
    const execIntervalMinutesInSeconds = +notLoggedTime?.split(':')[1] * 60
    const execIntervalSeconds = +notLoggedTime?.split(':')[2]

    time.setSeconds(
      isEverythingLogged
        ? 0
        : time.getSeconds() +
            (execIntervalHoursInSeconds +
              execIntervalMinutesInSeconds +
              execIntervalSeconds)
    )
    restart(time, false)
  }, [exercise])

  useEffect(() => {
    setSelectIntensity(
      exercise.log?.info?.intensity || exercise?.data?.info?.intensity
    )
  }, [exercise])

  const navigateToNextLink = () => {
    if (workoutItems[activeWorkoutIndex + 1].data.length) {
      setRoute(quickAccessRoutes.WORKOUT_LOG_SUPERSET, {
        name: workoutItems[activeWorkoutIndex + 1].workOutId.name,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        exercise: workoutItems[activeWorkoutIndex + 1],
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        logData: workoutItems[activeWorkoutIndex + 1].log,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    } else if (
      workoutItems[activeWorkoutIndex + 1].data.info.type === 'cardio'
    ) {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_CARDIO, {
        id: activeWorkoutData.id,
        name: workoutItems[activeWorkoutIndex + 1].name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1].log,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        withinWorkout: 'true',
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    } else {
      setRoute(quickAccessRoutes.WORKOUT_LOGGING_STRENGTH, {
        id: activeWorkoutData.id,
        name: workoutItems[activeWorkoutIndex + 1].name,
        exercise: workoutItems[activeWorkoutIndex + 1],
        logData: workoutItems[activeWorkoutIndex + 1].log,
        workoutId: workoutItems[activeWorkoutIndex + 1].workOutId,
        workoutDate: workoutItems[activeWorkoutIndex + 1].date,
        lengthOfWorkout: routeParams.lengthOfWorkout,
        workout: routeParams.workout
      })
    }
  }

  const startTimer = () => {
    if (isEverythingLogged) {
      workoutItems.length - 1 === activeWorkoutIndex ||
      routeParams.withinWorkout === 'false'
        ? setRoute(quickAccessRoutes.LOG_EXERCISE)
        : navigateToNextLink()
      return
    }
    if (!timerStarted) {
      start()
      setTimerStarted(true)
    } else {
      resumeTimer()
    }
  }

  const postDuration = async (wasSkipped = false) => {
    const duration = moment
      .utc(
        moment
          .duration(exercise.data.info.duration + ':00')
          .subtract(moment.duration([hours, minutes, seconds].join(':')))
          .as('milliseconds')
      )
      .format(TIME_FORMAT)

    const logPayload =
      routeParams.withinWorkout === 'false'
        ? formatCardioWithoutWorkoutPayload(
            duration,
            selectIntensity,
            +exercise?.data.info.avg_heart_rate,
            wasSkipped
          )
        : formatLogCardioPayload(
            exercise._id,
            duration,
            selectIntensity,
            +exercise?.data.info.avg_heart_rate,
            wasSkipped
          )
    const response = await AddLogToActivityOfTheCalendar(
      routeParams.workoutDate,
      routeParams.workoutId,
      logPayload
    )

    if (response) {
      toast.show({
        type: 'success',
        msg: `Exercise successfully ${wasSkipped ? 'skipped' : 'logged'}`
      })
    }
  }

  const onMarkCompleted = async (showMessage = true) => {
    if (timerStarted) {
      await onStopTimer()
      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
    } else {
      const logPayload =
        routeParams.withinWorkout === 'false'
          ? formatCardioWithoutWorkoutPayload(
              exercise.data.info.duration,
              selectIntensity,
              +exercise?.data.info.avg_heart_rate,
              false,
              true
            )
          : formatLogCardioPayload(
              exercise._id,
              exercise.data.info.duration,
              selectIntensity,
              +exercise?.data.info.avg_heart_rate,
              false,
              true
            )
      const response = await AddLogToActivityOfTheCalendar(
        routeParams.workoutDate,
        routeParams.workoutId,
        logPayload
      )
      dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
      if (showMessage) {
        if (response) {
          toast.show({
            type: 'success',
            msg: 'Exercise successfully fully logged'
          })
        }
      }
    }
    workoutItems.length - 1 === activeWorkoutIndex || !withinWorkout
      ? setRoute(quickAccessRoutes.LOG_EXERCISE)
      : navigateToNextLink()
  }

  const onStopTimer = async (stopTimer = true, wasSkipped = false) => {
    stopTimer && pauseTimer()
    stopTimer && postDuration(wasSkipped)
  }

  const formatTime = (hours: number, minutes: number, seconds: number) => {
    return `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const renderButtonText = () => {
    if (isEverythingLogged) {
      return 'Next Exercise'
    }
    if (!timerStarted && !isRunning) {
      return 'Start'
    }
    if (timerStarted && isRunning) {
      return 'Stop'
    }
    if (timerStarted && !isRunning) {
      return 'Start'
    }
  }

  return (
    <Styles>
      <div className="qa-logging-cardio__container">
        <QuickAccessBack
          label="list"
          route={quickAccessRoutes.LOG_EXERCISE}
          color={getColorCarry('neutral_50')}
        />
        <div className="qa-logging-cardio__header">
          <h3>{exercise?.data.name}</h3>
          <QuickAccessProgressBar percent={workoutProgress} />
        </div>

        <h2>{exercise?.data.name}</h2>
        <div className="qa-logging-cardio__timer-background">
          <span className="qa-logging-cardio__timer">
            <span className="qa-logging-cardio_time">
              {formatTime(hours, minutes, seconds)}
            </span>
            <TimerIcon />
          </span>
        </div>

        <div className="qa-logging-cardio__intensity">
          <Select
            id="cardio-intensity"
            value={selectIntensity}
            onChange={(value) => setSelectIntensity(value)}
            options={t('quickaccess:intensity-options')}
          />
          <span> {t('quickaccess:logging-cardio.intensity')}</span>
        </div>

        <div className="qa-logging-cardio__button-group">
          <Button
            variant="dark"
            className="qa-logging-cardio__btn-mark-completed"
            onClick={onMarkCompleted}
          >
            {t('quickaccess:logging-cardio.mark-completed-btn')}
          </Button>
          <Button
            onClick={() => {
              onStopTimer(true, true)
              dispatch({ type: INCREASE_ACTIVE_WORKOUT_INDEX })
              workoutItems.length - 1 === activeWorkoutIndex || !withinWorkout
                ? setRoute(quickAccessRoutes.LOG_EXERCISE)
                : navigateToNextLink()
            }}
            variant={`light`}
          >
            Skip
          </Button>
          <Button onClick={isRunning ? onStopTimer : startTimer}>
            {renderButtonText()}
          </Button>
        </div>
      </div>
    </Styles>
  )
}

export default QuickAccessLoggingCardio
