export enum quickAccessRoutes {
  LOG,
  ADD,
  ADD_EXERCISE,
  ADD_WORKOUT,
  ADD_MEAL,
  ADD_FOOD,
  ADD_SCHEDULE,
  ADD_INVOICE,
  LOG_EXERCISE,
  LOG_MEAL,
  LOG_WORKOUT,
  LOG_HEALTH_DATA,
  LOG_MEASUREMENT,
  LOG_HEALTH_DATA_STEPS,
  LOG_HEALTH_DATA_HEART_RATE,
  LOG_HEALTH_DATA_SLEEP,
  LOG_HEALTH_DATA_GLUCOSE,
  WORKOUT_OVERVIEW,
  WORKOUT_OVERVIEW_ADD_EXERCISE,
  WORKOUT_LOGGING_CARDIO,
  WORKOUT_LOGGING_STRENGTH,
  WORKOUT_LOG_SUPERSET,
  WORKOUT_LOGGING_SUPERSET,
  MEAL_OVERVIEW
}
