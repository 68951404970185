import moment from 'moment'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { MealIconV3 } from '../../../../assets/media/icons'
import useCalendar from '../../../../hooks/api/calendar/useCalendar'
import { useAuth } from '../../../../hooks/auth.hook'
import { useTranslation } from '../../../../modules/i18n/i18n.hook'
import { formatData } from '../../../../pages/logging/meals/main.component'
import { AssignDietPlanActivities } from '../../../../services/api/logging'
import getMeals from '../../../../services/api/logging/meals'
import { parseActivities } from '../../../../utils/api/calendar'
import { DATE_FORMAT } from '../../../../utils/date'
import Button from '../../../buttons/button/button.component'
import AutoCompleteInput from '../../../form/autoCompleteInput/autoCompleteInput.component'
import { toast } from '../../../toast/toast.component'
import QuickAccessBack from '../../components/quick-access-back/quick-access-back.component'
import MealItem from '../../components/quick-access-log-item/quick-access-log-item.component'
import { useQuickAccess } from '../../quick-access.context'
import { quickAccessRoutes } from '../../quick-access.routes'
import Styles from './quick-access-log-meal.styles'

const QuickAccessLogMeal: FC = () => {
  const { t } = useTranslation()
  const { setRoute, clientId, client } = useQuickAccess()
  const { id: userId } = useAuth()

  const [search, setSearch] = useState('')
  const [todayDate] = useState(moment(new Date()).format(DATE_FORMAT))
// console.log({clientId, client})
  const { activities, refetch: refetchActivities } = useCalendar({
    startDate: todayDate,
    endDate: todayDate,
    clientId: client?.id
  })
  const parsedActivities = parseActivities(activities)
  const filteredParsedActivities = parsedActivities.filter(
    (value) => value.resource_type === 'meals'
  )

  const { data, refetch: refetchMeals } = getMeals({ userId: client?.id || userId })
  const dataFromActivities: any = formatData(data, todayDate)
  const [meals, setMeals] = useState([])
  const dataFetcher = useCallback(() => {
    setMeals(
      filteredParsedActivities ? filteredParsedActivities : dataFromActivities
    )
  }, [activities])

  useEffect(() => {
    dataFetcher()
  }, [dataFetcher])

  const options = useMemo(() => {
    return (
      [
        { value: 'Lunch', label: 'Lunch' },
        { value: 'Dinner', label: 'Dinner' }
      ].filter((meal: any) =>
        meal.label.toLowerCase().includes(search.toLowerCase())
      ) || []
    )
  }, [search])
  
  const otherMealsInDietPlan = useMemo(() => {
 
    const revisionData = data
    const newRevisionData: {[key: string]: any[]} = {}

    revisionData?.forEach((dayMeals: any) => {
      const newMeals = dayMeals.activities.filter((meal: any) => {
        if (
          filteredParsedActivities?.find(
            (item: any) => item.resource._id == meal._id
          ) ||
          !meal.items.length
        ) {
          return false
        }
        return true
      })

      newRevisionData[dayMeals.name] = newMeals
    })

    return newRevisionData
  }, [filteredParsedActivities, data])

  const handleAssignMeal = async (item: any) => {
    const data: any = {}
    data['date'] = moment(new Date()).format(DATE_FORMAT)
    data['resource_type'] = 'meals'
    data['resource'] = [item]
    data['multiple_resources'] = false
    data['_id'] = item._id
    const res = await AssignDietPlanActivities(
      client?.id ? client.id : userId,
      data,
      data.date,
      ''
    )
    refetchMeals()
    refetchActivities()
    if (res) {
      toast.show({ type: 'success', msg: 'Activity successfully added' })
    }
  }

  return (
    <Styles>
      <QuickAccessBack label={'log'} route={quickAccessRoutes.LOG} />

      <h3>{t('quickaccess:log-meal.title')}</h3>

      <AutoCompleteInput
        id="meals-search"
        placeholder={t('quickaccess:log-meal.search-placeholder')}
        // prefix={<SearchIcon />}
        options={options}
        value={search}
        onChange={(value) => setSearch(value)}
        className="qa-log-meal__search"
      />

      <h4>{t('quickaccess:log-meal.today-exercises')}</h4>
      {meals?.length ? (
        meals?.map((meal: any) => (
          <MealItem
            key={meal?.resource?.name}
            name={meal?.resource?.name}
            Icon={MealIconV3}
            iconColor={'#90BF45'}
            completed={meal.status === 'everything_logged'}
            amount={`${meal?.resource?.items?.length} Food${
              meal?.resource?.items?.length > 1 ? 's' : ''
            }`}
            onClick={() =>
              setRoute(quickAccessRoutes.MEAL_OVERVIEW, {
                id: meal?._id,
                meal: meal,
                name: meal?.resource?.name,
                initialData: JSON.parse(JSON.stringify(meal?.resource))
              })
            }
          />
        ))
      ) : (
        <h3 className={`qa-log-meal__empty-text`}>No any meals</h3>
      )}

      {
        Object.values(otherMealsInDietPlan).find((meals: any[]) => meals.length > 0) &&
        <div style={{ marginTop: "2rem" }}>
          <h4>Other Meals</h4>
          {Object.entries(otherMealsInDietPlan).map((meals, i) => (
              meals[1].length ?
              <div key={i}>
                <div className='meals-page__other-meals-day-title'>{meals[0]}</div>
                {meals[1].map((meal, idx) => (
                  <MealItem
                    key={meal?.name}
                    name={meal?.name}
                    isAddable
                    Icon={MealIconV3}
                    iconColor={'#90BF45'}
                    completed={false}
                    amount={`${meal?.items?.length} Food${
                      meal?.items?.length > 1 ? 's' : ''
                    }`}
                    onClick={() => handleAssignMeal(meal)}
                  />
                ))}
              </div>
              : <></>
          ))}
        </div>
      }

      <Button
        variant="text"
        className="qa-log-meal__button"
        size="sm"
        onClick={() =>
          setRoute(quickAccessRoutes.ADD_MEAL, { parentRoute: 'log-meal' })
        }
      >
        {t('quickaccess:log-meal.add-new')}
      </Button>
    </Styles>
  )
}

export default QuickAccessLogMeal
