import styled from 'styled-components'

import { getColorCarry } from '../../../../../pipes/theme-color.pipe'

export default styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: ${getColorCarry('neutral_30')};

  padding: 1.4rem 2rem 1.1rem 1.5rem;
  margin: 0.5rem 0;
  cursor: pointer;

  h3 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: normal;
  }

  p {
    color ${getColorCarry('neutral_60')};
  }
   .container {
        display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%
    }
  .qa-log-item {
    &__icon {
      height: 27px;
      width: 27px;
      filter: invert(85%) sepia(9%) saturate(2555%) hue-rotate(32deg) brightness(83%) contrast(89%);
    }

    &__checkIcon {
        flex-grow: 1;
        text-align: right;
        svg {
            color: #3FC9AD;
        }
    }
  }
`
