import styled from 'styled-components'

export default styled.div`
  .qa-add {
    &__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: fit-content;
      margin: 0 auto;
      column-gap: 17px;
    }
  }
`
