import moment from 'moment'

import api from '../../managers/api.manager'
import { DATE_FORMAT } from '../../utils/date'

export async function getCalendar(url: string) {
  const response = await api.get(url)
  return response.data.data
}

export async function addEvent(data: any) {
  const response = await api.post('/events', data)
  return response.data.data
}
export async function addActivity(data: any) {
  const currentDate = data['date'] ?? moment(new Date()).format(DATE_FORMAT)

  const response = await api.post(
    `/calendar/date/${currentDate}/activities`,
    data
  )
  return response.data.data
}
export async function deleteEvent(event: any, userId: any) {
  const response = await api.delete(
    `/calendar/date/${event?.date}/activities/${event?._id}?account_id=${userId}`,
    event
  )
  return response.data.data
}
