import styled from 'styled-components'

import { getColorCarry } from '../../pipes/theme-color.pipe'

export const ClientsStyles = styled.div<{ isCollapsed: boolean }>`
  .ant-tooltip-placement-rightTop {
    top: 10px;
  }

  background-color: ${getColorCarry('background_v3')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  z-index: 40;
  top: 0;
  flex-shrink: 0;
  border-right: 1px solid ${(p) => p.theme.vars.colors.secondary2};
  width: ${({ isCollapsed }) => (isCollapsed ? '82px' : '220px')};
  padding: 1.125rem 1.125rem calc(1.125rem + 88px) 1.125rem;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  // transition: width 0.1s ease-in;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only print {
    display: none;
  }

  .sidebar {
    // Dark trainer badge
    &__trainer {
      margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '2.25rem' : '2rem')};
      background-color: ${({ isCollapsed }) =>
        isCollapsed ? 'transparent' : '#2E2F31'};
      padding: ${({ isCollapsed }) => (isCollapsed ? '0 0 0 1px' : '')};

      // Div that contains the name and subtitle
      > div:last-child {
        // Name
        > p {
          color: ${getColorCarry('white')};
        }

        // Subtitle
        > div {
          color: ${getColorCarry('neutral_30')};
        }
      }
    }

    &__collapsed {
      width: 82px;
    }

    &__logo {
      display: flex;
      justify-content: center;

      & svg {
        width: 190px;

        /* Make the letters white */
        & > path:nth-last-child(n + 3) {
          fill: ${getColorCarry('neutral_10')};
        }
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: ${getColorCarry('inputBorder_v3')};
      opacity: 0.5;

      &_spacing {
        margin-bottom: 2rem;
      }
    }

    &__nav-spacer {
    }

    &__nav {
      display: flex;
      flex-direction: column;
    }

    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: ${({ isCollapsed }) => (isCollapsed ? '44px' : '100%')};
      height: ${({ isCollapsed }) => (isCollapsed ? '42px' : '40px')};
      display: flex;
      align-items: center;
      font-weight: 400;
      border-radius: 10px;
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
      color: ${getColorCarry('white')};
      padding: 0 0.75rem;
      transition: none;
      position: relative;
      ${({ isCollapsed }) => (isCollapsed ? 'padding: 0' : '')};

      &-displayName {
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'inline')};
      }

      & svg {
        width: 22px;
        height: 22px;
        ${({ isCollapsed }) =>
          isCollapsed ? 'margin: 0 auto;' : 'margin-right: 1.375rem;'}
      }

      > * {
        opacity: 0.8;
      }

      > svg {
        opacity: 0.55 !important;
      }

      &_active {
        background-color: ${getColorCarry('red_n')};
        color: #fff;
        > * {
          opacity: 1;
        }

        > svg {
          opacity: 1 !important;
        }

        > span {
          font-weight: 700;
        }

        &:hover {
          background-color: ${getColorCarry('red_n')} !important;
        }
      }

      &:hover {
        //background-color: ${getColorCarry('primary_v2')};
        background-color: ${getColorCarry('neutral_100')};
        color: #fff;
      }

      &-unreadDot {
        height: 10px;
        width: 10px;
        background-color: ${getColorCarry('primary_v2')};
        opacity: 1;
        border-radius: 50%;
        border: 1.3px solid #0c0d0d;
        position: absolute;
        top: 8px;
        left: 24px;
      }
    }

    &__item:nth-child(3) {
      margin-top: 0.5rem;
    }
  }
`
export const TrainerStyles = styled.aside<{ isCollapsed: boolean }>`
  background: linear-gradient(#196a73, #00363c);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  z-index: 50;
  top: 0;
  flex-shrink: 0;
  border-right: 1px solid ${(p) => p.theme.vars.colors.secondary2};
  width: ${({ isCollapsed }) => (isCollapsed ? '82px' : '220px')};
  padding: 1.125rem 1.125rem calc(1.125rem + 88px) 1.125rem;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  // transition: width 0.1s ease-in;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only print {
    display: none;
  }

  .sidebar {
    &__trainer {
      margin-bottom: 2rem;
    }

    &__logo {
      display: flex;
      justify-content: center;

      ${({ isCollapsed }) =>
        isCollapsed ? 'position: relative; right: 12px; width: 70px;' : ''}

      & svg {
        width: 190px;
      }
    }

    &__nav-spacer {
    }

    &__nav {
      display: flex;
      flex-direction: column;
    }

    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0.75rem;
      color: rgba(255, 255, 255, 0.7);
      padding: 0 0.75rem;
      transition: ${(p) => p.theme.vars.defaults.transition};
      position: relative;
      ${({ isCollapsed }) => (isCollapsed ? 'padding: 0' : '')};

      &-displayName {
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'inline')};
      }

      &-icon__wrapper {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.5rem')};

        svg {
          width: 22px;
          height: 22px;
          fill: rgba(255, 255, 255, 0.7);
        }
      }

      &_active {
        font-weight: 700;
        color: #fff;
        .sidebar__item-icon__wrapper {
          background: #3fc9ad;
          border-radius: 10px;
        }
        & svg {
          fill: #fff;
        }
      }

      &:hover {
        .sidebar__item-icon__wrapper {
          background: #3fc9ad;
          border-radius: 10px;
        }
        font-weight: 700;
        color: #fff;
        .sidebar__item-icon__wrapper {
          svg {
            fill: #fff;
          }
        }
      }

      &-unreadDot {
        height: 10px;
        width: 10px;
        background-color: #ef1733;
        opacity: 1;
        border-radius: 50%;
        border: 1.3px solid #00363c;
        position: absolute;
        top: 8px;
        left: 34px;
      }
    }
  }
`

export const OrgStyles = styled.aside<{ isCollapsed: boolean }>`
  background-color: ${getColorCarry('background_v3')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  z-index: 50;
  top: 0;
  flex-shrink: 0;
  border-right: 1px solid ${(p) => p.theme.vars.colors.secondary2};
  width: ${({ isCollapsed }) => (isCollapsed ? '82px' : '220px')};
  padding: 1.125rem 1.125rem calc(1.125rem + 88px) 1.125rem;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  // transition: width 0.1s ease-in;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only print {
    display: none;
  }

  .sidebar {
    &__trainer {
      margin-bottom: 2rem;
    }

    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      position: relative;
      right: 24px;

      ${({ isCollapsed }) =>
        isCollapsed ? 'position: relative; right: 12px; width: 70px;' : ''}

      & svg {
        width: 118px;
      }

      span {
        font-weight: 450;
        font-size: ${({ isCollapsed }) => (isCollapsed ? '10px' : '12px')};
        line-height: 16px;
        color: #3fc9ad;
        position: absolute;
        top: ${({ isCollapsed }) => (isCollapsed ? '45px' : '36px')};
        right: ${({ isCollapsed }) => (isCollapsed ? '12px' : '-2px')};
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: ${getColorCarry('inputBorder_v3')};
      opacity: 0.5;

      &_spacing {
        margin-bottom: 2rem;
      }
    }

    &__nav-spacer {
    }

    &__nav {
      display: flex;
      flex-direction: column;
    }

    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0.75rem;
      color: rgba(255, 255, 255, 0.7);
      padding: 0 0.75rem;
      transition: ${(p) => p.theme.vars.defaults.transition};
      position: relative;
      ${({ isCollapsed }) => (isCollapsed ? 'padding: 0' : '')};

      &-displayName {
        display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'inline')};
      }

      &-icon__wrapper {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '0.5rem')};

        svg {
          width: 22px;
          height: 22px;
          fill: rgba(255, 255, 255, 0.7);
        }
      }

      &_active {
        font-weight: 700;
        color: #fff;
        .sidebar__item-icon__wrapper {
          background: #3fc9ad;
          border-radius: 10px;
        }
        & svg {
          fill: #fff;
        }
      }

      &:hover {
        .sidebar__item-icon__wrapper {
          background: #3fc9ad;
          border-radius: 10px;
        }
        font-weight: 700;
        color: #fff;
        .sidebar__item-icon__wrapper {
          svg {
            fill: #fff;
            color: #fff;
            path: {
              fill-opacity: 1;
            }
          }
        }
      }

      &-unreadDot {
        height: 10px;
        width: 10px;
        background-color: #ef1733;
        opacity: 1;
        border-radius: 50%;
        border: 1.3px solid #00363c;
        position: absolute;
        top: 8px;
        left: 34px;
      }
    }
  }
`
