import useSWR from 'swr'

import { EP_CALENDAR } from '../../../enums/api.enum'
import { getCalendar } from '../../../services/api/calendar'
import { useAuth } from '../../auth.hook'

interface UseCalendar {
  activities: any[]
  isLoading: boolean
  refetch: () => void
}

interface useCalendarProps {
  startDate: string
  endDate: string
  clientId?: number
}

export default function useCalendar({
  startDate,
  endDate,
  clientId
}: useCalendarProps): UseCalendar {
  const { id: userId } = useAuth()

  const { data, error, mutate } = useSWR(
    EP_CALENDAR +
      `?filter[date]=${startDate},${endDate}&filter[account_id]=${clientId || userId}${clientId ? `&account_id=${clientId}` : ''}`,
    getCalendar
  )

  const isLoading = !data && !error
  const activities = data || []

  return {
    activities,
    isLoading,
    refetch: mutate
  }
}
